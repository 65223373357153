import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { store } from 'redux/store';
import Routes from './Routes';

function App() {
  return (
    <Provider store={store}>
      <HashRouter>
        <Routes/>
      </HashRouter>
    </Provider>
  );
}

export default App;
