import React, { useEffect, useContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { BodyColor } from 'components/core/basic';
import { Web3Context } from 'context/Web3Context';
import { LoadPastEvents } from 'redux/reducers/Blockchain/actions'; 
import { InitSelectedEntity } from 'redux/reducers/Entities/actions';
import { InfoPanel, TopBar, View } from './components';
import { ToastContainer } from 'react-toastify';
import { RootContainer, MainBoard } from './styled';

const Main = () => {
    const { web3Instance } = useContext(Web3Context);
    const dispatch = useDispatch();

    const loadBlockchain = useCallback(
        () => dispatch(LoadPastEvents(web3Instance)), [dispatch, web3Instance]
    )
    
    useEffect(() => {
        loadBlockchain();
    }, [loadBlockchain])

    useEffect(() => {
        dispatch(InitSelectedEntity());
    }, [dispatch])

    return(
        <BodyColor color={"--bgDark"}>
            <RootContainer>
                <MainBoard>
                    <TopBar/>
                    <View/>
                </MainBoard>
                <InfoPanel/>
            </RootContainer>
            <ToastContainer
                position={"bottom-right"}
                hideProgressBar
                pauseOnHover
                draggable={false}
                closeOnClick={false}
            />
        </BodyColor>
    )
}

export default Main;