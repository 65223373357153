import React, { useState } from 'react';
import { FiUpload } from 'react-icons/fi';

import { Wrapper, InfoText, Icon, Input } from './styled';

const FileDrop = (props) => {
    const [isDraggedOver, setIsDraggedOver] = useState(false);

    const onFileUpload = (evt) => {
        const file = evt.target.files[0];
        props.onChange(file);
        evt.target.value = null;
    }

    const dropHandler = (evt) => {
        evt.preventDefault();
        if(evt.dataTransfer.items) {
            if(evt.dataTransfer.items[0].kind === "file") {
                const droppedFile = evt.dataTransfer.items[0].getAsFile();
                props.onChange(droppedFile);
            }
        }
        setIsDraggedOver(false);
    }

    const dragOverHandler = (evt) => {
        evt.preventDefault();
        if(isDraggedOver === false) setIsDraggedOver(true);
    }

    const dragLeaveHandler = (evt) => {
        evt.preventDefault();
        if(isDraggedOver === true) setIsDraggedOver(false);
    }

    const { onChange, ...other} = props;
    return (
        <Wrapper {...other} onDrop={dropHandler} onDragOver={dragOverHandler} onDragLeave={dragLeaveHandler} draggedOver={isDraggedOver}>
            <Icon as={FiUpload}/>
            <InfoText>Drop or load file</InfoText>
            <Input type={"file"} title={" "} onChange={onFileUpload} accept={".key"}/>
        </Wrapper>
    )
}

export default FileDrop;