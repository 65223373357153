import produce from 'immer';

const defaultState = {
    selected: null,
    list: [],
}
const initialState = JSON.parse(localStorage.getItem("providers")) || defaultState;

export default (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case "SELECT_PROVIDER":
                draft.selected = action.payload;
                break;
            case "ADD_NEW_PROVIDER":
                draft.list.push(action.payload);
                break;
            case "DELETE_PROVIDER":
                draft.list.splice(draft.list.findIndex(e => e.name === action.payload), 1);
                draft.selected = null;
                break;
            case "EDIT_PROVIDER":
                const pl = action.payload;
                const editIndex = draft.list.findIndex(e => e.name === pl.name);
                if(draft.selected !== null) {
                    if(draft.selected.name === pl.name) draft.selected = {name: pl.nameInput, address: pl.addressInput};
                }
                draft.list[editIndex].name = pl.nameInput;
                draft.list[editIndex].address = pl.addressInput;
                break;
            default:
                return draft;
        }
    })
