import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useSingleEntity = (listName, sc) => {
    const list = useSelector(state => state[listName]);
    const [thisEntity, setThisEntity] = useState();

    useEffect(() => {
        setThisEntity(
            list.find(e => e.item === sc)
        )
    }, [list, sc])

    return thisEntity;
}

export { useSingleEntity }