import styled from 'styled-components';

export const CloseButton = styled.div`
    padding: 0px 5px 0px 5px;
    background-color: var(--bgLight);
    color: var(--textPrimary);
    &:hover {
        background-color: #e11060;
    }
    margin-auto;
    height: 25px;
`;