import { combineReducers } from 'redux';
import Login from './Login';
import Blockchain from './Blockchain';
import Entities from './Entities';
import Main from './Main';

export default combineReducers({
    ...Login,
    ...Blockchain,
    ...Entities,
    ...Main,
});