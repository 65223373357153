import { populateNodeAndDispatch, populateNodeProps } from 'services/EventHandlingHelpers';

export const AddContract = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {
            exchange: evt.returnValues._node
        };
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setExtraMethods(["symbol"]).setDisabledMethods(["ccp", "wallet", "id", "owner", "isOwner"])
            .setAbiName("Contract").setActionType("ADD_CONTRACT").setAsyncActionType("LOAD_CONTRACT_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const contractEvents = {
    "Exchange_addContract": AddContract,
}