import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDetailViewValues, useCompanySupply } from 'hooks/detailView';
import { DetailViewRoot, DetailViewContent, SpinIcon } from 'components/detail/styled';
import { PanelHeader } from 'components/core/basic';
import { DetailViewDataRow, DetailViewExpander } from 'components/detail';
import { WithdrawalRow } from './WithdrawalRow';
import { SettlementRow } from './SettlementRow';
import { F } from 'services';
import { isEmpty } from 'lodash';
import { FaDonate, FaHandHoldingUsd, FaBalanceScale, FaUserCheck, FaUserSlash, FaSpinner } from 'react-icons/fa';
import { AccountDepositRow, AccountWithdrawRow, AccountCreateBalanceRow } from 'entities/Account/inputs';
import { RegisterAccount, DeregisterAccount } from 'entities/Account/transactions';
import { Web3Context } from 'context/Web3Context';

const Spinner = () => <SpinIcon as={FaSpinner}/>

const coreData = [
    {label: "Name", key: "name"},
    {label: "Sc Address", key: "item"},
    {label: "Company", key: "company"},
]

const balanceList = [
    {label: "Total Deposit", key: "amount"},
    {label: "Excess Eq", key: "excessEquity"},
    {label: "Open Trd. Eq", key: "openTradeEquity"},
    {label: "Realized PnL", key: "realizedPnL"},
    {label: "Margin Balance", key: "marginBalance"},
]

const DetailViewData = (props) => {
    const { data } = props;
    const currencies = useSelector(state => state.currencyList);
    const companySupply = useCompanySupply(data.company);
    
    return(
        <React.Fragment>
            {coreData.map(e => <DetailViewDataRow key={e.key} label={e.label}>{data[e.key]}</DetailViewDataRow>)}
            {data.ccyBalances.length > 0 && 
                <DetailViewExpander label={"Currency Balances"}>
                    {data.ccyBalances.map(e => {
                        const currency = currencies.find(ccy => ccy.item === e.currencySc);
                        return(
                            <DetailViewExpander key={e.currencySc} reverse label={`${currency.name} Info`}>
                                {balanceList.map(bl => (
                                    <DetailViewDataRow key={bl.key} label={bl.label}>{F.currency(e[bl.key], currency.decimals)}</DetailViewDataRow>
                                ))}
                            </DetailViewExpander>
                        )
                    })}
                </DetailViewExpander>
            }
            {!isEmpty(companySupply) && data.ccyBalances.length > 0 &&
                <DetailViewExpander label={"Currency Stats"}>
                    {data.ccyBalances.map(e => {
                        const currency = currencies.find(ccy => ccy.item === e.currencySc);
                        const totalSupplyPercent = Number(e.amount * 100 / currency.totalSupply).toFixed(2);
                        const companyPercent = Number(e.amount * 100 / companySupply[e.currencySc]).toFixed(2);
                        return(
                            <DetailViewExpander key={e.currencySc} reverse label={`${currency.name} Stats`}>
                                <DetailViewDataRow label={"% of Total Supply"}>{`%${totalSupplyPercent}`}</DetailViewDataRow>
                                <DetailViewDataRow label={"% of Company"}>{`%${companyPercent}`}</DetailViewDataRow>
                            </DetailViewExpander>
                        )
                    })}
                </DetailViewExpander>
            }
        </React.Fragment>
    )
}

export { DetailViewData };

const DetailView = () => {
    const dispatch = useDispatch();
    const { web3Instance } = useContext(Web3Context);
    const { selectedEntity, selectedRowID, data } = useDetailViewValues("accountList");
    const privileges = useSelector(state => state.privileges);
    const exchanges = useSelector(state => state.exchangeList);
    const [thisExchange, setThisExchange] = useState(null);
    const [showDeposit, setShowDeposit] = useState(false);
    const [showWithdrawal, setShowWithdrawal] = useState(false);
    const [showCreateBalance, setShowCreateBalance] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const prevRegistered = useRef();

    useEffect(() => {
        setThisExchange(exchanges.find(e => e.item === privileges.selected.scAddress))
    }, [exchanges, privileges.selected.scAddress])

    useEffect(() => {
        if(thisExchange && data) {
            const registered = thisExchange.accounts.find(e => e === data.item) ? true : false;
            setIsRegistered(registered);
        }
    }, [thisExchange, data])

    useEffect(() => {
        if(isRegistered !== prevRegistered.current)
            setLoadingRegister(false);
        prevRegistered.current = isRegistered;
    }, [isRegistered])

    const headerButtons = () => {
        if(privileges.selected.name === "Clearer" && selectedRowID && data)
            return [
                {icon: FaBalanceScale, onClick: () => openCreateBalance(), active: showCreateBalance, disabled: showCreateBalance, tooltip: "Create Balance"},
                {icon: FaDonate, onClick: () => openShowDeposit(), active: showDeposit, disabled: showDeposit, tooltip: "Deposit Account"},
                {icon: FaHandHoldingUsd, onClick: () => openShowWithdrawal(), active: showWithdrawal, disabled: showWithdrawal, tooltip: "Request Withdrawal"},
            ]
        if(privileges.selected.name === "Exchange" && selectedRowID && data)
            return [
                {icon: loadingRegister ? Spinner : (isRegistered ? FaUserSlash : FaUserCheck),
                    onClick: () => handleRegisterAccount(), tooltip: (isRegistered ? "Deregister" : "Register") + "Account"},
            ]
        return [];
    }

    const openCreateBalance = () => {
        setShowCreateBalance(true);
    }

    const openShowDeposit = () => {
        setShowDeposit(true)
        setShowWithdrawal(false)
    }

    const openShowWithdrawal = () => {
        setShowWithdrawal(true);
        setShowDeposit(false);
    }

    const handleRegisterAccount = () => {
        if(loadingRegister) return;
        const func = isRegistered ? DeregisterAccount : RegisterAccount;
        dispatch(func(data.item, web3Instance, thisExchange.item));
        setLoadingRegister(true);
    }
    
    if(selectedEntity !== "Account")
        return null;
    return (
        <DetailViewRoot>
            <PanelHeader title={selectedRowID === null ? "No Row Selected" : ""} buttons={headerButtons()}/>
            {showCreateBalance &&
                <AccountCreateBalanceRow key={data?.item} accountSc={data?.item} close={() => setShowCreateBalance(false)}/>
            }
            {showDeposit &&
                <AccountDepositRow accountSc={data?.item} close={() => setShowDeposit(false)}/>
            }
            {showWithdrawal &&
                <AccountWithdrawRow accountSc={data?.item} accountWallet={data?.wallet} close={() => setShowWithdrawal(false)}/>
            }
            {data &&
                <DetailViewContent>
                    <DetailViewData data={data}/>
                    {privileges.selected.name === "Clearer" && data.withdrawals.length > 0 && 
                        <DetailViewExpander label={"Withdrawals"}>
                            {data.withdrawals.map(e => {
                                return <WithdrawalRow key={e.id} accountSc={data.item} data={e}/>
                            })}
                        </DetailViewExpander>
                    }
                    {data.settlements.length > 0 &&
                        <DetailViewExpander label={"Settlements"}>
                            {data.settlements.map((e, idx) => {
                                return <SettlementRow key={idx} data={e}/>
                            })}
                        </DetailViewExpander>
                    }
                </DetailViewContent>
            }
        </DetailViewRoot>
    )
}

export { DetailView };