import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ListViewRoot, ListContent, ListHeaderRow } from 'components/list/styled';
import { ListColumn, ListWrapper, RowRenderer, useListViewValues } from 'components/list';
import { DeployRow } from 'entities/Market/inputs';
import { PanelHeader } from 'components/core/basic';
import { FaPlus } from 'react-icons/fa';

const formatType = (type) => {
    const values = {
        0: "Spot",
        1: "Spot perpetual",
        2: "Futures",
        3: "Options",
        4: "IRS(Interest Rate Swap)",
    }
    return values[type];
}

const formatLastTradingDate = (d) => {
    if(d) {
        return `${d.substring(0, 4)}-${d.substring(4, 6)}-${d.substring(6)}`
    }
}

const ListView = () => {
    const { selectedEntity, list } = useListViewValues("marketList");
    const privilege = useSelector(state => state.privileges.selected.name);
    const [showDeploy, setShowDeploy] = useState(false);
    const exchanges = useSelector(state => state.exchangeList);

    const rowRenderer = ({ key, index, style }) => (
        <RowRenderer key={key} index={index} style={style} item={list[index].item}>
            <ListColumn>{list[index].name}</ListColumn>
            <ListColumn>{exchanges.find(e => e.item === list[index].exchange)?.name}</ListColumn>
            <ListColumn>{formatType(list[index].type_)}</ListColumn>
            <ListColumn>{formatLastTradingDate(list[index].lastTradingDate)}</ListColumn>
        </RowRenderer>
    )

    const headerButtons = () => {
        if(privilege === "Exchange")
            return[
                {icon: FaPlus, onClick: () => setShowDeploy(true), active: showDeploy, disabled: showDeploy, tooltip: "Deploy Market"}
            ]
    }

    if(selectedEntity !== "Market")
        return null;
    return (
        <ListViewRoot>
            <PanelHeader title={"Markets"} buttons={headerButtons()}/>
            {showDeploy && <DeployRow close={() => setShowDeploy(false)}/>}
            {list.length > 0 && (
                <ListHeaderRow>
                    <ListColumn head>Name</ListColumn>
                    <ListColumn head>Exchange</ListColumn>
                    <ListColumn head>Type</ListColumn>
                    <ListColumn head>Last Trade Date</ListColumn>
                </ListHeaderRow>
            )}
            <ListContent headerVisible={list.length > 0}>
                <ListWrapper length={list.length} rowRenderer={rowRenderer}/>
            </ListContent>
        </ListViewRoot>
    )
}

export { ListView };