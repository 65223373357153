import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HomeRoot, HomeColumn } from 'components/home/styled';
import { PanelHeader } from 'components/core/basic';
import { HomeContent } from 'components/home';
import { ExchangeContractRow } from './ExchangeContractRow';

const innerStyle = {
    display: "flex",
    flexDirection: "row",
}

const Home = () => {
    const { name, scAddress } = useSelector(state => state.privileges.selected);
    const exchanges = useSelector(state => state.exchangeList);
    const [thisExchange, setThisExchange] = useState(null);

    useEffect(() => {
        setThisExchange(exchanges.find(e => e.item === scAddress))
    }, [exchanges, scAddress])

    if(name !== "Exchange")
        return null;
    return (
        <HomeRoot>
            <PanelHeader title={"Home"}/>
            <HomeContent innerStyle={innerStyle}>
                <HomeColumn>
                    {thisExchange?.contractIDList.map((e, idx) => {
                        return (idx % 2 === 0) && <ExchangeContractRow key={idx} data={e} exchange={thisExchange}/>
                    })}
                </HomeColumn>
                <HomeColumn>
                    {thisExchange?.contractIDList.map((e, idx) => {
                        return (idx % 2 === 1) && <ExchangeContractRow key={idx} data={e} exchange={thisExchange}/>
                    })}
                </HomeColumn>
            </HomeContent>
        </HomeRoot>
    )
}

export { Home };