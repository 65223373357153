import { eventMeta } from './actionsHelper';

export const LoadAbi = () => {
    return (dispatch) => {
        dispatch({ type: "SET_ABI_ERROR", payload: false })
        const HOST = "https://clearchain.alternativederivatives.com/files/interim/";
        const types = ["CCP", "CCP_Events", "Exchange", "Clearer", "Currency", "Custodian", "Company", "Account", "Contract", "Market"];
        let data = {};
        let promises = [];
        types.forEach((e, index) => {
            promises[index] = fetch(HOST + e + ".json", { mode: "cors"})
                .then(r => r.json())
                .then(res => data[e] = res)
        });
        Promise.all(promises)
            .then(
                r => dispatch({ type: "LOAD_ABI", payload: data })
            )
            .catch(err => dispatch({ type: "SET_ABI_ERROR", payload: true }))
    }
}

export const LoadPrivileges = (web3) => {
    return async (dispatch, getState) => {
        const state = getState();
        const network = await web3.eth.net.getNetworkType();
        dispatch(SetNetwork(network));
        
        const accounts = await web3.eth.getAccounts();
        dispatch(SetAccounts(accounts));

        const selected = await web3.eth.accounts.privateKeyToAccount("0x" + state.privateKey);
        dispatch({ type: "SELECT_ACCOUNT", payload: selected });

        await web3.eth.getBalance(selected.address)
            .then((wei, error) => {
                if(error) return;
                dispatch({ type: "SET_BALANCE", payload: wei});
            });
        
        web3.eth.getGasPrice()
            .then(res => dispatch({ type: "SET_GAS_PRICE", payload: res }))

        const compatibleAbi = state.abi.data.CCP.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        
        const contract = new web3.eth.Contract(compatibleAbi, state.ccpAddress);
        await contract.methods.isOwner().call({ from: selected.address })
            .then(r => (r === true) && dispatch({ type: "SET_CCP" }))
        await contract.methods.owner().call()
            .then(r => dispatch({ type: "SET_CCP_WALLET", payload: r }))
        await contract.methods.name().call()
            .then(r => dispatch({ type: "SET_CCP_NAME", payload: r}))
        await contract.methods.callbacks().call({ from: selected.address })
            .then(r => dispatch({ type: "SET_CALLBACKS_ADDRESS", payload: r }))
        
        const NULLADDRESS = "0x0000000000000000000000000000000000000000"; 
        const promises = ["EXCHANGE", "CUSTODIAN", "CLEARER"].map(e => {
            const func = e.slice(0, 1).toUpperCase() + e.slice(1, e.length).toLowerCase();
            return contract.methods["get" + func]().call({ from: selected.address })
                .then(r => r !== NULLADDRESS && dispatch({ type: `SET_${e}`, payload: r }))
        });
        const companyPromise = contract.methods.getCompany().call({ from: selected.address })
            .then(r => r.length > 0 && dispatch({ type: "SET_COMPANY", payload: r })).catch(err => {});
        Promise.all(promises.concat([companyPromise]))
            .then(r => dispatch({ type: "PRIVILEGES_LOADED" }))
    }
}

export const SetNetwork = (data) => {
    return {
        type: "SET_NETWORK",
        payload: data,
    }
}

export const SetAccounts = (data) => {
    return {
        type: "SET_ACCOUNTS",
        payload: data,
    }
}

export const SetAbiError = (data) => {
    return {
        type: "SET_ABI_ERROR",
        payload: data,
    }
}

export const LoadPastEvents = (web3) => {
    return async (dispatch, getState) => {
        const state = getState();
        const compatibleAbi = state.abi.data.CCP_Events.abi.map(e => {
            const {constant, ...other} = e;
            return {...other};
        });
        const contract = new web3.eth.Contract(compatibleAbi, state.callbacks);
        
        dispatch({ type: "PAST_EVENTS_CALLED" });
        contract.events.allEvents((err, evt) => {
            console.log(evt, err);
            loadEvent(evt, dispatch, web3);
        })
        contract.getPastEvents(
            "AllEvents",
            {
                fromBlock: 0,
                toBlock: "latest",
            },
            (err, events) => {
                events.forEach((evt) => loadEvent(evt, dispatch, web3));
                dispatch({ type: "PAST_EVENTS_LOADED" });
            }
        );
    }
}

const loadEvent = (evt, dispatch, web3) => {
    if(eventMeta[evt.event])
        dispatch(eventMeta[evt.event](evt, web3));
}

export const ClearerDefaultFundContracts = (clearerSc, currencySc, web3) => {
    return async (dispatch, getState) => {
        
    }
}