import { populateNodeAndDispatch, populateNodeProps } from 'services/EventHandlingHelpers';

export const AddCompany = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setDisabledMethods(["ccp", "wallet"])
            .setAbiName("Company").setActionType("ADD_COMPANY").setAsyncActionType("LOAD_COMPANY_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const ClearerAddCompany = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _item } = evt.returnValues;
        dispatch({ type: "COMPANY_SET_CLEARER", payload: { clearerSc: _node, companySc: _item} });
    }
}

export const ClearerRemoveCompany = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item } = evt.returnValues;
        dispatch({ type: "COMPANY_SET_CLEARER", payload: { clearerSc: "", companySc: _item} });
    }
}

export const companyEvents = {
    "Company_created": AddCompany,
    "Clearer_add_Company": ClearerAddCompany,
    "Clearer_remove_Company": ClearerRemoveCompany,
}