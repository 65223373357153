import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useClearerTotalAccountSupply = (clearerSc) => {
    const [totalSupply, setTotalSupply] = useState([]);
    const accounts = useSelector(state => state.accountList);
    const clearers = useSelector(state => state.clearerList);

    useEffect(() => {
        const clearer = clearers.find(e => e.item === clearerSc);
        let totalAccBalance = [];
        clearer.accounts.forEach(e => {
            const account = accounts.find(x => x.item === e);
            account.ccyBalances.forEach(x => {
                const foundBalanceIdx = totalAccBalance.findIndex(b => b.currencySc === x.currencySc);
                if(foundBalanceIdx === -1) {
                    totalAccBalance.push({
                        currencySc: x.currencySc,
                        amount: x.amount,
                    });
                }
                else
                    totalAccBalance[foundBalanceIdx].amount += x.amount;
            });
        });
        setTotalSupply(totalAccBalance);
    }, [accounts, clearerSc, clearers])

    return totalSupply;
}

export { useClearerTotalAccountSupply };