import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "MARKET_UPDATE_INITIAL_MARGIN":{
                const { marketSc, margin, exchange, blockNumber } = action.payload;
                draft.push({
                    blockNumber: blockNumber,
                    marketSc: marketSc,
                    margin: margin,
                    exchange: exchange,
                });
                return draft;
            }
            case "MARKET_SET_TIMESTAMP":{
                const { timestamp, blockNumber } = action.payload;
                const updateIndex = draft.findIndex(e => e.blockNumber === blockNumber);
                draft[updateIndex].timestamp = timestamp;
                return draft;
            }
            default:
                return draft;
        }
    })
