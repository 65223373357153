import { populateNodeAndDispatch, populateNodeProps } from './actionsHelper';

export const SetSelectedEntity = (data) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: "SET_SELECTED_ENTITY", payload: data });
        // const listName = data.toLowerCase() + "List";
        // const firstRow = state[listName][0];
        // dispatch({ type: "SET_SELECTED_ROW_ID", payload: (firstRow ? firstRow.item : null) });
        dispatch({ type: "SET_SELECTED_ROW_ID", payload: null });
    }
}

export const InitSelectedEntity = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: "SET_SELECTED_ENTITY", payload: state.privileges.selected.entities[0]});
    }
}

export const SetSelectedRowID = (id) => {
    return {
        type: "SET_SELECTED_ROW_ID",
        payload: id,
    }
}

export const AddExchange = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setAbiName("Exchange").setActionType("ADD_EXCHANGE").setAsyncActionType("LOAD_EXCHANGE_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const RemoveExchange = (evt, web3) => {
    return async (dispatch, getState) => {
        dispatch({ type: "REMOVE_EXCHANGE", payload: evt.returnValues._item });
    }
}

export const AddClearer = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setAbiName("Clearer").setActionType("ADD_CLEARER").setAsyncActionType("LOAD_CLEARER_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const RemoveClearer = (evt, web3) => {
    return async (dispatch, getState) => {
        dispatch({ type: "REMOVE_CLEARER", payload: evt.returnValues._item });
    }
}

export const AddCustodian = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setDisabledMethods(["ccp"])
            .setAbiName("Custodian").setActionType("ADD_CUSTODIAN").setAsyncActionType("LOAD_CUSTODIAN_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const RemoveCustodian = (evt, web3) => {
    return async (dispatch, getState) => {
        dispatch({ type: "REMOVE_CUSTODIAN", payload: evt.returnValues._item });
    }
}

export const AddCompany = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setDisabledMethods(["ccp", "wallet"])
            .setAbiName("Company").setActionType("ADD_COMPANY").setAsyncActionType("LOAD_COMPANY_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const AddAccount = (evt, web3) => {
    return async (dispatch, getState) => {
        const state = getState();
           
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setExtraMethods(["company"]).setDisabledMethods(["ccp", "wallet"])
            .setAbiName("Account").setActionType("ADD_ACCOUNT").setAsyncActionType("LOAD_ACCOUNT_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);

        dispatch({ type: "ADD_ACCOUNT_TO_CLEARER_LIST", payload: {clearerSc: evt.returnValues._clearer, accountSc: evt.returnValues._item} });
        dispatch({ type: "ADD_CLEARER_TO_ACCOUNT_LIST", payload: {clearerSc: evt.returnValues._clearer, accountSc: evt.returnValues._item} });
        dispatch({ type: "COMPANY_ADD_ACCOUNT", payload: {companySc: evt.returnValues._node, accountSc: evt.returnValues._item} });
    }
}

export const AddContract = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {
            exchange: evt.returnValues._node
        };
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setExtraMethods(["symbol"]).setDisabledMethods(["ccp", "wallet", "id", "owner", "isOwner"])
            .setAbiName("Contract").setActionType("ADD_CONTRACT").setAsyncActionType("LOAD_CONTRACT_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const AddMarket = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {
            exchange: evt.returnValues._node,
            contract: evt.returnValues._contract,
        };
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setExtraMethods(["type_", "lastTradingDate", "initialMargin", "openInterest"]).setDisabledMethods(["ccp", "wallet"])
            .setAbiName("Market").setActionType("ADD_MARKET").setAsyncActionType("LOAD_MARKET_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const AddCurrency = (evt, web3) => {
    return async (dispatch, getState) => {
        const state = getState();
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setExtraMethods(["totalSupply", "decimals", "symbol", "balanceOf"]).setDisabledMethods(["ccp", "wallet"])
            .setAbiName("Currency").setActionType("ADD_CURRENCY").setAsyncActionType("LOAD_CURRENCY_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);

        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other};
        });
        const currencySc = evt.returnValues._item;
        const contract = new web3.eth.Contract(compatibleAbi, evt.returnValues._item);

        contract.events.allEvents((err, evt) => {
            if(evt.event === "Transfer") handleCurrencyTransfer(evt, dispatch, state, currencySc)
        });
        contract.getPastEvents(
            "AllEvents",
            {
                fromBlock: 0,
                toBlock: "latest",
            },
            (err, events) => {
                events.forEach((evt) => {if(evt.event === "Transfer") handleCurrencyTransfer(evt, dispatch, state, currencySc)});
            }
        );
    }
}

const handleCurrencyTransfer = (evt, dispatch, state, currencySc) => {
    const { from , to, value } = evt.returnValues;
    dispatch({ type: "UPDATE_ADDRESS_BALANCE", payload: {from: from, value: value, to: to, currencySc: currencySc} });
}

export const RemoveCurrency = (evt, web3) => {
    return async (dispatch, getState) => {
        dispatch({ type: "REMOVE_CURRENCY", payload: evt.returnValues._item });
    }
}

export const CcpAddDefaultFundContribution = (evt, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const { _node, _delta } = evt.returnValues;

        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, _node);
        if(state.privileges.selected.name === "CCP")
            contract.methods.balanceOf(state.accounts.selected.address).call().then(r => {
                dispatch({ type: "CCP_ADD_DFC", payload: {node: _node, delta: _delta, balanceOf: parseInt(r)} });
            });
    }
}

export const CcpRemoveDefaultFundContribution = (evt, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const { _node, _delta } = evt.returnValues;

        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, _node);
        if(state.privileges.selected.name === "CCP")
            contract.methods.balanceOf(state.accounts.selected.address).call().then(r => {
                dispatch({ type: "CCP_REMOVE_DFC", payload: {node: _node, delta: _delta, balanceOf: parseInt(r)} });
            });
    }
}

export const CustodianRegisterClearer = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node }  = evt.returnValues;
        dispatch({ type: "CUSTODIAN_REGISTER_CLEARER", payload: {clearerSc: _item, custodianSc: _node} });
        dispatch({ type: "ADD_CLEARER_TO_CUSTODIAN_LIST", payload: {clearerSc: _item, custodianSc: _node} });
    }
}

export const CustodianDeregisterClearer = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "CUSTODIAN_DEREGISTER_CLEARER", payload: {clearerSc: _item, custodianSc: _node} });
        dispatch({ type: "REMOVE_CLEARER_FROM_CUSTODIAN_LIST", payload: {clearerSc: _item, custodianSc: _node} });
    }
}

export const ExchangeRegisterClearer = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "EXCHANGE_REGISTER_CLEARER", payload: {clearerSc: _item, exchangeSc: _node} });
        dispatch({ type: "ADD_CLEARER_TO_EXCHANGE_LIST", payload: {clearerSc: _item, exchangeSc: _node} });
    }
}

export const ExchangeDeregisterClearer = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "EXCHANGE_DEREGISTER_CLEARER", payload: {clearerSc: _item, exchangeSc: _node} });
        dispatch({ type: "REMOVE_CLEARER_FROM_EXCHANGE_LIST", payload: {clearerSc: _item, exchangeSc: _node} });
    }
}

export const ExchangeRegisterAccount = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "ADD_EXCHANGE_TO_ACCOUNT_LIST", payload: {accountSc: _item, exchangeSc: _node} });
        dispatch({ type: "ADD_ACCOUNT_TO_EXCHANGE_LIST", payload: {accountSc: _item, exchangeSc: _node} });
    }
}

export const ExchangeDeregisterAccount = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "REMOVE_EXCHANGE_FROM_ACCOUNT_LIST", payload: {accountSc: _item, exchangeSc: _node} });
        dispatch({ type: "REMOVE_ACCOUNT_FROM_EXCHANGE_LIST", payload: {accountSc: _item, exchangeSc: _node} });
    }
}

export const ExchangeSetDefaultFundContribution = (evt, web3) => {
    return (dispatch, getState) => {
        const { _currency, _node, _amount, _contractID } = evt.returnValues;
        dispatch({ type: "EXCHANGE_SET_DFC", payload: {currencySc: _currency, exchangeSc: _node, amount: _amount, contractID: _contractID} });
    }
}

export const CurrencyIncreaseSupply = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _newTotal, _custodian, _delta } = evt.returnValues;
        dispatch({ type: "SET_TOTAL_SUPPLY", payload: {currencyAddress: _node, newValue: _newTotal} });
        dispatch({ type: "CUSTODIAN_UPDATE_TOTAL_MINT", payload: {custodianSc: _custodian, delta: _delta, currencySc: _node} });
    }
}

export const CurrencyDecreaseSupply = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _newTotal, _custodian, _delta } = evt.returnValues;
        dispatch({ type: "SET_TOTAL_SUPPLY", payload: {currencyAddress: _node, newValue: _newTotal}});
        dispatch({ type: "CUSTODIAN_UPDATE_TOTAL_BURN", payload: {custodianSc: _custodian, delta: _delta, currencySc: _node} });
    }
}

export const ClearerAddCompany = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _item } = evt.returnValues;
        dispatch({ type: "COMPANY_SET_CLEARER", payload: { clearerSc: _node, companySc: _item} });
    }
}

export const ClearerRemoveCompany = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item } = evt.returnValues;
        dispatch({ type: "COMPANY_SET_CLEARER", payload: { clearerSc: "", companySc: _item} });
    }
}

export const ClearerWithdrawal = (evt, web3) => {
    return (dispatch, getState) => {
        // const { _node, _clearer, _delta } = evt.returnValues;
        // dispatch({ type: "CLEARER_WALLET_UPDATE_BALANCE", payload: {clearerWallet: _clearer, amount: -_delta, currencySc: _node} });
    }
}

export const ClearerDeposit = (evt, web3) => {
    return (dispatch, getState) => {
        // const { _node, _clearer, _amount } = evt.returnValues;
        // dispatch({ type: "CLEARER_UPDATE_BALANCE", payload: {clearerSc: _clearer, amount: _amount, currencySc: _node} });
    }
}

export const ClearerDefaultFundContribution = (evt, web3) => {
    return (dispatch, getState) => {
        // const { _node, _from, _delta } = evt.returnValues;
        // dispatch({ type: "CLEARER_WALLET_UPDATE_BALANCE", payload: {clearerWallet: _from, amount: -_delta, currencySc: _node} });
    }
}

export const ClearerUpdateDefaultFundContribution = (evt, web3) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { _node, _exchange, _contractID, _currencyDeposited } = evt.returnValues;
        const compatibleAbi = state.abi.data.Clearer.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, _node);
        const amount = await contract.methods.getDefaultFundMinimumContribution(_exchange, _contractID, _currencyDeposited).call();
        dispatch({ type: "ADD_CONTRACT_ID", payload: {exchangeSc: _exchange, contractID: _contractID, currencySc: _currencyDeposited, amount: amount[0], clearerSc: _node} });
    }
}

export const AccountDeposit = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _moneyAmount, _currency } = evt.returnValues;
        dispatch({ type: "ADD_ACCOUNT_BALANCE", payload: {accountSc: _node, amount: _moneyAmount, currencySc: _currency} });
    }
}

export const AccountWithdrawalProcessed = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _moneyAmount, _currency, _id } = evt.returnValues;
        dispatch({ type: "REMOVE_ACCOUNT_BALANCE", payload: {accountSc: _node, amount: _moneyAmount, currencySc: _currency} });
        dispatch({ type: "ACCOUNT_WITHDRAWAL_PROCESSED", payload: { accountSc: _node, id: _id } });
    }
}

export const AccountWithdrawalRequested = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _id, _requestedAmount, _currency } = evt.returnValues;
        dispatch({ type: "ACCOUNT_WITHDRAWAL_REQUESTED", payload: { accountSc: _node, id: _id, amount: _requestedAmount, currencySc: _currency } });
    }
}

export const AccountWithdrawalCancelled = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _id, _currency, _amount } = evt.returnValues;
        dispatch({ type: "ACCOUNT_WITHDRAWAL_CANCELLED", payload: { accountSc: _node, id: _id, amount: _amount, currencySc: _currency} });
    }
}

export const AccountSettlementUpdate = (evt, web3) => {
    return (dispatch, getState) => {
       const { _node, _currency, ...other } = evt.returnValues;
       dispatch({ type: "ACCOUNT_SETTLEMENT_UPDATE", payload: {accountSc: _node, currencySc: _currency, settlementValues: other} });
    }
}

export const AccountPhysicalSettlementRequested = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _settlementID, _exchange, _currency, _counterparty, _amount, _canBeSatisfied } = evt.returnValues;
        const payload = {
            sender: _node,
            settlementID: _settlementID,
            exchange: _exchange,
            currency: _currency,
            counterparty: _counterparty,
            amount: _amount,
            canBeSatisfied: _canBeSatisfied,
        }
        dispatch({ type: "ACCOUNT_ADD_PHYSICAL_SETTLEMENT", payload: payload });
        web3.eth.getBlock(evt.blockNumber).then(r => {
            dispatch({ type: "SET_SETTLEMENT_TIMESTAMP", payload: {timestamp: r.timestamp, settlementID: _settlementID} });
        });
    }
}

export const AccountPhysicalSettlementProcessed = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _settlementID, _exchange, _currency, _counterparty, _amount } = evt.returnValues;
        const payload = {
            sender: _node,
            settlementID: _settlementID,
            exchange: _exchange,
            currency: _currency,
            counterparty: _counterparty,
            amount: _amount,
        }
        dispatch({ type: "ACCOUNT_PHYSICAL_SETTLEMENT_PROCESSED", payload: payload });
        dispatch({ type: "ADD_ACCOUNT_BALANCE", payload: {accountSc: _counterparty, amount: _amount, currencySc: _currency} });
        dispatch({ type: "REMOVE_ACCOUNT_BALANCE", payload: {accountSc: _node, amount: _amount, currencySc: _currency} });
    }
}

export const AccountPhysicalSettlementRejected = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _settlementID, _exchange, _currency, _counterparty, _amount } = evt.returnValues;
        const payload = {
            sender: _node,
            settlementID: _settlementID,
            exchange: _exchange,
            currency: _currency,
            counterparty: _counterparty,
            amount: _amount,
        }
        dispatch({ type: "ACCOUNT_REJECT_PHYSICAL_SETTLEMENT", payload: payload });
    }
}

export const CcpAdjustInitialMargin = (evt, web3) => {
    return (dispatch, getState) => {
        const { _market, _marginPercentage } = evt.returnValues;
        dispatch({ type: "MARKET_UPDATE_INITIAL_MARGIN", payload: {marketSc: _market, margin: _marginPercentage, blockNumber: evt.blockNumber} });
        web3.eth.getBlock(evt.blockNumber).then(r => {
            dispatch({ type: "MARKET_SET_TIMESTAMP", payload: {timestamp: r.timestamp, blockNumber: evt.blockNumber} });
        });
    }
}

export const ExchangeAdjustInitialMargin = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _marginPercentage, _exchange } = evt.returnValues; 
        dispatch({ type: "MARKET_UPDATE_INITIAL_MARGIN", payload: {marketSc: _node, margin: _marginPercentage, exchange: _exchange, blockNumber: evt.blockNumber} });
        web3.eth.getBlock(evt.blockNumber).then(r => {
            dispatch({ type: "MARKET_SET_TIMESTAMP", payload: {timestamp: r.timestamp, blockNumber: evt.blockNumber} });
        });
    }
}

export const ExchangeUpdateOpenInterest = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _amount } = evt.returnValues;
        dispatch({ type: "MARKET_UPDATE_OPEN_INTEREST", payload: {marketSc: _node, amount: _amount} });
    }
}