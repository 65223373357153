import React from 'react';
import { useSelector } from 'react-redux';
import { Wrapper, InputItem2x0 } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { ClearerDepositDefaultFund } from 'entities/Clearer/transactions';
import { useValidation, useSingleEntity, useAddressBalance } from 'hooks/core';
import Cleave from 'cleave.js/react';

const DepositBufferFundRow = (props) => {
    const { cleave, setCleave, amount, setAmount, dispatch, web3Instance} = useDefaultInputValues();
    const { exchangeSc, symbol, currencySc, close } = props;
    const wallet = useSelector(state => state.accounts.selected.address);
    const thisCurrency = useSingleEntity("currencyList", currencySc);
    const balance = useAddressBalance(wallet);
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount === "",
            errorText: "Can't deposit 0 amount",
        },
        {
            validation: () => balance?.balances.find(e => e.currencySc === thisCurrency?.item)?.value < cleave.getRawValue() * Math.pow(10, thisCurrency?.decimals),
            errorText: "Can't deposit more than you have in Balance",
        },
    ]);

    const deposit = () => {
        if(disabled) return;
        dispatch(ClearerDepositDefaultFund(
            exchangeSc,
            symbol,
            cleave.getRawValue() * Math.pow(10, thisCurrency?.decimals),
            thisCurrency?.item,
            web3Instance,
        ))
        close();
    }
    
    return (
        <Wrapper disableHover>
            <InputItem2x0>
                <Cleave onInit={cleave => setCleave(cleave)}
                    className={"cleaveStyle"} style={{height: "25px", width: "100%"}} placeholder={"Amount..."} onChange={e => setAmount(e.target.value)} 
                    options={{numeral: true, numeralDecimalScale: thisCurrency?.decimals}}
                />
            </InputItem2x0>
            <Confirm disabled={disabled} onClick={deposit} tooltip={errorText ?? "Confirm Deposit"} error={errorText}/>
            <Close onClick={close} tooltip={"Discard Input"}/>
        </Wrapper>
    )
}

export { DepositBufferFundRow };