import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListViewRoot, ListContent, ListHeaderRow } from 'components/list/styled';
import { ListColumn, ListWrapper, RowRenderer, useListViewValues } from 'components/list';
import { PanelHeader } from 'components/core/basic';
import { FaFilter } from 'react-icons/fa';
import { SettlementFilter } from 'entities/Settlement/list/Filter';
import { F } from 'services';

const ListView = () => {
    const { selectedEntity, list } = useListViewValues("settlementList");
    const accounts = useSelector(state => state.accountList);
    const currencies = useSelector(state => state.currencyList);
    const [filter, setFilter] = useState(false);
    const [filterFunction, setFilterFunction] = useState(() => true);

    useEffect(() => {
        
    }, [list])

    const accountName = (accountSc) => accounts.find(x => x.item === accountSc)?.name ?? undefined;
    const currencyName = (currencySc) => currencies.find(x => x.item === currencySc)?.symbol ?? undefined;

    const currencyFormat = (amount, currencySc) => {
        const thisCurrency = currencies.find(x => x.item === currencySc);
        return F.currency(amount, thisCurrency?.decimals);
    }

    const rowRenderer = ({ key, index, style }) => (
        <RowRenderer key={key} index={index} style={style} item={list[index].settlementID}>
            <ListColumn>{accountName(list[index].sender)}</ListColumn>
            <ListColumn>{accountName(list[index].counterparty)}</ListColumn>
            <ListColumn timestamp>{list[index].timestamp}</ListColumn>
            <ListColumn>{currencyName(list[index].currency)}</ListColumn>
            <ListColumn>{currencyFormat(list[index].amount, list[index].currency)}</ListColumn>
            <ListColumn>{list[index].status}</ListColumn>
        </RowRenderer>
    )

    const headerButtons = () => {
        return [
            {icon: FaFilter, onClick: () => setFilter(c => !c), disabled: false, active: filter, tooltip: "Toggle Filter"},
        ]
    }

    const handeFilterFunction = () => {}

    if(selectedEntity !== "Settlement")
        return null;
    return (
        <ListViewRoot>
            <PanelHeader title={"Settlements"} buttons={headerButtons()}/>
            {filter &&
                <SettlementFilter setFilterFunction={handeFilterFunction}/>
            }
            {list.length > 0 && (
                <ListHeaderRow>
                    <ListColumn head>Sender</ListColumn>
                    <ListColumn head>Counterparty</ListColumn>
                    <ListColumn head>Timestamp</ListColumn>
                    <ListColumn head>Currency</ListColumn>
                    <ListColumn head>Amount</ListColumn>
                    <ListColumn head>Status</ListColumn>
                </ListHeaderRow>
            )}
            <ListContent headerVisible={list.length > 0}>
                <ListWrapper length={list.length} rowRenderer={rowRenderer}/>
            </ListContent>
        </ListViewRoot>
    )
}

export { ListView };