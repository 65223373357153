import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FaPlus } from 'react-icons/fa';
import { AiOutlineClose, AiOutlineDown } from 'react-icons/ai';
import SimpleBar from 'simplebar-react';
import produce from 'immer';
import { ButtonDefault, InputAde } from 'components/core/basic';
import { AddNewProvider, SelectProvider, DeleteProvider, EditProvider } from 'redux/reducers/Login/actions';
import { useSelectedProvider } from 'hooks/Login';
import { useElementOverflown } from 'hooks/core';

import { defaultProvider } from '../config.js';
import { Container, InputBox, ProviderList, NameAndAddress, AddButton, InputTitleSecondary, Title, 
    ListContainer, ListItem, ListItemToolbox, ListItemIcon, EditRow, EditInputContainer, EditButton } from './styled';
import { InputAdeWrapper, InputTitle } from '../styled';

function providersInEditReducer(state, action) {
    switch(action.type) {
        case "ADD":
            const inputs = {nameInput: action.payload.name, addressInput: action.payload.address};
            return [...state, {name: action.payload.name, ...inputs}];
        case "REMOVE":
            return state.filter(e => e.name !== action.payload.name);
        case "INPUT_CHANGE":
            const value = action.payload.evt.target.value;
            const inputType = action.payload.type === 0 ? "nameInput" : "addressInput";
            const editIndex = state.findIndex(e => e.name === action.payload.name);
            const nextState = produce(state, draft => {
                draft[editIndex][inputType] = value;
            });
            return nextState;
        default:
            return state;
    }
}

function simplebarWrapperGetter(ref) {
    if(ref.current) {
        return { current: ref.current.contentWrapperEl };
    }
    return ref;
}

const ProviderMenu = () => {
    const selectedProvider = useSelectedProvider();
    const providers = useSelector(state => state.providers);
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [addDisabled, setAddDisabled] = useState(true);
    const [, setForceUpdate] = useState(true);
    const [providersInEdit, dispatchProvidersInEdit] = useReducer(providersInEditReducer, []);
    const simplebarRef = useRef();
    const isListOverflown = useElementOverflown(simplebarWrapperGetter(simplebarRef));

    useEffect(() => {
        setForceUpdate(e => !e);
    }, []);

    useEffect(() => {
        if([providers.list.map(e => e.name).find(e => e === name), defaultProvider.name].includes(name)) {
            setAddDisabled(true);
            return;
        }
        if(name !== "" && address !== "")
            setAddDisabled(false);
        else
            setAddDisabled(true);
    }, [name, address, providers.list]);

    const addProvider = () => {
        if(addDisabled) return;
        dispatch(AddNewProvider({
            name: name,
            address: address,
        }));
        setName("");
        setAddress("");
    }

    const deleteProvider = (evt, name) => {
        evt.stopPropagation();
        dispatch(DeleteProvider(name));
    }

    const editProvider = (name) => {
        const providerData = providersInEdit.find(e => e.name === name);
        if(providers.list.filter(e => e.name !== name).find(e => e.name === providerData.nameInput)) return;
        dispatch(EditProvider(providerData));
        dispatchProvidersInEdit({type: "REMOVE", payload: {name: name}});
    }

    const toggleEditProvider = (evt, provider) => {
        evt.stopPropagation();
        if(providersInEdit.find(e => e.name === provider.name)) {
            dispatchProvidersInEdit({type: "REMOVE", payload: provider});
            return;
        }
        dispatchProvidersInEdit({type: "ADD", payload: provider});
    }

    return(
        <Container>
            <InputBox>
                <NameAndAddress>
                    <Title>Add Provider</Title>
                    <InputTitle>Name</InputTitle>
                    <InputAdeWrapper as={InputAde} value={name} onChange={(e) => setName(e.target.value)}/>
                    <InputTitle as={InputTitleSecondary}>Address</InputTitle>
                    <InputAdeWrapper as={InputAde} value={address} onChange={(e) => setAddress(e.target.value)}/>
                </NameAndAddress>
                <AddButton as={ButtonDefault} onClick={addProvider} disabled={addDisabled}><FaPlus/></AddButton>
            </InputBox>
            <ProviderList>
                <Title>Provider List</Title>
                <ListContainer as={SimpleBar} autoHide={false} ref={simplebarRef} scrollvisible={isListOverflown}>
                    <ListItem selected={providers.selected === null} onClick={() => dispatch(SelectProvider(null))}>{defaultProvider.name}</ListItem>
                    {providers.list.map(e => 
                        <div key={e.name}>
                            <ListItem selected={selectedProvider.name === e.name} onClick={() => dispatch(SelectProvider(e))}>
                                <div>{e.name}</div>
                                <ListItemToolbox>
                                    <AiOutlineClose onClick={(evt) => deleteProvider(evt, e.name)}/>
                                    <ListItemIcon as={AiOutlineDown} onClick={(evt) => toggleEditProvider(evt, e)}/>
                                </ListItemToolbox>
                            </ListItem>
                            {providersInEdit.find(p => p.name === e.name) ? 
                                <EditRow>
                                    <EditInputContainer>
                                        <InputTitle>Name</InputTitle>
                                        <InputAdeWrapper as={InputAde} value={providersInEdit.find(p => p.name === e.name).nameInput} 
                                            onChange={(evt) => dispatchProvidersInEdit({type: "INPUT_CHANGE", payload: {name: e.name, evt: evt, type: 0 }})}/>
                                    </EditInputContainer>
                                    <EditInputContainer>
                                        <InputTitle>Address</InputTitle>
                                        <InputAdeWrapper as={InputAde} value={providersInEdit.find(p => p.name === e.name).addressInput} 
                                            onChange={(evt) => dispatchProvidersInEdit({type: "INPUT_CHANGE", payload: {name: e.name, evt: evt, type: 1 }})}/>
                                    </EditInputContainer>
                                    <EditButton as={ButtonDefault} onClick={() => editProvider(e.name)}>Edit</EditButton>
                                </EditRow> 
                            : null}
                        </div>)}
                </ListContainer>
            </ProviderList>
        </Container>
    )
}

export default ProviderMenu;