import React from 'react';
import { useSelector } from 'react-redux';
import { HomeRoot, HomeColumn, HomeDataRowWrapper } from 'components/home/styled';
import { HomeContent } from 'components/home';
import { PanelHeader } from 'components/core/basic';
import { SettlementRow } from './SettlementRow';
import { SupplyRow } from './SupplyRow';

const innerStyle = {
    display: "flex",
    flexDirection: "row",
}

const Home = () => {
    const privileges = useSelector(state => state.privileges);
    const currencies = useSelector(state => state.currencyList);
 
    if(privileges.selected.name !== "Custodian")
        return null;
    return (
        <HomeRoot>
            <PanelHeader title={"Home"}/>
            <HomeContent innerStyle={innerStyle}>
                <HomeColumn>
                    {currencies.map(e => {
                        return (
                            <HomeDataRowWrapper key={e.item} noMarginRight>
                                <SupplyRow custodianSc={privileges.selected.scAddress} currency={e}/>
                            </HomeDataRowWrapper>
                        )
                    })}
                </HomeColumn>
                <HomeColumn>
                    <SettlementRow custodianSc={privileges.selected.scAddress}/>
                </HomeColumn>
            </HomeContent>
        </HomeRoot>
    )
}

export { Home };