import { populateNodeAndDispatch, populateNodeProps } from 'services/EventHandlingHelpers';

export const AddClearer = (evt, web3) => {
    return async (dispatch, getState) => {
        const state = getState();
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setAbiName("Clearer").setActionType("ADD_CLEARER").setAsyncActionType("LOAD_CLEARER_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
        const contractIDList = state.exchangeList.reduce((acc, curr) => {
            const list = curr.contractIDList.map(e => ({
                exchangeSc: curr.item,
                currencySc: e.currencySc,
                contractID: e.id,
            }));
            return [...acc, ...list];
        }, []);
        dispatch({ type: "ADD_CONTRACT_IDS_ON_NEW_CLEARER", payload: {
            contractIDList: contractIDList,
            clearerSc: evt.returnValues._item,
        } });
    }
}

export const RemoveClearer = (evt, web3) => {
    return async (dispatch, getState) => {
        dispatch({ type: "REMOVE_CLEARER", payload: evt.returnValues._item });
    }
}

export const ClearerUpdateDefaultFundContribution = (evt, web3) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { _node, _exchange, _contractID, _currencyDeposited } = evt.returnValues;
        const compatibleAbi = state.abi.data.Clearer.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, _node);
        const amount = await contract.methods.getDefaultFundMinimumContribution(_exchange, _contractID, _currencyDeposited).call();
        dispatch({ type: "ADD_CONTRACT_ID", payload: {exchangeSc: _exchange, contractID: _contractID, currencySc: _currencyDeposited, amount: amount[0], clearerSc: _node} });
    }
}

export const ClearerUpdateDefaultFundContributionOption = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _exchange, _contractID, _currency, _option, _isActive, _percentageAmount, _notionalAmount } = evt.returnValues;
        dispatch({ type: "ADD_CONTRACT_ID_OPTION", payload: {
            clearerSc: _node,
            exchangeSc: _exchange,
            contractID: _contractID,
            currencySc: _currency,
            option: _option,
            isActive: _isActive,
            percent: _percentageAmount,
            notional: _notionalAmount,
        } });
    }
}

export const ClearerRunCalc = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _exchange, _contractID, _currency, _option, _minContribution } = evt.returnValues;
        dispatch({ type: "CONTRACT_ID_RUN_CALC", payload: {
            clearerSc: _node,
            exchangeSc: _exchange,
            contractID: _contractID,
            currencySc: _currency,
            contribution: _minContribution,
        } });
    }
}

export const SetVar = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _exchange, _contractID, _currency, _segregatedForVAR } = evt.returnValues;
        dispatch({ type: "CONTRACT_ID_SET_VAR", payload: {
            clearerSc: _node,
            exchangeSc: _exchange,
            contractID: _contractID,
            currencySc: _currency,
            varValue: _segregatedForVAR,
        } });
    }
}

export const clearerEvents = {
    "CCP_addClearer": AddClearer,
    "CCP_removeClearer": RemoveClearer,
    "Clearer_update_DefaultFundContribution": ClearerUpdateDefaultFundContribution,
    "Clearer_update_DefaultFundContributionOption": ClearerUpdateDefaultFundContributionOption,
    "Clearer_runCalc_DefaultFundContribution": ClearerRunCalc,
    "Clearer_update_DefaultFundContributionParam": SetVar,
}