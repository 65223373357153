import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { SetDefaultFundContribution } from 'entities/Exchange/transactions';
import Cleave from 'cleave.js/react';
import { useValidation } from 'hooks/core';
import { DefaultSelect } from 'components/core/selectors';

const SetBufferFundRow = (props) => {
    const { item } = props?.exchange;
    const { symbol, withSymbol } = props;
    const { dispatch, web3Instance, cleave, setCleave, amount, setAmount } = useDefaultInputValues();
    const currencies = useSelector(state => state.currencyList);
    const contracts = useSelector(state => state.contractList);
    const [filteredContracts, setFilteredContracts] = useState([]);
    const [selectedCcy, setSelectedCcy] = useState({});
    const [contract, setContract] = useState({});
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount === "",
            errorText: "BFC can't be empty",
        },
        {
            validation: () => currencies.length === 0,
            errorText: "No available currencies"
        },
        {
            validation: () => symbol ? false : filteredContracts.length === 0,
            errorText: "No available contracts",
        },
    ]);

    useEffect(() => {
        setFilteredContracts(
            contracts.filter(e => {
                return (e.symbol && e.exchange) && (e.exchange === item)
            })
        );
    }, [contracts, item])

    const deposit = () => {
        if(disabled) return;
        dispatch(SetDefaultFundContribution(
            selectedCcy.item,
            item,
            symbol ?? contract.symbol,
            cleave.getRawValue() * Math.pow(10, selectedCcy.decimals),
            web3Instance,
        ))
        props.close();
    }
    
    return (
        <Wrapper>
            <Inputs>
                <InputItem width={withSymbol ? "50%" : "34%"}>
                    <Cleave onInit={cleave => setCleave(cleave)} key={selectedCcy.item} 
                        className={"cleaveStyle"} style={{height: "25px", width: "100%"}} placeholder={"Amount..."} onChange={e => setAmount(e.target.value)} 
                        options={{numeral: true, numeralDecimalScale: selectedCcy.decimals}}
                    />
                </InputItem>
                <InputItem width={withSymbol ? "50%" : "33%"}>
                    <DefaultSelect
                        label={"Currency"}
                        onChange={selected => setSelectedCcy(selected)}
                        formatValue={(e) => `${e.name}/${e.symbol}`}
                        list={currencies}
                    />
                </InputItem>
                {withSymbol === undefined &&
                    <InputItem width={"33%"}>
                        <DefaultSelect
                            label={"Symbol"}
                            onChange={selected => setContract(selected)}
                            formatValue={(e) => `${e.symbol}`}
                            list={filteredContracts}
                        />
                    </InputItem>}
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Set Buffer Fund Contribution"} disabled={disabled} onClick={deposit}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { SetBufferFundRow };