import { useCallback, useState } from "react";

const useIsHovered = () => {
    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = () => {
        if(isHovered) return;
        setIsHovered(true);
    }

    const onMouseLeave = () => {
        if(isHovered === false) return;
        setIsHovered(false);
    }

    return [isHovered, onMouseEnter, onMouseLeave];
}

export { useIsHovered };