import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'components/core/basic';
import { Toggle, Label, Selected, Expand, Content, RowItem } from '../styled';
import { FaCaretDown } from 'react-icons/fa';
import { usePrevious } from 'hooks/core';
import { isNil } from 'lodash';

const CompanySelect = (props) => {
    const { onChange } = props;
    const [selected, setSelected] = useState(null);
    const clearerSc = useSelector(state => state.privileges.selected.scAddress);
    const toggle = useRef();
    const prevSelected = usePrevious(selected);
    const companies = useSelector(state => state.companyList);
    const [filtered, setFiltered] = useState([]);

    const toggleCallback = useCallback((toggleFunc) => {
        toggle.current = toggleFunc;
    }, [toggle])

    const selectItem = (item) => {
        setSelected(item);
        const toggleFunc = toggle.current;
        toggleFunc();
    }

    useEffect(() => {
        const filter = companies.some(e => {
            if(isNil(e.name)) return true; 
            return false;
        });
        if(filter) setFiltered([]);
        else setFiltered(companies.filter(e => e.clearerSc === clearerSc));
    }, [companies, clearerSc])

    useEffect(() => {
        if(filtered.length > 0 && isNil(selected)) {
            setSelected(filtered[0])
        }
    }, [filtered, selected])

    useEffect(() => {
        if(selected === null) return;
        if(selected === prevSelected) return;
        onChange(selected);
    }, [selected, prevSelected, onChange])

    useEffect(() => {
        setSelected(s => filtered.find(e => e.item === s?.item));
    }, [filtered])

    return (
        <Dropdown fitContainerHeight={true} toggleCallback={toggleCallback}>
            <Toggle disabled={filtered.length === 0}>
                <Label>Company</Label>
                <Selected>
                    {filtered.length === 0
                        ? "Empty"
                        : selected?.name}
                </Selected>
                <Expand><FaCaretDown/></Expand>
            </Toggle>
            {filtered.length > 0 && (
                <Content>
                    {filtered.map(e => {
                        return <RowItem key={e.item} onClick={() => selectItem(e)}>{e.name}</RowItem>
                    })}
                </Content>
            )}
        </Dropdown>
    );
}

export { CompanySelect }