import { TransactionService } from 'services';
import { abiParser } from 'services/EventHandlingHelpers';

export const SetDefaultFundContribution = (currencySc, exchangeSc, contractID, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Exchange"), exchangeSc);
        const method = () => contract.methods.setDefaultFundContribution(currencySc, contractID, amount);
        TransactionService.executeTransaction(method, exchangeSc, web3, state, "setDefaultFundContribution");
    }
}

export const AdjustInitialMargin = (marketSc, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Market"), marketSc);
        const method = () => contract.methods.adjustInitialMargin(amount);
        TransactionService.executeTransaction(method, marketSc, web3, state, "adjustInitialMargin");
    }
}