import React from 'react';
import { useSelector } from 'react-redux';
import { InputItem2x0, Wrapper } from 'components/inputs/styled';
import { Confirm , Close, useDefaultInputValues } from 'components/inputs';
import { ClearerWithdrawFromDefaultFund } from 'entities/Clearer/transactions';
import Cleave from 'cleave.js/react';
import { useValidation, useSingleEntity, useAddressBalance } from 'hooks/core';

const WithdrawFromBufferFundRow = (props) => {
    const { cleave, setCleave, amount, setAmount, dispatch, web3Instance} = useDefaultInputValues();
    const { exchangeSc, symbol, currencySc, close } = props;
    const thisCurrency = useSingleEntity("currencyList", currencySc);
    const scAddress = useSelector(state => state.privileges.selected.scAddress);
    const balance = useAddressBalance(scAddress);
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount === "",
            errorText: "You can't withdraw 0 amount",
        },
        {
            validation: () => balance?.balances.find(e => e.currencySc === thisCurrency?.item)?.value < cleave.getRawValue() * Math.pow(10, thisCurrency?.decimals),
            errorText: "You can't withdraw more than you have in BFC"
        }
    ])

    const withdraw = () => {
        if(disabled) return;
        dispatch(ClearerWithdrawFromDefaultFund(
            exchangeSc,
            scAddress,
            symbol,
            cleave.getRawValue() * Math.pow(10, thisCurrency?.decimals),
            thisCurrency?.item,
            web3Instance,
        ));
        close();
    }
    
    return (
        <Wrapper>
            <InputItem2x0 width={"40%"}>
                <Cleave onInit={cleave => setCleave(cleave)}
                    className={"cleaveStyle"} style={{height: "25px", width: "100%"}} placeholder={"Amount..."} onChange={e => setAmount(e.target.value)} 
                    options={{numeral: true, numeralDecimalScale: thisCurrency?.decimals}}
                />
            </InputItem2x0>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Withdraw from Buffer Fund"} disabled={disabled} onClick={withdraw}/>
            <Close tooltip={"Discard Input"} onClick={close}/>
        </Wrapper>
    )
}

export { WithdrawFromBufferFundRow };