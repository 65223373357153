import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { List, AutoSizer } from 'react-virtualized';
import SimpleBar from 'simplebar-react';
import { NoRowsContainer } from './styled';
import { Spinner } from 'components/core/basic';

const ListWrapper = (props) => {
    const [scrollHeight, setScrollHeight] = useState(null);
    const [scrollTop, setScrollTop] = useState(0);
    const simpleBarRef = useRef();
    const pastEventsStatus = useSelector(state => state.pastEventsStatus);

    useEffect(() => {
        setScrollHeight(30 * props.length)
    }, [props.length])

    useEffect(() => {
        simpleBarRef.current.recalculate();
    })

    useEffect(() => {
        const handleScrollEvent = (evt) => {
            setScrollTop(evt.srcElement.scrollTop);
        }
        const wrapperRef = simpleBarRef.current.contentWrapperEl;
        wrapperRef.addEventListener("scroll", handleScrollEvent);
        return () => {
            wrapperRef.removeEventListener("scroll", handleScrollEvent);
        }
    }, [])

    const noRowsRenderer = () => {
        if(pastEventsStatus.loading)
            return <NoRowsContainer><Spinner width={50} height={50}/></NoRowsContainer>
        return <NoRowsContainer>Empty</NoRowsContainer>;
    }

    return (
        <AutoSizer>
            {({width, height}) => (
                <SimpleBar autoHide={false} style={{ height: height, width: width }} ref={simpleBarRef}>
                    <div style={{ height: scrollHeight }}>
                        <List
                            height={height}
                            width={width}
                            rowCount={props.length}
                            rowHeight={30}
                            rowRenderer={props.rowRenderer}
                            noRowsRenderer={noRowsRenderer}
                            scrollTop={scrollTop}
                        />
                    </div>
                </SimpleBar>
            )}
        </AutoSizer>
    )
}

export { ListWrapper };