import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Wrapper, Data, Label, Button, TitleRow, Nested, DenseRow } from 'components/detail/basic/styled';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { useArray, useIsHovered } from 'hooks/core';
import { F } from 'services';

const ExchangeBalances = (props) => {
    const exchange = props.exchange;
    const [expanded, setExpanded] = useState(false);
    const currencies = useSelector(state => state.currencyList);
    const accounts = useSelector(state => state.accountList);
    const clearers = useSelector(state => state.clearerList);
    const contractIDList = useSelector(state => state.contractIDList);
    const [expandedCurrencies, addEc, removeEc] = useArray([]);
    const [expandedBfc, addBfc, removeBfc] = useArray([]);
    const [expandedSupplies, addSpl, removeSpl] = useArray([]);
    const [currencyHovered, onCurrencyEnter, onCurrencyLeave] = useIsHovered();
    const [totalBalanceHovered, onTotalBalanceEnter, onTotalBalanceLeave] = useIsHovered();
    const [totalBfcHovered, onTotalBfcEnter, onTotalBfcLeave] = useIsHovered();

    return (
        <React.Fragment>
            <Wrapper as={TitleRow}>
                <Label>{expanded ? <Button as={FaAngleUp} onClick={() => setExpanded(false)}/> : <Button as={FaAngleDown} onClick={() => setExpanded(true)}/>}</Label>
                <Data bold>Total Balance</Data>
                <Button></Button>
            </Wrapper>
            {expanded &&
                currencies.map(e => {
                    let [totalSupply, clearerSupply] = [0, {}];
                    accounts.forEach(a => {
                        const intersectClearers = exchange.clearers.filter(x => a.clearers.includes(x));
                        if(intersectClearers.length > 0) {
                            const amount = (a.ccyBalances.find(x => x.currencySc === e.item)?.amount ?? 0);
                            totalSupply += amount;
                            intersectClearers.forEach(x => {
                                clearerSupply[x] = clearerSupply[x] ? clearerSupply[x] + amount : amount;
                            });
                        }
                    });
                    let bfc = {
                        total: 0,
                    };
                    contractIDList.filter(c => exchange.clearers.includes(c.clearerSc)).forEach(c => {
                        const bfcs = c.data.filter(x => x.exchangeSc === exchange.item && x.currencySc === e.item);
                        bfcs.forEach(x => {
                            bfc.total += x.amount;
                            bfc[x.contractID] = bfc[x.contractID] ? bfc[x.contractID] + x.amount : x.amount;
                        });
                    });
                    return (
                        <React.Fragment>
                            <Wrapper depth={1} as={Nested} onMouseEnter={onCurrencyEnter} onMouseLeave={onCurrencyLeave} bgColor={currencyHovered && "#b334844b"}>
                                <Label>
                                    {expandedCurrencies.find(x => x === e.item) 
                                        ? <Button onClick={() => removeEc(e.item)} as={FaAngleUp}/> 
                                        : <Button onClick={() => addEc(e.item)} as={FaAngleDown}/>
                                    }
                                </Label>
                                <Data>
                                    {e.symbol} - {e.name}
                                </Data>
                            </Wrapper>
                            {expandedCurrencies.find(x => x === e.item) &&
                                <React.Fragment>
                                    <React.Fragment>
                                        <Wrapper depth={2} as={Nested} onMouseEnter={onTotalBalanceEnter} onMouseLeave={onTotalBalanceLeave} bgColor={totalBalanceHovered && "#b334844b"}>
                                            <Label>Total Balance</Label>
                                            <Data>{F.currency(totalSupply, e.decimals)}</Data>
                                            {expandedSupplies.find(x => x === e.item) 
                                                    ? <Button onClick={() => removeSpl(e.item)} as={FaAngleUp}/>
                                                    : <Button onClick={() => addSpl(e.item)} as={FaAngleDown}/>
                                            }
                                        </Wrapper>
                                        {expandedSupplies.find(x => x === e.item) &&
                                            Object.entries(clearerSupply).map(([key, value], idx) => {
                                                const clearer = clearers.find(clr => clr.item === key);
                                                return(
                                                    <DenseRow last={Object.entries(clearerSupply).length === idx + 1} depth={3} as={Nested}>
                                                        <Label>{clearer?.name}</Label>
                                                        <Data>{F.currency(value, e.decimals)}</Data>
                                                        <Button></Button>
                                                    </DenseRow>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                    <React.Fragment>
                                        <Wrapper depth={2} as={Nested} onMouseEnter={onTotalBfcEnter} onMouseLeave={onTotalBfcLeave} bgColor={totalBfcHovered && "#b334844b"}>
                                            <Label>Total BFC</Label>
                                            <Data>{F.currency(bfc.total, e.decimals)}</Data>
                                            {expandedBfc.find(x => x === e.item) 
                                                ? <Button onClick={() => removeBfc(e.item)} as={FaAngleUp}/>
                                                : <Button onClick={() => addBfc(e.item)} as={FaAngleDown}/>
                                            }
                                        </Wrapper>
                                        {expandedBfc.find(x => x === e.item) &&
                                            Object.entries(bfc).map(([key, value], idx) => {
                                                if(key === "total") return null;
                                                return(
                                                    <DenseRow last={Object.entries(bfc).length === idx + 1} depth={3} as={Nested}>
                                                        <Label>{key}</Label>
                                                        <Data>{F.currency(value, e.decimals)}</Data>
                                                        <Button></Button>
                                                    </DenseRow>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    );
}

export { ExchangeBalances };