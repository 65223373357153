import styled from 'styled-components';

export const Column = styled.div`
    flex: ${props => props.flex ? props.flex : 1};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 100%;
    font-weight: ${props => props.head ? "bold" : "medium"};
    overflow: hidden;
`;

export const ColumnText = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;