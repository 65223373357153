import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Login, Main } from 'screens';
import { Web3Context } from 'context/Web3Context';
import { useWeb3Instance } from 'hooks/blockchain';

const Routes = () => {
    const [web3Instance, setWeb3Instance, clearWeb3Instance] = useWeb3Instance();
    const privileges = useSelector(state => state.privileges)

    return(
        <Web3Context.Provider value={{ web3Instance, setWeb3Instance, clearWeb3Instance }}>
            <Switch>
                <Route exact path="/" render={() => privileges.loaded ? <Main/> : <Login/>}/>
            </Switch>
        </Web3Context.Provider>
    )
}

export default Routes;