import { TransactionService } from 'services';
import { abiParser } from 'services/EventHandlingHelpers';

export const RegisterClearer = (clearerSc, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const abiName = state.privileges.selected.name;
        const contract = new web3.eth.Contract(abiParser(state, abiName), state.privileges.selected.scAddress);
        const method = () => contract.methods.registerClearer(clearerSc);
        TransactionService.executeTransaction(method, state.privileges.selected.scAddress, web3, state);
    }
}

export const DeregisterClearer = (clearerSc, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const abiName = state.privileges.selected.name;
        const contract = new web3.eth.Contract(abiParser(state, abiName), state.privileges.selected.scAddress);
        const method = () => contract.methods.deregisterClearer(clearerSc);
        TransactionService.executeTransaction(method, state.privileges.selected.scAddress, web3, state);
    }
}

export const Deposit = (clearerWallet, ccyAddress, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Currency"), ccyAddress);
        const method = () => clearerWallet ? contract.methods.deposit(clearerWallet, amount) : contract.methods.deposit(amount);
        TransactionService.executeTransaction(method, ccyAddress, web3, state, "deposit");
    }
}

export const ClearerDepositDefaultFund = (exchangeSc, symbol, amount, ccyAddress, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Currency"), ccyAddress);
        const method = () => contract.methods.depositDefaultFund(exchangeSc, symbol, amount);
        TransactionService.executeTransaction(method, ccyAddress, web3, state, "depositDefaultFund");
    }
}

export const ClearerWithdrawFromDefaultFund = (exchangeSc, clearerSc, symbol, amount, ccyAddress, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Clearer"), clearerSc);
        const method = () => contract.methods.withdrawFromDefaultFund(exchangeSc, symbol, ccyAddress, amount);
        TransactionService.executeTransaction(method, clearerSc, web3, state, "withdrawFromDefaultFund");
    }
}

export const SetDFCOptionParams = (clearerSc, exchangeSc, symbol, currencySc, option, isActive, percentage, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Clearer"), clearerSc);
        const method = () => contract.methods.setDFC_OptionParams(exchangeSc, symbol, currencySc, option, isActive, percentage, amount);
        TransactionService.executeTransaction(method, clearerSc, web3, state, "setDFC_OptionParams");
    }
}

export const RunDFCCalc = (clearerSc, exchangeSc, symbol, currencySc, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Clearer"), clearerSc);
        const method = () => contract.methods.runDFC_Calc(exchangeSc, symbol, currencySc);
        TransactionService.executeTransaction(method, clearerSc, web3, state, "runDFC_Calc");
    }
}

export const SetVar = (clearerSc, exchangeSc, symbol, currencySc, percent, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Clearer"), clearerSc);
        const method = () => contract.methods.setDFC_Option(exchangeSc, symbol, currencySc, percent);
        TransactionService.executeTransaction(method, clearerSc, web3, state, "setDFC_Option");
    }
}