import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { useDetailViewValues } from 'hooks/detailView';
import { DetailViewRoot, DetailViewContent } from 'components/detail/styled';
import { PanelHeader } from 'components/core/basic';
import { DetailViewDataRow } from 'components/detail';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { SetBufferFundRow } from 'entities/Exchange/inputs';
import { useSingleEntity } from 'hooks/core';

const DetailView = () => {
    const { selectedEntity, selectedRowID, data } = useDetailViewValues("contractList");
    const privileges = useSelector(state => state.privileges);
    const thisExchange = useSingleEntity("exchangeList", data?.exchange);
    const [showSetBFC, setShowSetBFC] = useState(false);

    const headerButtons = () => {
        if(privileges.selected.name === "CCP" && selectedRowID)
            return [
                {icon: FaFileInvoiceDollar, onClick: () => setShowSetBFC(true), active: showSetBFC, disabled: showSetBFC, tooltip: "Set Buffer Fund Contribution"}, 
            ]
    }

    if(selectedEntity !== "Contract")
        return null;
    return (
        <DetailViewRoot>
            <PanelHeader title={selectedRowID === null ? "No Row Selected" : ""} buttons={headerButtons()}/>
            {showSetBFC &&
                <SetBufferFundRow symbol={data?.symbol} withSymbol exchange={thisExchange} close={() => setShowSetBFC(false)}/>
            }
            {data &&
                <DetailViewContent>
                    <DetailViewDataRow label={"Name"}>{data.name}</DetailViewDataRow>
                    <DetailViewDataRow label={"Symbol"}>{data.symbol}</DetailViewDataRow>
                    <DetailViewDataRow label={"Sc Address"}>{data.item}</DetailViewDataRow>
                </DetailViewContent>
            }
        </DetailViewRoot>
    )
}

export { DetailView };