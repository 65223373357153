import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ADD_COMPANY":
                draft.push({...action.payload, clearerSc: "", accounts: []});
                return draft;
            case "LOAD_COMPANY_ASYNC_VALUES":
                const updateIndex = draft.findIndex(e => e.item === action.payload.item);
                draft[updateIndex] = {...draft[updateIndex], ...action.payload};
                return draft;
            case "COMPANY_SET_CLEARER":
                {
                    const { clearerSc, companySc } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === companySc);
                    draft[updateIndex].clearerSc = clearerSc;
                }
                return draft;
            case "COMPANY_ADD_ACCOUNT":
                {
                    const { companySc, accountSc } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === companySc);
                    draft[updateIndex].accounts.push(accountSc);
                }
                return draft;
            default:
                return draft;
        }
    })
