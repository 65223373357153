import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListViewRoot, ListContent, ListHeaderRow } from 'components/list/styled';
import { ListColumn, ListWrapper, RowRenderer } from 'components/list';
import { Deploy } from 'entities/Account/inputs';
import { PanelHeader } from 'components/core/basic';
import { FaPlus, FaUserCheck } from 'react-icons/fa';

const ListView = () => {
    const selectedEntity = useSelector(state => state.selectedEntity);
    const exchanges = useSelector(state => state.exchangeList);
    const clearers = useSelector(state => state.clearerList);
    const privileges = useSelector(state => state.privileges);
    const list = useSelector(state => state.accountList);
    const [filteredList, setFilteredList] = useState([]);
    const [showDeploy, setShowDeploy] = useState(false);
    const [showRegistered, setShowRegistered] = useState(true);

    useEffect(() => {
        if(!["Clearer", "Exchange"].includes(privileges.selected.name))
            setFilteredList(list);
    }, [list, privileges])

    useEffect(() => {
        if(privileges.selected.name === "Exchange") {
            if(showRegistered) {
                setFilteredList(list);
                return;
            }
            const thisExchange = exchanges.find(e => e.item === privileges.selected.scAddress);
            setFilteredList(list.filter(e => !thisExchange?.accounts.includes(e.item)))
        }
    }, [list, showRegistered, exchanges, privileges])

    useEffect(() => {
        if(privileges.selected.name === "Clearer") {
            const thisClearer = clearers.find(e => e.item === privileges.selected.scAddress);
            setFilteredList(
                list.filter(e => thisClearer?.accounts.includes(e.item))
            );
        }
    }, [list, privileges, clearers])

    const clearerName = (clearerSc) => clearerSc ? clearers.find(e => e.item === clearerSc)?.name : undefined

    const rowRenderer = ({ key, index, style }) => (
        <RowRenderer key={key} index={index} style={style} item={filteredList[index].item}>
            <ListColumn>{filteredList[index].name}</ListColumn>
            <ListColumn timestamp>{filteredList[index].timestamp}</ListColumn>
            <ListColumn>{clearerName(filteredList[index].clearers[0])}</ListColumn>
        </RowRenderer>
    )

    const headerButtons = () => {
        if(privileges.selected.name === "Clearer")
            return [
                {icon: FaPlus, onClick: () => setShowDeploy(true), disabled: showDeploy, active: showDeploy, tooltip: "Deploy Account"}
            ]
        if(privileges.selected.name === "Exchange")
            return [
                {icon: FaUserCheck, onClick: () => setShowRegistered(c => !c), disabled: false, active: showRegistered, 
                    tooltip: `${showRegistered ? "Hide" : "Show" } Registered`}
            ]
        return [];
    }

    if(selectedEntity !== "Account")
        return null;
    return (
        <ListViewRoot>
            <PanelHeader title={"Accounts"} buttons={headerButtons()}/>
            {showDeploy &&
                <Deploy close={() => setShowDeploy(false)}/>
            }
            {filteredList.length > 0 && (
                <ListHeaderRow>
                    <ListColumn head>Name</ListColumn>
                    <ListColumn head>Timestamp</ListColumn>
                    <ListColumn head>Clearing Member</ListColumn>
                </ListHeaderRow>
            )}
            <ListContent headerVisible={filteredList.length > 0}>
                <ListWrapper length={filteredList.length} rowRenderer={rowRenderer}/>
            </ListContent>
        </ListViewRoot>
    )
}

export { ListView };