import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: var(--textPrimary);
    margin-bottom: 10px;
`;

export const InputBox = styled.div`
    display: flex;
    flex-direction: row;
`;

export const NameAndAddress = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const AddButton = styled.div`
    margin-left: 10px;
    font-size: 16px;
    flex: 0.1;
`;

export const ProviderList = styled.div`
    margin-top: 10px;
`;

export const InputTitleSecondary = styled.div`
    margin-top: 10px;
`;

export const ListContainer = styled.div`
    background-color: var(--bgDark);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    max-height: 125px;
    border-left: 2px solid var(--bgDark);
    padding-right: ${props => props.scrollvisible ? "12px" : "0px"};
`;

export const ListItem = styled.div`
    height: 25px;
    padding: 0px 0.5em 0px 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid var(--bgStrong);
    font-weight: ${props => props.selected ? "bold" : "medium"};
    border-left: ${props => props.selected ? "3px solid #2d7f9d" : "none"};
    &:hover {
        background-color: var(--textSecondary);
        color: var(--bgDark);
    }
    transition: background-color 0.2s;
    box-sizing: border-box;
`;

export const ListItemToolbox = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ListItemIcon = styled.div`
    margin-left: 10px;
    user-select: none;
`;

export const EditRow = styled.div`
    background-color: var(--bgLight);
    display: flex;
    flex-direction: row;
    padding: 10px;
`;

const EditRowItem = styled.div`
    &:not(:first-child) {
        margin-left: 5px;
    }
`;

export const EditInputContainer = styled(EditRowItem)`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const EditButton = styled(EditRowItem)`
    height: 25px;
    margin-left: 5px;
    align-self: flex-end;
    flex: 0.5;
    background-color: var(--textSecondary);
`;