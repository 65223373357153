import React from 'react';
import { useSelector } from 'react-redux';
import { useDetailViewValues } from 'hooks/detailView';
import { PanelHeader } from 'components/core/basic';
import { DetailViewRoot, DetailViewContent } from 'components/detail/styled';
import { DetailViewDataRow, DetailViewExpander } from 'components/detail';
import { ClearerDetailViewData } from 'entities/Clearer';
import { F } from 'services';

const rowData = [
    {label: "Name", key: "name"},
    {label: "Sc Address", key: "item"},
    {label: "Wallet", key: "wallet"},
]

const DetailViewData = (props) => {
    const { data } = props;
    return (
        <React.Fragment>
             {rowData.map(e => (
                 <DetailViewDataRow key={e.key} label={e.label}>{data[e.key]}</DetailViewDataRow>
             ))}
        </React.Fragment>
    )
}

export { DetailViewData };

const DetailView = () => {
    const { selectedEntity, selectedRowID, data } = useDetailViewValues("custodianList");
    const clearers = useSelector(state => state.clearerList);
    const currencies = useSelector(state => state.currencyList);
    
    if(selectedEntity !== "Custodian")
        return null;
    return (
        <DetailViewRoot>
            <PanelHeader title={selectedRowID === null ? "No Row Selected" : ""}/>
            {data &&
                <DetailViewContent>
                    <DetailViewData data={data}/>
                    {data.clearers.length > 0 &&
                        <DetailViewExpander label={"Registered Clearers"}>
                            {data.clearers.map(e => (
                                <DetailViewExpander key={e} reverse label={clearers.find(c => c.item === e).name}>
                                    <ClearerDetailViewData data={clearers.find(c => c.item === e)}/>
                                </DetailViewExpander>
                            ))}
                        </DetailViewExpander>
                    }
                    {data.totalMint.length > 0 &&
                        <DetailViewExpander label={"Currency Issued"}>
                            {data.totalMint.map(e => {
                                const currency = currencies.find(ccy => ccy.item === e.currencySc);
                                return(
                                    <DetailViewDataRow key={e} reverse label={`${currency.name}/${currency.symbol}`}>
                                        {F.currency(e.amount, currency.decimals)}
                                    </DetailViewDataRow>
                                )
                            })}
                        </DetailViewExpander>
                    }
                    {data.totalBurn.length > 0 &&
                        <DetailViewExpander label={"Currency Burned"}>
                            {data.totalBurn.map(e => {
                                const currency = currencies.find(ccy => ccy.item === e.currencySc);
                                return(
                                    <DetailViewDataRow key={e} reverse label={`${currency.name}/${currency.symbol}`}>
                                        {F.currency(e.amount, currency.decimals)}
                                    </DetailViewDataRow>
                                )
                            })}
                        </DetailViewExpander>
                    }
                </DetailViewContent>
            }
        </DetailViewRoot>
    )
}

export { DetailView };