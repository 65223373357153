import styled from 'styled-components';

export const Column = styled.div`
    flex: ${props => props.flex ? props.flex : 1};
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
        
    }
    font-size: 14px;
    height: 100%;
    font-weight: ${props => props.head ? "bold" : "medium"};
    overflow: hidden;
`;

export const ColumnText = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ListRowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px 0px 5px;
    box-sizing: border-box;
`;

export const ListRow = styled.div`
    flex: 1;
    height: 25px;
    ${props => props.selected 
        ? "background-color: var(--bgLight);"
        : "background-color: rgba(0, 0, 0, " + (props.index % 2 === 0 ? 0.25 : 0.15) + ");"
    }
    color: ${props => props.selected ? "var(--textPrimary)" : "var(--textSecondary)"};
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        cursor: pointer;
        color: var(--textPrimary);
    }
`;

export const NoRowsContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;