import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ACCOUNT_ADD_PHYSICAL_SETTLEMENT":
                draft.push({...action.payload, status: "PENDING"});
                return draft;
            case "ACCOUNT_PHYSICAL_SETTLEMENT_PROCESSED":
                {
                    const { settlementID } = action.payload;
                    const updateIndex = draft.findIndex(e => e.settlementID === settlementID);
                    draft[updateIndex].status = "PROCESSED";
                }
                return draft;
            case "ACCOUNT_REJECT_PHYSICAL_SETTLEMENT":
                {
                    const { settlementID } = action.payload;
                    const updateIndex = draft.findIndex(e => e.settlementID === settlementID);
                    draft[updateIndex].status = "REJECTED";
                }
                return draft;
            case "SET_SETTLEMENT_TIMESTAMP":
                {
                    const { settlementID, timestamp } = action.payload;
                    const updateIndex = draft.findIndex(e => e.settlementID === settlementID);
                    draft[updateIndex].timestamp = timestamp;
                }   
                return draft;
            default:
                return draft;
        }
    })
