import React from 'react';
import { DataRow, Data, Settlement } from '../styled';
import { Spinner } from 'components/core/basic';
import { useSingleEntity } from 'hooks/core';
import { F } from 'services';

const Loader = () => <Spinner width={10} height={10} borderSize={5}/>

const SettlementRow = (props) => {
    const { sender, to ,amount, currencySc } = props.data;
    const senderAcc = useSingleEntity("accountList", sender);
    const receiverAcc = useSingleEntity("accountList", to);
    const ccy = useSingleEntity("currencyList", currencySc);

    return (
        <DataRow as={Settlement}>
            <Data>
                from&nbsp;
                <React.Fragment>{senderAcc?.name ?? <Loader/>}</React.Fragment>&nbsp;
                {F.currency(amount, ccy?.decimals)}&nbsp;
                <React.Fragment>{ccy?.symbol ?? <Loader/>}</React.Fragment>&nbsp;
                to&nbsp;
                <React.Fragment>{receiverAcc?.name ?? <Loader/>}</React.Fragment>
            </Data>
        </DataRow>
    );
}

export default SettlementRow;