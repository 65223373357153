import styled from 'styled-components';

export const Button = styled.div`
    border-radius: 2px;
    background-color: var(--textSecondary);
    color: var(--bgDark);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${props => props.disabled === true ? "0.3" : "1"};
    &:hover {
        cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
        background-color: var(--contrast);
    }
    transition: background-color 0.2s;
    box-sizing: border-box;
    user-select: none;
    ${props => props.inactive === true 
        ? "opacity: 0.3; &:hover { cursor: not-allowed; }"
        : ""
    }
`;