import React, { useState , useEffect } from 'react';
import { ListViewRoot, ListContent, ListHeaderRow } from 'components/list/styled';
import { ListColumn, ListWrapper, RowRenderer, useListViewValues, DeployRow } from 'components/list';
import { PanelHeader } from 'components/core/basic';
import { FaPlus, FaUserCheck } from 'react-icons/fa';

const ListView = () => {
    const { selectedEntity, list, privileges, showDeploy, setShowDeploy } = useListViewValues("clearerList");
    const [filteredList, setFilteredList] = useState([]);
    const [showRegistered, setShowRegistered] = useState(true);

    useEffect(() => {
        if(showRegistered) {
            setFilteredList(list);
            return;
        }
        setFilteredList(list.filter(e => !e.custodians.includes(privileges.selected.scAddress)));
    }, [list, showRegistered, privileges])

    const rowRenderer = ({ key, index, style }) => (
        <RowRenderer key={key} index={index} style={style} item={filteredList[index].item}>
            <ListColumn>{filteredList[index].name}</ListColumn>
            <ListColumn timestamp>{filteredList[index].timestamp}</ListColumn>
            <ListColumn>{filteredList[index].accounts.length}</ListColumn>
        </RowRenderer>
    )

    const headerButtons = () => {
        if(privileges.selected.name === "CCP")
            return [
                {icon: FaPlus, onClick: () => setShowDeploy(true), disabled: showDeploy, active: showDeploy, tooltip: "Deploy Clearing Member"}
            ]
        if(privileges.selected.name === "Custodian")
            return [
                {icon: FaUserCheck, onClick: () => setShowRegistered(c => !c), 
                    disabled: false, active: showRegistered, tooltip: `${showRegistered ? "Hide" : "Show"} Registered`}
            ]
        return [];
    }
    
    if(selectedEntity !== "Clearer")
        return null;
    return (
        <ListViewRoot>
            <PanelHeader title={"Clearing Member"} buttons={headerButtons()}/>
            {showDeploy &&
                <DeployRow close={() => setShowDeploy(false)} type={"Clearer"}/>
            }
            {filteredList.length > 0 && (
                <ListHeaderRow>
                    <ListColumn head>Name</ListColumn>
                    <ListColumn head>Timestamp</ListColumn>
                    <ListColumn head>Reg. Accounts</ListColumn>
                </ListHeaderRow>
            )}
            <ListContent headerVisible={filteredList.length > 0}>
                <ListWrapper length={filteredList.length} rowRenderer={rowRenderer}/>
            </ListContent>
        </ListViewRoot>
    )
}

export { ListView };