import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCompanySupply = (companySc) => {
    const accounts = useSelector(state => state.accountList);
    const [companyBalance, setCompanyBalance] = useState({});

    useEffect(() => {
        let companyBalance = {};
        accounts.filter(acc => companySc === acc.company).forEach(acc => {
            acc.ccyBalances.forEach(bl => {
                companyBalance[bl.currencySc] = companyBalance[bl.currencySc] ? companyBalance[bl.currencySc] + bl.amount : bl.amount;
            });
        });
        setCompanyBalance(companyBalance);
    }, [companySc, accounts])
    
    return companyBalance;
} 

export { useCompanySupply };