import React, { useEffect, useState } from 'react';
import { InputItem2x0, Wrapper } from 'components/inputs/styled';
import { Confirm , Close, useDefaultInputValues } from 'components/inputs';
import { DefaultSelect } from 'components/core/selectors';
import { useValidation } from 'hooks/core';
import Cleave from 'cleave.js/react';
import { SetDFCOptionParams } from 'entities/Clearer/transactions';


const cleaveDimension = {
    height: "25px", width: "100%",
}

const isActiveList = [
    {value: "true"}, {value: "false"},
]

const EditOptionParams = (props) => {
    const { exchangeSc, clearer, currencySc, symbol, type, decimals, initialPercent, notional, isActive } = props;
    const { dispatch, web3Instance} = useDefaultInputValues();
    const [active, setActive] = useState(isActiveList[0].value);
    const [percent, setPercent] = useState(initialPercent ?? "");
    const [amount, setAmount] = useState(notional ? notional / Math.pow(10, decimals) : "");
    const [disabled, errorText] = useValidation([
        {
            validation: () => percent === "",
            errorText: "Percent can't be empty",
        },
        {
            validation: () => amount === "",
            errorText: "Notional can't be empty",
        }
    ]);

    useEffect(() => {
        if(percent > 100) setPercent(100);
    }, [percent])

    const submit = () => {
        if(disabled) return;
        dispatch(SetDFCOptionParams(
            clearer?.item,
            exchangeSc,
            symbol,
            currencySc,
            type,
            active === "true" ? true : false,
            percent,
            amount * Math.pow(10, decimals),
            web3Instance,
        ));
        props.close();
    }

    return (
        <Wrapper bg={"transparent"}>
            <InputItem2x0>
                <Cleave
                    value={percent}
                    className={"cleaveStyle"} 
                    style={cleaveDimension}
                    placeholder={"Percent..."} 
                    onChange={e => setPercent(e.target.value)} 
                    options={{numericOnly: true, numeralPositiveOnly: true}}
                />
            </InputItem2x0>
            <InputItem2x0>
                <Cleave  
                    value={amount}
                    className={"cleaveStyle"} 
                    style={cleaveDimension} 
                    placeholder={"Notional..."}
                    onChange={e => setAmount(e.target.rawValue)} 
                    options={{numeral: true, numeralDecimalScale: decimals}}
                />
            </InputItem2x0>
            <InputItem2x0>
                <DefaultSelect
                    label={"Is Active"}
                    onChange={s => setActive(s.value)}
                    formatValue={e => e.value}
                    list={isActiveList}
                    initial={isActive ? {value: "true"} : {value: "false"}}
                />
            </InputItem2x0>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Set Option Params"} disabled={disabled} onClick={submit}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { EditOptionParams };