import { useState, useEffect } from 'react';

const useDimensions = (ref) => {
    const { current } = ref;
    const [loaded, setLoaded] = useState(false);
    const [overflownWidth, setOverflownWidth] = useState(false);
    const [overflownHeight, setOverflownHeight] = useState(false);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setLoaded(true);
    }, [])

    useEffect(() => {
        if(current === undefined) return;
        const resizeObserver = new ResizeObserver((entries) => {
            const { clientWidth, scrollWidth, clientHeight, scrollHeight } = entries[0].target;
            setWidth(clientWidth);
            setHeight(clientHeight);
            if(clientWidth < scrollWidth)
                setOverflownWidth(true)
            else
                setOverflownWidth(false)
            if(clientHeight < scrollHeight)
                setOverflownHeight(true)
            else
                setOverflownHeight(false)
        })
        resizeObserver.observe(current);
        return () => {
            resizeObserver.disconnect();
        }
    }, [current, loaded])
       
    return { overflownWidth, width, height, overflownHeight } ;
}

export { useDimensions };