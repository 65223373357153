import produce from 'immer';

const initialState = {
    loaded: false,
    data: {
        CCP: {
            hasPrivilege: false,
            scAddress: null,
            entities: ["Custodian", "Exchange", "Clearer", "Account", "Currency", "Contract", "Market"],
        },
        Exchange: {
            hasPrivilege: false,
            scAddress: null,
            entities: ["Clearer", "Account", "Currency", "Contract", "Market"],
        },
        Clearer: {
            hasPrivilege: false,
            scAddress: null,
            entities: ["Company", "Account", "Currency"],
        },
        Custodian: {
            hasPrivilege: false,
            scAddress: null,
            entities: ["Clearer", "Currency", "Settlement"],
        },
        Company: {
            hasPrivilege: false,
            scAddress: null,
            entities: ["Company"],
        }
    },
    error: false,
    selected: null,
}

export default (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case "SET_CCP":
                draft.data.CCP.hasPrivilege = true;
                return draft;
            case "SET_EXCHANGE":
                draft.data.Exchange.hasPrivilege = true;
                draft.data.Exchange.scAddress = action.payload;
                return draft;
            case "SET_CLEARER":
                draft.data.Clearer.hasPrivilege = true;
                draft.data.Clearer.scAddress = action.payload;
                return draft;
            case "SET_CUSTODIAN":
                draft.data.Custodian.hasPrivilege = true;
                draft.data.Custodian.scAddress = action.payload;
                return draft;
            case "SET_COMPANY":
                draft.data.Company.hasPrivilege = true;
                draft.data.Company.scAddress = action.payload;
                return draft;
            case "SET_SELECTED_PRIVILEGE":
                return draft;
            case "PRIVILEGES_LOADED":
                const initSelectedPrivilege = () => {
                    const types = ["CCP", "Exchange", "Clearer", "Custodian"];
                    types.some(e => {
                        if(draft.data[e].hasPrivilege) {
                            draft.selected = {...draft.data[e], name: e};
                            return true;
                        }
                        return false;
                    })
                }
                initSelectedPrivilege();
                if(draft.selected === null)
                    draft.selected = {...draft.data.Company, name: "None"}
                draft.loaded = true;
                return draft;
            default:
                return draft;
        }
    })
