import { populateNodeAndDispatch, populateNodeProps } from 'services/EventHandlingHelpers';

export const AddMarket = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {
            exchange: evt.returnValues._node,
            contract: evt.returnValues._contract,
        };
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setExtraMethods(["type_", "lastTradingDate", "initialMargin", "openInterest"]).setDisabledMethods(["ccp", "wallet"])
            .setAbiName("Market").setActionType("ADD_MARKET").setAsyncActionType("LOAD_MARKET_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const CcpAdjustInitialMargin = (evt, web3) => {
    return (dispatch, getState) => {
        const { _market, _marginPercentage } = evt.returnValues;
        dispatch({ type: "MARKET_UPDATE_INITIAL_MARGIN", payload: {marketSc: _market, margin: _marginPercentage, blockNumber: evt.blockNumber} });
        web3.eth.getBlock(evt.blockNumber).then(r => {
            dispatch({ type: "MARKET_SET_TIMESTAMP", payload: {timestamp: r.timestamp, blockNumber: evt.blockNumber} });
        });
    }
}

export const ExchangeAdjustInitialMargin = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _marginPercentage, _exchange } = evt.returnValues; 
        dispatch({ type: "MARKET_UPDATE_INITIAL_MARGIN", payload: {marketSc: _node, margin: _marginPercentage, exchange: _exchange, blockNumber: evt.blockNumber} });
        web3.eth.getBlock(evt.blockNumber).then(r => {
            dispatch({ type: "MARKET_SET_TIMESTAMP", payload: {timestamp: r.timestamp, blockNumber: evt.blockNumber} });
        });
    }
}

export const ExchangeUpdateOpenInterest = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _amount } = evt.returnValues;
        dispatch({ type: "MARKET_UPDATE_OPEN_INTEREST", payload: {marketSc: _node, amount: _amount} });
    }
}

export const marketEvents = {
    "Exchange_addMarket": AddMarket,
    "Exchange_adjustInitialMargin": CcpAdjustInitialMargin,
    "Exchange_Market_adjustInitialMargin": ExchangeAdjustInitialMargin,
    "Exchange_Market_updateOpenInterest": ExchangeUpdateOpenInterest,
}