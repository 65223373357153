import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { DepositAccount } from 'entities/Account/transactions';
import Cleave from 'cleave.js/react';
import { useValidation, useAddressBalance, useSingleEntity } from 'hooks/core';
import { DefaultSelect } from 'components/core/selectors';

const AccountDepositRow = (props) => {
    const { dispatch, web3Instance, cleave, setCleave, amount, setAmount } = useDefaultInputValues();
    const currencies = useSelector(state => state.currencyList);
    const [selectedCcy, setSelectedCcy] = useState({});
    const clearerWallet = useSelector(state => state.accounts.selected.address);
    const thisBalance = useAddressBalance(clearerWallet);
    const thisAccount = useSingleEntity("accountList", props.accountSc);
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount === "",
            errorText: "Can't deposit 0 amount",
        },
        {
            validation: () => thisBalance?.balances.find(e => e.currencySc === selectedCcy.item).value < cleave.getRawValue() * Math.pow(10, selectedCcy.decimals),
            errorText: "Can't deposit more than in Balance",
        },
        {
            validation: () => thisAccount?.exchanges.length === 0 ?? true,
            errorText: "Account not registered with Exchange",
        }
    ]);

    const deposit = () => {
        if(disabled) return;
        dispatch(DepositAccount(
            selectedCcy.item,
            props.accountSc,
            cleave.getRawValue() * Math.pow(10, selectedCcy.decimals),
            web3Instance,
        ));
        props.close();
    }

    return (
        <Wrapper>
            <Inputs>
                <InputItem width={"50%"}>
                    <Cleave onInit={cleave => setCleave(cleave)}
                        className={"cleaveStyle"} style={{height: "25px", width: "100%"}} placeholder={"Amount..."} onChange={e => setAmount(e.target.value)} 
                        options={{numeral: true, numeralDecimalScale: selectedCcy.decimals}}
                    />
                </InputItem>
                <InputItem width={"50%"}>
                    <DefaultSelect
                        label={"Currency"}
                        onChange={selected => setSelectedCcy(selected)}
                        formatValue={(e) => `${e.name}/${e.symbol}`}
                        list={currencies}
                    />
                </InputItem>
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Deposit"} disabled={disabled} onClick={deposit}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { AccountDepositRow };