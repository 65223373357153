import React, { useState, useEffect } from 'react';
import { InputItem2x0, Wrapper } from 'components/inputs/styled';
import { Confirm , Close, useDefaultInputValues } from 'components/inputs';
import { useValidation } from 'hooks/core';
import Cleave from 'cleave.js/react';
import { SetVar } from 'entities/Clearer/transactions';

const cleaveDimension = {
    height: "25px", width: "100%",
}

const EditVarRow = (props) => {
    const { exchangeSc, clearerSc, currencySc, symbol } = props;
    const { dispatch, web3Instance} = useDefaultInputValues();
    const [percent, setPercent] = useState("");
    const [disabled, errorText] = useValidation([
        {
            validation: () => percent === "",
            errorText: "Percent can't be empty",
        },
    ])

    useEffect(() => {
        if(percent > 100) setPercent(100);
    }, [percent])

    const submit = () => {
        if(disabled) return;
        dispatch(SetVar(
            clearerSc,
            exchangeSc,
            symbol,
            currencySc,
            Number(percent),
            web3Instance,
        ));
        props.close();
    }

    return(
        <Wrapper>
            <InputItem2x0>
                <Cleave
                    value={percent}
                    className={"cleaveStyle"} 
                    style={cleaveDimension}
                    placeholder={"VAR..."} 
                    onChange={e => setPercent(e.target.value)} 
                    options={{numericOnly: true, numeralPositiveOnly: true}}
                />
            </InputItem2x0>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Set VAR"} disabled={disabled} onClick={submit}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { EditVarRow };