import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useDetailViewValues = (listName, pk = "item") => {
    const selectedEntity = useSelector(state => state.selectedEntity);
    const selectedRowID = useSelector(state => state.selectedRowID);
    const list = useSelector(state => state[listName]);
    const [data, setData] = useState(null);

    useEffect(() => {
        setData(list.find(e => e[pk] === selectedRowID));
    }, [selectedRowID, list, pk])

    return { selectedEntity, selectedRowID, data }
} 

export { useDetailViewValues };