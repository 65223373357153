import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    border-radius: 2px;
    background-color: ${props => props.bgColor ?? "rgba(0, 0, 0, 0.25)"};
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: ${props => props.noMargin ? "0px" : "5px"};
    display: flex;
    flex-direction: row;
    // align-items: center;
    &:hover {
        ${props => props.disableHover ? "" : 
            "background-color: var(--bgLight);" +
            "color: var(--textPrimary);" +
            "cursor: pointer;"
        }
    }
    height: ${props => props.height ? props.height : "auto"};
`;

export const Data = styled.div`
    flex: 3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${props => props.bold && "font-weight: bold;"}
    ${props => props.decoration && "text-decoration: underline;"}
`;