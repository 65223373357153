import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useAddressBalance = (address) => {
    const addressCcyBalances = useSelector(state => state.addressCcyBalances);
    const [thisBalance, setThisBalance] = useState(null);

    useEffect(() => {
        const foundThisBalance = addressCcyBalances.find(e => e.address === address);
        if(foundThisBalance)
            setThisBalance(foundThisBalance);
        else
            setThisBalance(null);
    }, [addressCcyBalances, address])

    return thisBalance;
}

export { useAddressBalance };