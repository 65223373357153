import React, { useState, useEffect, useRef } from 'react';
import { Container, Content, FixedContent } from './styled';
import { useDimensions } from 'hooks/core';

const Dropdown = (props) => {
    const [show, setShow] = useState(false);
    const containerRef = useRef();
    const { toggleCallback, ...other } = props;
    const dimensions = useDimensions(containerRef);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        if(toggleCallback)
            toggleCallback(toggle);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [toggleCallback])

    const handleClickOutside = (evt) => {
        if(containerRef.current && !containerRef.current.contains(evt.target))
            setShow(false);
    }

    const toggle = () => {
        setShow(c => !c);
    }

    return (
        <Container ref={containerRef} fitContainerHeight={props.fitContainerHeight}>
            {React.cloneElement(other.children[0], { onClick: toggle })}
            {show && (
                <Content>
                    <FixedContent width={dimensions.width}>
                        {other.children[1]}
                    </FixedContent>
                </Content>
            )}
        </Container>
    )
}

export default Dropdown;