import styled from 'styled-components';

export const RootContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
`;

export const MainBoard = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
`;