import * as actions from 'redux/reducers/Entities/actions';
import * as AccountActions from 'entities/Account/actions';
import { custodianEvents } from 'entities/Custodian/actions';
import { clearerEvents } from 'entities/Clearer/actions';
import { exchangeEvents } from 'entities/Exchange/actions';
import { currencyEvents } from 'entities/Currency/actions';
import { companyEvents } from 'entities/Company/actions';
import { ccpEvents } from 'entities/CCP/actions';
import { contractEvents } from 'entities/Contract/actions';
import { marketEvents } from 'entities/Market/actions'

const accountEvents = {
    "Company_add_Account": AccountActions.AddAccount,
    "Account_Deposit": AccountActions.AccountDeposit,
    "Account_WithdrawalProcessed": AccountActions.AccountWithdrawalProcessed,
    "Account_WithdrawalRequested": AccountActions.AccountWithdrawalRequested,
    "Account_WithdrawalCancelled": AccountActions.AccountWithdrawalCancelled,
    "Account_SettlementUpdate": AccountActions.AccountSettlementUpdate,
    "Account_PhysicalSettlement_Requested": AccountActions.AccountPhysicalSettlementRequested,
    "Account_PhysicalSettlement_Processed": AccountActions.AccountPhysicalSettlementProcessed,
    "Account_PhysicalSettlement_Rejected": AccountActions.AccountPhysicalSettlementRejected,
}

export const eventMeta = {
    // "CCP_addExchange" : actions.AddExchange,
    // "CCP_removeExchange": actions.RemoveExchange, 
    // "CCP_addClearer" : actions.AddClearer,
    // "CCP_removeClearer": actions.RemoveClearer,
    // "CCP_addCustodian": actions.AddCustodian,
    // "CCP_removeCustodian": actions.RemoveCustodian,
    // "Company_created": actions.AddCompany,
    // "Company_add_Account": actions.AddAccount,
    // "CCP_addCurrency": actions.AddCurrency,
    // "CCP_removeCurrency": actions.RemoveCurrency,
    // "CCP_addDefaultFundContribution": actions.CcpAddDefaultFundContribution,
    // "CCP_removeDefaultFundContribution": actions.CcpRemoveDefaultFundContribution,
    // "Custodian_registerClearer": actions.CustodianRegisterClearer,
    // "Custodian_deregisterClearer": actions.CustodianDeregisterClearer,
    // "Currency_IncreaseSupply": actions.CurrencyIncreaseSupply,
    // "Currency_DecreaseSupply": actions.CurrencyDecreaseSupply,
    "Currency_ClearerWithdrawal": actions.ClearerWithdrawal,
    "Currency_ClearerDeposit": actions.ClearerDeposit,
    "Currency_ClearerDefaultFundContribution": actions.ClearerDefaultFundContribution,
    // "Exchange_registerClearer": actions.ExchangeRegisterClearer,
    // "Exchange_deregisterClearer": actions.ExchangeDeregisterClearer,
    // "Exchange_registerAccount": actions.ExchangeRegisterAccount,
    // "Exchange_deregisterAccount": actions.ExchangeDeregisterAccount,
    // "Exchange_set_DefaultFundContribution": actions.ExchangeSetDefaultFundContribution,
    // "Exchange_addContract": actions.AddContract,
    // "Exchange_addMarket": actions.AddMarket,
    // "Exchange_adjustInitialMargin": actions.CcpAdjustInitialMargin,
    // "Exchange_Market_adjustInitialMargin": actions.ExchangeAdjustInitialMargin,
    // "Exchange_Market_updateOpenInterest": actions.ExchangeUpdateOpenInterest,
    // "Clearer_add_Company": actions.ClearerAddCompany,
    // "Clearer_remove_Company": actions.ClearerRemoveCompany,
    // "Clearer_update_DefaultFundContribution": actions.ClearerUpdateDefaultFundContribution,
    ...accountEvents,
    ...custodianEvents,
    ...clearerEvents,
    ...exchangeEvents,
    ...currencyEvents,
    ...companyEvents,
    ...ccpEvents,
    ...contractEvents,
    ...marketEvents,
}