import styled from 'styled-components';

export const Toggle = styled.div`
    user-select: none;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 5px 0px 5px;
    box-sizing: border-box;
    &:hover {
        color: var(--textPrimary);
        background-color: var(--bgLight);
        cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    }
    opacity: ${props => props.disabled ? 0.5 : 1};
`;

export const Label = styled.div`
    flex: 2;
    opacity: 0.75;
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Selected = styled.div`
    flex: 3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Expand = styled.div`
    width: 20px;
    text-align: center;
`;

export const Content = styled.div`
    background-color: var(--bgDark);
    color: var(--textSecondary);
    padding: 5px;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px var(--bgLight);
    overflow: hidden;
`;

export const RowItem = styled.div`
    cursor: pointer;
    &:hover {
        background-color: var(--contrast);
        color: var(--bgDark);
    }
    border-radius: 2px;
    &:not(:last-child) {
        margin-bottom: 5px;
    }
    padding: 5px;
    box-sizing: border-box;
`;