import produce from 'immer';

const initialOptions = [
    {key: "2", percent: null, notional: null, isActive: false},
    {key: "3", percent: null, notional: null, isActive: false},
    {key: "4", percent: null, notional: null, isActive: false},
]

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ADD_CONTRACT_ID":
                const { exchangeSc, contractID, currencySc, amount, clearerSc } = action.payload;
                const clearerIndex = draft.findIndex(e => e.clearerSc === clearerSc);
                if(clearerIndex === -1) {
                    draft.push({
                        clearerSc: clearerSc,
                        data:[{
                            exchangeSc: exchangeSc,
                            contractID: contractID,
                            currencySc: currencySc,
                            amount: parseInt(amount),
                            options: initialOptions,
                        }]
                    });
                }
                else {
                    const addIndex = draft[clearerIndex].data
                        .findIndex(e => e.exchangeSc === exchangeSc && e.contractID === contractID && e.currencySc === currencySc);
                    if(addIndex === -1) {
                        draft[clearerIndex].data.push({
                            exchangeSc: exchangeSc,
                            contractID: contractID,
                            currencySc: currencySc,
                            amount: parseInt(amount),
                            options: initialOptions,
                        })
                    }
                    else
                        draft[clearerIndex].data[addIndex].amount = parseInt(amount);
                }
                return draft;
            case "ADD_CONTRACT_ID_OPTION":{
                const { clearerSc, exchangeSc, contractID, currencySc, option, isActive, percent, notional } = action.payload;
                const clearerIndex = draft.findIndex(e => e.clearerSc === clearerSc);
                if(clearerIndex === -1) {
                    draft.push({
                        clearerSc: clearerSc,
                        data:[{
                            exchangeSc: exchangeSc,
                            contractID: contractID,
                            currencySc: currencySc,
                            amount: 0,
                            options: [
                                ...initialOptions.filter(e => e.key !== option),
                                {key: option, percent: percent, notional: notional, isActive: isActive},
                            ]
                        }]
                    });
                }
                else{
                    const addIndex = draft[clearerIndex].data
                        .findIndex(e => e.exchangeSc === exchangeSc && e.contractID === contractID && e.currencySc === currencySc);
                    if(addIndex === -1) {
                        draft[clearerIndex].data.push({
                            exchangeSc: exchangeSc,
                            contractID: contractID,
                            currencySc: currencySc,
                            amount: 0,
                            options: [
                                ...initialOptions.filter(e => e.key !== option),
                                {key: option, percent: percent, notional: notional, isActive: isActive},
                            ]
                        })
                    }
                    else {
                        draft[clearerIndex].data[addIndex].options = [
                            ...draft[clearerIndex].data[addIndex].options.filter(e => e.key !== option),
                            {key: option, percent: percent, notional: notional, isActive: isActive},
                        ]
                    }
                }
                return draft;
            }
            case "CONTRACT_ID_RUN_CALC":{
                const { clearerSc, exchangeSc, contractID, currencySc, contribution } = action.payload;
                const clearerIndex = draft.findIndex(e => e.clearerSc === clearerSc);
                const dataIndex = draft[clearerIndex].data
                        .findIndex(e => e.exchangeSc === exchangeSc && e.contractID === contractID && e.currencySc === currencySc);
                draft[clearerIndex].data[dataIndex].contribution = contribution;
                return draft;
            }
            case "EXCHANGE_SET_DFC":{
                const { exchangeSc, contractID, currencySc, clearerScList } = action.payload;
                clearerScList.forEach(e => {
                    const dataIndex = draft.findIndex(x => x.clearerSc === e);
                    if(dataIndex === -1)
                        draft.push({
                            clearerSc: e,
                            data:[{
                                exchangeSc: exchangeSc,
                                contractID: contractID,
                                currencySc: currencySc,
                                amount: 0,
                                options: initialOptions,
                            }]
                        })
                    else {
                        const bfcIndex = draft[dataIndex].data.findIndex(x => 
                            x.exchangeSc === exchangeSc && x.contractID === contractID && x.currencySc === currencySc)
                        if(bfcIndex === -1)
                            draft[dataIndex].data.push({
                                exchangeSc: exchangeSc,
                                contractID: contractID,
                                currencySc: currencySc,
                                amount: 0,
                                options: initialOptions,
                            })
                    }
                });
                return draft;
            }
            case "ADD_CONTRACT_IDS_ON_NEW_CLEARER":{
                const { contractIDList, clearerSc } = action.payload;
                let initial = {clearerSc: clearerSc, data: []};
                contractIDList.forEach(e => {
                    initial.data.push({
                        exchangeSc: e.exchangeSc,
                        contractID: e.contractID,
                        currencySc: e.currencySc,
                        amount: 0,
                        options: initialOptions,
                    })
                });
                draft.push(initial);
                return draft;
            }
            case "CONTRACT_ID_SET_VAR":{
                const { clearerSc, exchangeSc, currencySc, contractID, varValue } = action.payload;
                const clearerIndex = draft.findIndex(e => e.clearerSc === clearerSc);
                const dataIndex = draft[clearerIndex].data.findIndex(e => e.exchangeSc === exchangeSc && e.currencySc === currencySc && e.contractID === contractID);
                draft[clearerIndex].data[dataIndex].varValue = varValue;
                return draft;
            }
            default:
                return draft;
        }
    })
