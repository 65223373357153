import styled from 'styled-components';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FilterRoot = styled(Row)`
    height: 35px;
    background-color: var(--bgLight);
`;

export const FilterCell = styled(Row)`
    height: 100%;
    justify-content: center;
    width: ${props => props.width ? props.width : "auto"};
    ${props => props.width ?? "flex: 1;"}
`;