import produce from 'immer';

const initial = {
    loading: false,
    finished: false,
}

export default (state = initial, action) => 
    produce(state, draft => {
        switch(action.type) {
            case "PAST_EVENTS_CALLED":
                draft.loading = true;
                return draft;
            case "PAST_EVENTS_LOADED":
                draft.finished = true;
                draft.loading = false;
                return draft;
            default:
                return draft;
        }
    })
