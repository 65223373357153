import produce from 'immer';

const initialState = {
    list: [],
    balance: null,
    selected: null,
    ccpWallet: null,
    ccpName: null,
};

export default (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case "SET_ACCOUNTS":
                draft.list = action.payload;
                return draft;
            case "SELECT_ACCOUNT":
                draft.selected = action.payload;
                return draft;
            case "SET_BALANCE":
                draft.balance = action.payload;
                return draft;
            case "SET_CCP_WALLET":
                draft.ccpWallet = action.payload;
                return draft;
            case "SET_CCP_NAME":
                draft.ccpName = action.payload;
                return draft;
            default:
                return draft;
        }
    })
