import { useState } from 'react';

const useArray = (initial) => {
    const [array, setArray] = useState(initial);

    const addElement = (element) => {
        setArray([...array, element])
    }

    const removeElement = (element) => {
        setArray(array.filter(e => e !== element));
    }

    return [
        array, addElement, removeElement
    ]
}

export { useArray };