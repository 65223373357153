import styled from 'styled-components';

export const HomeContentRoot = styled.div`
    height: calc(100% - 45px);
    background-color: var(--bgStrong);
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const DataRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    color: var(--textSecondary);
    font-size: 14px;
    padding: 0px 5px 0px 5px;
    &:hover {
        cursor: pointer;
        background-color: var(--bgLight);
        color: var(--textPrimary);
    }
    margin-top: ${props => props.marginTop ? props.marginTop : "0px"};
`;

export const Label = styled.div`
    flex: 1;
    opacity: 0.75;
    margin-right: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Data = styled.div`
    flex: 3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items:center;
`;

export const Button = styled.div`
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        color: var(--contrast);
        cursor: ${props => props.inactive ? "not-allowed" : "pointer"};
    }
    opacity: ${props => props.inactive ? "0.5" : "1"};
    margin-left: 5px;
    user-select: none;
`;

export const DataPart = styled.div`
    margin-left: 5px;
`;

export const Settlement = styled.div`
    background-color: #a52a2a;
`;

export const Red = styled.div`
    background-color: #a52a2a19;
`;