import { useState, useEffect } from 'react';

const useWidthOverflown = (ref) => {
    const { current } = ref;
    const [overflown, setOverflown] = useState(false);

    useEffect(() => {
        if(current === undefined) return;
        const resizeObserver = new ResizeObserver((entries) => {
            const { clientWidth, scrollWidth } = entries[0].target;
            if(clientWidth < scrollWidth)
                setOverflown(true)
            else
                setOverflown(false)
        })
        resizeObserver.observe(current);
        return () => {
            resizeObserver.disconnect();
        }
    }, [current])
       
    return overflown;
}

export { useWidthOverflown };