import Abi from './Abi';
import Accounts from './Accounts';
import Network from './Network';
import GasPrice from './GasPrice';
import Privileges from './Privileges';
import Callbacks from './Callbacks';
import PastEventsStatus from './PastEventsStatus';

export default {
    abi: Abi,
    accounts: Accounts,
    network: Network,
    gasPrice: GasPrice,
    privileges: Privileges,
    callbacks: Callbacks,
    pastEventsStatus: PastEventsStatus,
}