import { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Web3Context } from 'context/Web3Context';

const useDefaultInputValues = () => {
    const [cleave, setCleave] = useState(null);
    const [amount, setAmount] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [errorText, setErrorText] = useState("");
    const dispatch = useDispatch();
    const { web3Instance } = useContext(Web3Context);

    return {
        cleave, setCleave, amount, setAmount, disabled, setDisabled, dispatch, web3Instance, errorText, setErrorText,
    }
}

export { useDefaultInputValues };