import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Confirm, Close } from 'components/inputs';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { InputAde } from 'components/core/basic';
import { useValidation } from 'hooks/core';
import { Deploy } from 'redux/reducers/UserActions/actions';
import { Web3Context } from 'context/Web3Context';

const inputStyle = {
    height: "25px",
    width: "100%",
}

const DeployRow = (props) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [symbol, setSymbol] = useState("");
    const { web3Instance } = useContext(Web3Context);
    const [disabled, errorText] = useValidation([
        {
            validation: () => name === "",
            errorText: "Name can't be empty",
        },
        {
            validation: () => symbol === "",
            errorText: "Symbol can't be empty",
        }
    ]);

    const deploy = () => {
        if(disabled) return;
        dispatch(Deploy(
            "Contract",
            [name, symbol],
            web3Instance,
        ));
        props.close();
    }

    return (
        <Wrapper>
            <Inputs>
                <InputItem>
                    <InputAde 
                        style={inputStyle} 
                        placeholder={"Name..."} 
                        value={name} 
                        onChange={evt => setName(evt.target.value)}
                    />
                </InputItem>
                <InputItem>
                    <InputAde 
                        style={inputStyle} 
                        placeholder={"Symbol..."} 
                        value={symbol} 
                        onChange={evt => setSymbol(evt.target.value)}
                    />
                </InputItem>
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Deploy"} onClick={deploy} disabled={disabled}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { DeployRow };