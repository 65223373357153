import styled, { css } from 'styled-components';

const center = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const Nested = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-left: 15px;
`;

export const Options = styled.div`
    background-color: rgba(0, 0, 0, 0.25);
    margin-bottom: 5px;
    font-size: 14px;
    border-radius: 2px;
`;

export const OptionRow = styled.div`
    height: 25px;
    cursor: pointer;
    &:hover {
        color: var(--textPrimary);
        background-color: var(--bgLight);
    }
    box-sizing: border-box;
    ${center}
    ${props => props.head && "background-color: var(--bgLight);"}
`;

export const OptionColumn = styled.div`
    flex: 1;
    padding: 5px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${center}
    ${props => props.head && "font-weight: bold;"}
`;

export const Red = styled.div`
    background-color: #a52a2a4b;
`;

export const OptionEdit = styled.div`
    width: 20px;
    display: flex;
    ${center}
    &:hover {
        color: var(--contrast);
    }
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: row;
`;

export const InputWrapper = styled.div`
    flex: 1;
`;