import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDetailViewValues } from 'hooks/detailView';
import { DetailViewRoot, DetailViewContent, SpinIcon } from 'components/detail/styled';
import { PanelHeader } from 'components/core/basic';
import { DetailViewDataRow, DetailViewExpander } from 'components/detail';
import { AccountDetailViewData } from 'entities/Account'
import { FaUserCheck, FaUserSlash, FaSpinner } from 'react-icons/fa';
import { F } from 'services';
import { isEmpty, isNil } from 'lodash';
import { Web3Context } from 'context/Web3Context';
import { RegisterCompany, DeregisterCompany } from 'entities/Company/transactions';
import { useSingleEntity } from 'hooks/core';

const Spinner = () => (
    <SpinIcon as={FaSpinner}/>
)

const DetailView = () => {
    const { selectedEntity, selectedRowID, data } = useDetailViewValues("companyList");
    const privileges = useSelector(state => state.privileges);
    const thisClearer = useSingleEntity("clearerList", privileges.selected.scAddress);
    const [clearerFilter, setClearerFilter] = useState(() => () => true);
    const accounts = useSelector(state => state.accountList);
    const currencies = useSelector(state => state.currencyList);
    const [ccyBalances, setCcyBalances] = useState({});
    const [loadingRegister, setLoadingRegister] = useState(false);
    const prevRegistered = useRef();
    const { web3Instance } = useContext(Web3Context);
    const dispatch = useDispatch();

    useEffect(() => {
        if(data) {
            let ccyBalances = {};
            accounts.filter(acc => data.accounts.includes(acc.item)).forEach(acc => {
                acc.ccyBalances.forEach(bl => {
                    ccyBalances[bl.currencySc] = ccyBalances[bl.currencySc] ? ccyBalances[bl.currencySc] + bl.amount : bl.amount;
                });
            });
            setCcyBalances(ccyBalances);
        }
    }, [accounts, data])

    useEffect(() => {
        if(data) {
            const registered = data.clearerSc === "" ? false : true;
            if(registered !== prevRegistered.current)
                setLoadingRegister(false);
            prevRegistered.current = registered;
        }
    }, [data])

    useEffect(() => {
        if(isNil(thisClearer)) return;
        const func = () => (e) => {
            return thisClearer?.accounts.includes(e)
        }
        setClearerFilter(func)
    }, [thisClearer])

    const headerButtons = () => {
        if(privileges.selected.name === "Clearer" && selectedRowID && data) {
            const tooltip = (data.clearerSc ? "Deregister" : "Register") + " Company";
            const icon = loadingRegister ? Spinner : (data.clearerSc ? FaUserSlash : FaUserCheck);
            return [{
                icon: icon, onClick: () => handleRegisterClick(), disabled: false, active: false, tooltip: tooltip,
            }]
        }
        return [];
    }

    const handleRegisterClick = () => {
        if(loadingRegister) return;
        const func = data.clearerSc === "" ? RegisterCompany : DeregisterCompany;
        dispatch(func(data.item, web3Instance));
        setLoadingRegister(true);
    }

    if(selectedEntity !== "Company")
        return null;
    return (
        <DetailViewRoot>
            <PanelHeader title={selectedRowID === null ? "No Row Selected" : ""} buttons={headerButtons()}/>
            {data &&
                <DetailViewContent>
                    <DetailViewDataRow label={"Name"}>{data.name}</DetailViewDataRow>
                    <DetailViewDataRow label={"Sc Address"}>{data.item}</DetailViewDataRow>
                    {!isEmpty(ccyBalances) &&
                        <DetailViewExpander label={"Currency Balances"}>
                         {Object.entries(ccyBalances).map(([key, value]) => {
                            const currency = currencies.find(e => e.item === key);
                            return (
                                <DetailViewDataRow key={key} label={`${currency.name}/${currency.symbol}`}>
                                    {F.currency(value, currency.decimals)}
                                </DetailViewDataRow>
                            )
                        })}
                        </DetailViewExpander>
                    }
                    {!isEmpty(ccyBalances) &&
                        <DetailViewExpander label={"Currency Stats"}>
                         {Object.entries(ccyBalances).map(([key, value]) => {
                            const currency = currencies.find(e => e.item === key);
                            const percent = Number(value * 100 / currency.totalSupply).toFixed(2);
                            return (
                                <DetailViewDataRow key={key} label={`${currency.name} % of Total`}>
                                    %{percent}
                                </DetailViewDataRow>
                            )
                        })}
                        </DetailViewExpander>
                    }
                    {data.accounts.filter(clearerFilter).length > 0 &&
                        <DetailViewExpander label={"Deployed Accounts"}>
                            {data.accounts.filter(clearerFilter).map(e => (
                                <DetailViewExpander key={e} reverse label={accounts.find(c => c.item === e).name}>
                                    <AccountDetailViewData data={accounts.find(c => c.item === e)}/>
                                </DetailViewExpander>
                            ))}
                        </DetailViewExpander>
                    }
                </DetailViewContent>
            }
        </DetailViewRoot>
    )
}

export { DetailView };