import React from 'react';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { Deposit } from 'entities/Custodian/transactions';
import Cleave from 'cleave.js/react';
import { useValidation } from 'hooks/core';

const DepositCcpRow = (props) => {
    const { dispatch, web3Instance, cleave, setCleave, amount, setAmount } = useDefaultInputValues();
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount === "",
            errorText: "Can't deposit 0 amount",
        }
    ])

    const deposit = () => {
        if(disabled) return;
        dispatch(Deposit(
            null,
            props.ccy.item,
            cleave.getRawValue() * Math.pow(10, props.ccy.decimals),
            web3Instance,
        ));
        props.close();
    }

    return (
        <Wrapper>
            <Inputs>
                <InputItem width={"100%"}>
                    <Cleave onInit={(cleave) => setCleave(cleave)} 
                        className={"cleaveStyle"} style={{height: "25px", width: "100%"}} placeholder={"Amount..."} onChange={(e) => setAmount(e.target.value)} 
                        options={{numeral: true, numeralDecimalScale: props.ccy.decimals}}
                    />
                </InputItem>
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Deposit"} disabled={disabled} onClick={deposit}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export default DepositCcpRow;