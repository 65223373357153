import { populateNodeAndDispatch, populateNodeProps } from 'services/EventHandlingHelpers';

export const AddCurrency = (evt, web3) => {
    return async (dispatch, getState) => {
        const state = getState();
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setExtraMethods(["totalSupply", "decimals", "symbol", "balanceOf"]).setDisabledMethods(["ccp", "wallet"])
            .setAbiName("Currency").setActionType("ADD_CURRENCY").setAsyncActionType("LOAD_CURRENCY_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);

        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other};
        });
        const currencySc = evt.returnValues._item;
        const contract = new web3.eth.Contract(compatibleAbi, evt.returnValues._item);

        contract.events.allEvents((err, evt) => {
            if(evt.event === "Transfer") handleCurrencyTransfer(evt, dispatch, state, currencySc)
        });
        contract.getPastEvents(
            "AllEvents",
            {
                fromBlock: 0,
                toBlock: "latest",
            },
            (err, events) => {
                events.forEach((evt) => {if(evt.event === "Transfer") handleCurrencyTransfer(evt, dispatch, state, currencySc)});
            }
        );
    }
}

const handleCurrencyTransfer = (evt, dispatch, state, currencySc) => {
    const { from , to, value } = evt.returnValues;
    dispatch({ type: "UPDATE_ADDRESS_BALANCE", payload: {from: from, value: value, to: to, currencySc: currencySc} });
}

export const RemoveCurrency = (evt, web3) => {
    return async (dispatch, getState) => {
        dispatch({ type: "REMOVE_CURRENCY", payload: evt.returnValues._item });
    }
}

export const CurrencyIncreaseSupply = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _newTotal, _custodian, _delta } = evt.returnValues;
        dispatch({ type: "SET_TOTAL_SUPPLY", payload: {currencyAddress: _node, newValue: _newTotal} });
        dispatch({ type: "CUSTODIAN_UPDATE_TOTAL_MINT", payload: {custodianSc: _custodian, delta: _delta, currencySc: _node} });
    }
}

export const CurrencyDecreaseSupply = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _newTotal, _custodian, _delta } = evt.returnValues;
        dispatch({ type: "SET_TOTAL_SUPPLY", payload: {currencyAddress: _node, newValue: _newTotal}});
        dispatch({ type: "CUSTODIAN_UPDATE_TOTAL_BURN", payload: {custodianSc: _custodian, delta: _delta, currencySc: _node} });
    }
}

export const currencyEvents = {
    "CCP_addCurrency": AddCurrency,
    "CCP_removeCurrency": RemoveCurrency,
    "Currency_IncreaseSupply": CurrencyIncreaseSupply,
    "Currency_DecreaseSupply": CurrencyDecreaseSupply,
}