import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { DefaultSelect } from 'components/core/selectors';
import { AccountCreateBalance } from 'entities/Account/transactions';
import { useValidation } from 'hooks/core';

const AccountCreateBalanceRow = (props) => {
    const { accountSc } = props;
    const { dispatch, web3Instance } = useDefaultInputValues();
    const currencies = useSelector(state => state.currencyList);
    const [selectedCcy, setSelectedCcy] = useState({});
    const [filteredCurrencies, setFilteredCurrencies] = useState([]);
    const transferLog = useSelector(state => state.addressCcyBalances);
    const [disabled, errorText] = useValidation([
        {
            validation: () => filteredCurrencies.length === 0,
            errorText: "Every Ccy has Balance",
        }
    ]);
    
    useEffect(() => {
        setFilteredCurrencies(currencies);
    }, [currencies, transferLog, accountSc])
    
    const createBalance = () => {
        if(disabled) return;
        dispatch(AccountCreateBalance(
            props.accountSc,
            selectedCcy.item,
            web3Instance,
        ));
        props.close();
    }
    
    return (
        <Wrapper>
            <Inputs>
                <InputItem width={"100%"}>
                    <DefaultSelect
                        label={"Currency"}
                        onChange={selected => setSelectedCcy(selected)}
                        formatValue={(e) => `${e.name}/${e.symbol}`}
                        list={filteredCurrencies}
                    />
                </InputItem> 
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Create Balance"} disabled={disabled} onClick={createBalance}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export default AccountCreateBalanceRow;