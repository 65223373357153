import { toast } from 'react-toastify';
const EthereumTx = require('ethereumjs-tx').Transaction;

export default class TransactionService {
    static async executeTransaction(method, to, web3, state, msg){
        try {
            if(to)
                await method().call({ from: state.accounts.selected.address });
        }
        catch(err) {
            toast.error(`Transaction ${msg} failed`);
            console.log(err);
            return;
        }
        const txHash = method().encodeABI();
        web3.eth.getTransactionCount(state.accounts.selected.address).then(r => {
            const accountNonceHex = "0x" + r.toString(16);
            const gasPriceHex = web3.utils.toHex(parseInt(state.gasPrice / 100000000) + "");
            let rawTx = {
                nonce: accountNonceHex,
                gasPrice: gasPriceHex,
                gasLimit: "0x7a1200",
                data: txHash,
            }
            if(to) {
                rawTx.to = to;
                rawTx.value = "0x00";
            }
            const privateKey = Buffer.from(state.privateKey, "hex");
            const tx = new EthereumTx(rawTx);
            
            tx.sign(privateKey);
            const serializedTx = tx.serialize();
            web3.eth.sendSignedTransaction("0x" + serializedTx.toString("hex"))
                .then(r => msg && toast.success(`Transaction ${msg} succedeed`)).catch(err => console.log(err))
        });
    }
}