import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Confirm, Close } from 'components/inputs';
import { InputItem2x0, Wrapper } from 'components/inputs/styled';
import { InputAde } from 'components/core/basic';
import { useValidation } from 'hooks/core';
import { CompanySelect } from 'components/selectors';
import { Deploy } from 'redux/reducers/UserActions/actions';
import { Web3Context } from 'context/Web3Context';

const inputStyle = {
    height: "25px",
    width: "100%",
}

const DeployRow = (props) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [selectedCompany, setSelectedCompany] = useState(null);
    const { web3Instance } = useContext(Web3Context);
    const [disabled, errorText] = useValidation([
        {
            validation: () => name === "",
            errorText: "Name can't be empty",
        }
    ]);

    const deploy = () => {
        if(disabled) return;
        dispatch(Deploy(
            "Account",
            [name, selectedCompany.item],
            web3Instance,
        ));
        props.close();
    }

    return (
        <Wrapper>
            <InputItem2x0>
                <InputAde 
                    style={inputStyle} 
                    placeholder={"Name..."} 
                    value={name} 
                    onChange={evt => setName(evt.target.value)}
                />
            </InputItem2x0>
            <InputItem2x0>
                <CompanySelect
                    onChange={s => setSelectedCompany(s)}
                />
            </InputItem2x0>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Deploy"} onClick={deploy} disabled={disabled}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { DeployRow };