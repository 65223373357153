import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { DataRow, Label, Data, Button } from 'components/home/basic/styled';
import { Spinner, Tooltip } from 'components/core/basic';
import { F } from 'services';
import { useSingleEntity } from 'hooks/core';
import { FaLongArrowAltDown, FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { DepositCcpRow } from 'entities/Custodian/inputs';

const Loading = () => <Spinner height={10} width={10} borderSize={5}/>

const SupplyRow = (props) => {
    const currency = props.currency;
    const [showDeposit, setShowDeposit] = useState(false);
    const [showClearers, setShowClearers] = useState(false);
    const clearers = useSelector(state => state.clearerList);
    const thisCustodian = useSingleEntity("custodianList", props.custodianSc);
    const transferLog = useSelector(state => state.addressCcyBalances);

    return (
        <React.Fragment>
            <DataRow>
                <Label>
                    <React.Fragment>{currency.symbol ? `${currency.symbol} Total Supply` : <Loading/>}</React.Fragment>
                </Label>
                <Data>{F.currency(currency.totalSupply, currency.decimals)}</Data>
                <Tooltip content={"Deposit CCP"}>
                    <Button onClick={() => setShowDeposit(true)}><FaLongArrowAltDown/></Button>
                </Tooltip>
                <Tooltip content={"Toggle Clearer Balances"}>
                    <Button inactive={thisCustodian?.clearers.length < 1}>
                        {showClearers ? <FaAngleUp onClick={() => setShowClearers(false)}/> : <FaAngleDown onClick={() => setShowClearers(true)}/>}
                    </Button>
                </Tooltip>
            </DataRow>
            {showClearers &&
                thisCustodian?.clearers.map(e => {
                    const clearer = clearers.find(x => x.item === e);
                    const addressData = transferLog.find(x => x.address === clearer.wallet);
                    const balance = addressData?.balances.find(x => x.currencySc === currency.item);
                    const percentage = (balance?.value && currency.totalSupply) && Number(balance?.value * 100 / currency.totalSupply).toFixed(2);
                    return (
                        <DataRow key={e}>
                            <Label>
                                <React.Fragment>{clearer.name ?? <Loading/>}</React.Fragment>
                            </Label>
                            <Data>{F.currency(balance?.value, currency.decimals)}{percentage && `(${percentage}% of Total)`}</Data>
                            <Button></Button>
                            <Button></Button>
                        </DataRow>
                    )
            })}
            {showDeposit && <DepositCcpRow ccy={currency} close={() => setShowDeposit(false)}/>}
        </React.Fragment>
    )
}

export { SupplyRow };