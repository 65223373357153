import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ADD_CLEARER":
                draft.push({...action.payload, registered: false, custodians: [], exchanges: [], accounts: [], ccyBalances: []});
                return draft;
            case "LOAD_CLEARER_ASYNC_VALUES":
                const updateIndex = draft.findIndex(e => e.item === action.payload.item);
                draft[updateIndex] = {...draft[updateIndex], ...action.payload};
                return draft;
            case "REMOVE_CLEARER":
                const removeIndex = draft.findIndex(e => e.item === action.payload);
                draft.splice(removeIndex, 1);
                return draft;
            case "CUSTODIAN_REGISTER_CLEARER":
                const registerIndex = draft.findIndex(e => e.item === action.payload.clearerSc);
                draft[registerIndex].registered = true;
                draft[registerIndex].custodians.push(action.payload.custodianSc)
                return draft;
            case "CUSTODIAN_DEREGISTER_CLEARER":
                const deregisterIndex = draft.findIndex(e => e.item === action.payload.clearerSc);
                draft[deregisterIndex].registered = false;
                const custodianToRemoveIndex = draft[deregisterIndex].custodians.findIndex(e => e === action.payload.custodianSc);
                draft[deregisterIndex].custodians.splice(custodianToRemoveIndex, 1);
                return draft;
            case "EXCHANGE_REGISTER_CLEARER":
                {
                    const registerIndex = draft.findIndex(e => e.item === action.payload.clearerSc);
                    draft[registerIndex].registered = true;
                    draft[registerIndex].exchanges.push(action.payload.exchangeSc)
                }
                return draft;
            case "EXCHANGE_DEREGISTER_CLEARER":
                {
                    const deregisterIndex = draft.findIndex(e => e.item === action.payload.clearerSc);
                    draft[deregisterIndex].registered = false;
                    const exchangeToRemoveIndex = draft[deregisterIndex].exchanges.findIndex(e => e === action.payload.exchangeSc);
                    draft[deregisterIndex].exchanges.splice(exchangeToRemoveIndex, 1);
                }
                return draft;
            case "ADD_ACCOUNT_TO_CLEARER_LIST":
                {
                    const { clearerSc, accountSc } = action.payload;
                    const clearerIndex = draft.findIndex(e => e.item === clearerSc);
                    draft[clearerIndex].accounts.push(accountSc);
                }
                return draft;
            default:
                return draft;
        }
    })
