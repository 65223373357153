import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { SetSelectedEntity } from 'redux/reducers/Entities/actions';
import { FaExchangeAlt, FaBuilding, FaLandmark, FaChessRook, FaDollarSign, FaUserTag, FaHome, FaSignOutAlt, FaCommentsDollar,
    FaFileContract, FaCalendarAlt } from 'react-icons/fa';
import { Bar, Selector, Left, Right } from './styled';
import { Web3Context } from 'context/Web3Context';
import { ToggleHomeVisibility } from 'redux/reducers/Main/actions';
import { DefaultSelect } from 'components/core/selectors';
import { Tooltip } from 'components/core/basic';

const icons = { "Custodian": FaChessRook, "Exchange": FaExchangeAlt, "Clearer": FaLandmark, "Company": FaBuilding,
    "Account": FaUserTag, "Currency": FaDollarSign, "Settlement": FaCommentsDollar, "Contract": FaFileContract, "Market": FaCalendarAlt, 
}

const tooltipText = (key) => {
    const keys = {"Clearer" : "Clearing Member", "CCP": "ClearChain"};
    return (keys[key] ?? key);
}

const TopBar = () => {
    const { web3Instance } = useContext(Web3Context);
    const { selectedEntity, privileges, isHomeVisible, accounts } = useSelector(state => ({
        selectedEntity: state.selectedEntity,
        privileges: state.privileges,
        isHomeVisible: state.isHomeVisible,
        accounts: state.accounts,
    }), shallowEqual);
    const dispatch = useDispatch();
    const [privilegeList, setPrivilegeList] = useState([]);

    useEffect(() => {
        let list = [];
        Object.entries(privileges.data).forEach(([key, value]) => {
            if(value.hasPrivilege) {
                list.push({
                    name: key,
                    hasPrivilege: true,
                    scAddress: value.scAddress,
                    entities: value.entities,
                })
            }
        });
        setPrivilegeList(list);
    }, [privileges])

    const logout = () => {
        web3Instance.currentProvider.connection.close();
        window.location.reload();
    }

    return(
        <Bar>
            <Left>
                {privileges.selected.entities.map(e => (
                    <Tooltip key={e} content={tooltipText(e)}>
                        <div style={{marginRight: "10px"}}>
                            <Selector onClick={() => dispatch(SetSelectedEntity(e))} as={icons[e]} selected={e === selectedEntity}/>
                        </div>
                    </Tooltip>
                ))}
            </Left>
            <Right>
                <div style={{marginRight: "20px"}}>
                    {`Name: ${accounts.ccpName}`}
                </div>
                <div style={{marginRight: "20px", height: "35px", width: "200px"}}>
                    <DefaultSelect
                        label={"Privilege"}
                        onChange={() => {}}
                        formatValue={e => tooltipText(e.name)}
                        list={privilegeList}
                        disabled={privilegeList.length < 2}
                    />
                </div>
                <Tooltip content={"Toggle Home"}>
                    <div style={{marginRight: "10px"}}>
                        <Selector as={FaHome} selected={isHomeVisible} onClick={() => dispatch(ToggleHomeVisibility())}/>
                    </div>
                </Tooltip>
                <Tooltip content={"Log Out"}>
                    <div>
                        <Selector as={FaSignOutAlt} onClick={logout}/>
                    </div>
                </Tooltip>
            </Right>
        </Bar>
    )
}

export default TopBar;