const ctrMethods = ["name", "owner", "ccp", "id", "wallet", "isOwner"];

export const populateNodeProps = function() {
    const props = ["evt", "web3", "dispatch", "getState", "node", "extraMethods", "disabledMethods", "abiName", "actionType", "asyncActionType"];
    return {
        setEvent: function(evt) {
            this.evt = evt;
            return this;
        },
        setWeb3: function(web3) {
            this.web3 = web3;
            return this;
        },
        setDispatch: function(dispatch) {
            this.dispatch = dispatch;
            return this;
        },
        setGetState: function(getState) {
            this.getState = getState;
            return this;
        },
        setNode: function(node) {
            this.node = node;
            return this;
        },
        extraMethods: [],
        setExtraMethods: function(extraMethods) {
            this.extraMethods = extraMethods;
            return this;
        },
        disabledMethods: [],
        setDisabledMethods: function(disabledMethods) {
            this.disabledMethods = disabledMethods;
            return this;
        },
        setAbiName: function(abiName) {
            this.abiName = abiName;
            return this;
        },
        setActionType: function(actionType) {
            this.actionType = actionType;
            return this;
        },
        setAsyncActionType: function(asyncActionType) {
            this.asyncActionType = asyncActionType;
            return this;
        },
        build: function() {
            let result = {};
            props.forEach(e => result[e] = this[e]);
            return result;
        }
    }
}

const formatAbi = (abi) => {
    return abi.map(e => {
        const {constant, ...other} = e;
        return {...other};
    });
}

const extractEvtProps = (node, evt) => {
    node.creatorAddress = evt.address;
    node.blockNumber = evt.blockNumber;
    node.item = evt.returnValues._item;
    node.wallet = evt.returnValues._wallet;
}

const loadTimestamp = (node, web3) => {
    return web3.eth.getBlock(node.blockNumber).then(r => {
        node.timestamp = r.timestamp;
    });
}

export const populateNodeAndDispatch = async ({ evt, web3, dispatch, getState, node, extraMethods, disabledMethods, abiName, actionType, asyncActionType }) => {
    const state = getState();
    extractEvtProps(node, evt, web3);
    
    dispatch({ type: actionType, payload: {...node} });

    const contractAddress = node.item;
    const contract = new web3.eth.Contract(formatAbi(state.abi.data[abiName].abi), contractAddress);
    let promises = extraMethods.concat(ctrMethods).filter(e => !disabledMethods.includes(e)).map(e => {
        if(e === "balanceOf")
            return contract.methods[e](state.accounts.selected.address).call().then(r => {node[e] = parseInt(r)})
        if(e !== "isOwner")
            return contract.methods[e]().call().then(r => {node[e] = r})
        return contract.methods[e]().call({ from: state.accounts.selected.address }).then(r => {node[e] = r})
    });
    const timestampPromise = loadTimestamp(node, web3);
    Promise.all(promises.concat([timestampPromise])).then(r => dispatch({ type: asyncActionType, payload: {...node} }));
}

export const abiParser = (state, type) => {
    const compatibleAbi = state.abi.data[type].abi.map(e => {
        const {constant, ...other} = e;
        return {...other}
    });
    return compatibleAbi;
}