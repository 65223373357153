import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataRow, Label, Data, Button } from 'components/home/basic/styled';
import { useSingleEntity } from 'hooks/core';
import { FaAngleUp, FaAngleDown, FaTimes, FaCheck, FaLongArrowAltRight } from 'react-icons/fa';
import { Spinner } from 'components/core/basic';
import { isNil } from 'lodash';
import { F } from 'services';
import { CustodianAcceptSettlement, CustodianRejectSettlement } from 'entities/Custodian/transactions';
import { Web3Context } from 'context/Web3Context';

const Loading = () => <Spinner height={10} width={10} borderSize={5}/>

const SettlementRow = (props) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const thisCustodian = useSingleEntity("custodianList", props.custodianSc);
    const clearers = useSelector(state => state.clearerList);
    const accounts = useSelector(state => state.accountList);
    const currencies = useSelector(state => state.currencyList);
    const [settlements, setSettlements] = useState([]);
    const { web3Instance } = useContext(Web3Context);

    useEffect(() => {
        if(isNil(thisCustodian)) return;
        let settlements = [];
        thisCustodian.clearers.forEach(e => {
            const clearer = clearers.find(x => x.item === e);
            clearer.accounts.forEach(a => {
                const account = accounts.find(x => x.item === a);
                settlements.push(...account.settlements.filter(x => x.processed === false && x.to && x.rejected === false).map(x => ({...x, sender: a})));
            });
        });
        setSettlements(settlements);
    }, [thisCustodian, clearers, accounts]);

    const accept = (settlement) => {
        dispatch(CustodianAcceptSettlement(
            settlement.settlementID,
            settlement.exchangeSc,
            settlement.currencySc,
            settlement.sender,
            settlement.to,
            settlement.amount,
            props.custodianSc,
            web3Instance,
        ));
    }

    const reject = (settlement) => {
        dispatch(CustodianRejectSettlement(
            settlement.settlementID,
            settlement.exchangeSc,
            settlement.currencySc,
            settlement.sender,
            settlement.to,
            settlement.amount,
            props.custodianSc,
            web3Instance,
        ))
    }
    
    return (
        <React.Fragment>
            <DataRow>
                <Label>Pending Settlements</Label>
                <Button inactive={settlements.length < 1}>
                    {expanded ? <FaAngleUp onClick={() => setExpanded(false)}/> : <FaAngleDown onClick={() => setExpanded(true)}/>}
                </Button>
            </DataRow>
            {expanded &&
                settlements.map(e => {
                    const receiver = accounts.find(x => x.item === e.to);
                    const sender = accounts.find(x => x.item === e.sender);
                    const currency = currencies.find(x => x.item === e.currencySc);
                    return (
                        <DataRow key={e.settlementID}>
                            <Data>
                                {sender?.name ?? <Loading/>}&nbsp;
                                <FaLongArrowAltRight/>&nbsp;
                                {receiver?.name ?? <Loading/>}&nbsp;
                                {currency.symbol ?? <Loading/>}&nbsp;
                                {F.currency(e.amount, currency?.decimals)}
                            </Data>
                            <Button onClick={() => accept(e)}><FaCheck/></Button>
                            <Button onClick={() => reject(e)}><FaTimes/></Button>
                        </DataRow>
                    )
            })}
        </React.Fragment>
    )
}

export { SettlementRow };