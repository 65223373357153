import { useState, useEffect } from 'react';

const useValidation = (props) => {
    const [disabled, setDisabled] = useState(false);
    const [errorText, setErrorText] = useState(null);

    useEffect(() => {
        const disabled = props.some(p => {
            if(p.validation()) {
                setDisabled(true);
                setErrorText(p.errorText);
                return true;
            }
            return false;
        })
        if(disabled === false) {
            setDisabled(false);
            setErrorText(null);
        }
    }, [props])

    return [disabled, errorText];
}

export { useValidation };