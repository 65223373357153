import { TransactionService } from 'services';
import { abiParser } from 'services/EventHandlingHelpers';

export const WithdrawFromDefaultFund = (amount, ccyAddress, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "CCP"), state.ccpAddress);
        const method = () => contract.methods.withdrawFromDefaultFund(amount, ccyAddress);
        TransactionService.executeTransaction(method, state.ccpAddress, web3, state, "withdrawFromDefaultFund");
    }
}

export const DepositFromDefaultFund = (ccyAddress, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Currency"), ccyAddress);
        const method = () => contract.methods.depositDefaultFund(amount);
        TransactionService.executeTransaction(method, ccyAddress, web3, state, "depositDefaultFund");
    }
}