import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HomeRoot, HomeRow, HomeDataRowWrapper } from 'components/home/styled';
import { PanelHeader, Tooltip } from 'components/core/basic';
import { HomeContent, HomeDataRow, HomeCcyLabel, SettlementRow } from 'components/home';
import { FaLongArrowAltDown, FaLongArrowAltUp, FaPlus} from 'react-icons/fa';
import { DepositBufferFundRow, WithdrawFromBufferFundRow } from 'entities/CCP/inputs';
import { useArray, useAddressBalance } from 'hooks/core';
import { DeployRow } from 'components/list';
import { DetailViewExpander } from 'components/detail';
import { F } from 'services';

const Home = () => {
    const ccpAddress = useSelector(state => state.ccpAddress);
    const accountData = useSelector(state => state.accounts);
    const privileges = useSelector(state => state.privileges);
    const currencies = useSelector(state => state.currencyList);
    const accounts = useSelector(state => state.accountList);
    const [unsatisfiedSettlements, setUnsatisfiedSettlements] = useState([]);
    const [depositRows, addDepositRow, removeDepositRow] = useArray([]);
    const [withdrawRows, addWithdrawRow, removeWithdrawRow] = useArray([]);
    const ccpSupply = useAddressBalance(accountData.selected.address);
    const ccpDfc = useAddressBalance(ccpAddress);
    const [showDeploy, setShowDeploy] = useState(false);

    useEffect(() => {
        let settlements = [];
        accounts.forEach(e => {
            e.settlements.forEach(s => {
                if(s.canBeSatisfied === false && s.to) settlements.push({...s, sender: e.item})
            });
        });
        setUnsatisfiedSettlements(settlements);
    }, [accounts])

    const depositButtons = (ccy) => {
        return [{
            icon: <Tooltip content={"Deposit Buffer Fund"}><span><FaLongArrowAltDown/></span></Tooltip>,
            onClick: () => addDepositRow(ccy.item),
        }]
    }

    const withdrawButtons = (ccy) => {
        return [{
            icon: <Tooltip content={"Withdraw from Buffer Fund"}><span><FaLongArrowAltUp/></span></Tooltip>,
            onClick: () => addWithdrawRow(ccy.item),
        }]
    }

    const headerButtons = () => {
        return [{
            icon: FaPlus, onClick: () => setShowDeploy(true), disabled: showDeploy, active: showDeploy, tooltip: "Deploy CCP",
        }];
    }

    if(privileges.selected.name !== "CCP")
        return null;
    return (
        <HomeRoot>
            <PanelHeader title={"Home"} buttons={headerButtons()}/>
            <HomeContent innerStyle={{display: "flex", flexDirection: "column"}}>
                {showDeploy &&
                    <DeployRow type={"CCP"} close={() => setShowDeploy(false)}/>
                }
                {unsatisfiedSettlements.length > 0 &&
                    <DetailViewExpander label={"Unsatisfied Settlements"}>
                        {unsatisfiedSettlements.map(e => {
                            return <HomeDataRowWrapper noMarginRight><SettlementRow data={e}/></HomeDataRowWrapper>
                        })}
                    </DetailViewExpander>
                }
                {currencies.map(e => {
                    const supply = ccpSupply?.balances?.find(x => x.currencySc === e.item)?.value ?? 0;
                    const dfc = ccpDfc?.balances?.find(x => x.currencySc === e.item)?.value ?? 0;
                    return (
                        <HomeRow key={e.item}>
                            <HomeDataRowWrapper width={"20%"}>
                                <HomeCcyLabel name={e.name} symbol={e.symbol}/>
                            </HomeDataRowWrapper>
                            <HomeDataRowWrapper width={"40%"}>
                                <HomeDataRow formatValue={(v) => F.currency(v, e.decimals)} label={"Balance:"} buttons={depositButtons(e)}>
                                    {supply}
                                </HomeDataRow>
                                {depositRows.find(row => row === e.item) && 
                                    <DepositBufferFundRow supply={supply} ccy={e} close={() => removeDepositRow(e.item)}/>
                                }
                            </HomeDataRowWrapper>
                            <HomeDataRowWrapper width={"40%"}>
                                <HomeDataRow formatValue={(v) => F.currency(v, e.decimals)} label={"BFC:"} buttons={withdrawButtons(e)}>
                                    {dfc}
                                </HomeDataRow>
                                {withdrawRows.find(row => row === e.item) && 
                                    <WithdrawFromBufferFundRow dfc={dfc} ccy={e} close={() => removeWithdrawRow(e.item)}/>
                                }
                            </HomeDataRowWrapper>
                        </HomeRow>
                        )
                })}
            </HomeContent>
        </HomeRoot>
    );
}

export { Home };