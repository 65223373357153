import styled, { keyframes, css } from 'styled-components';

const nested = css`
    align-self: flex-end;
    width: calc(100% - ${props => props.depth !== undefined ? `${(props.depth * 15)}px` : "15px"});
`;

const lineText = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const blink = keyframes`
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
`;

export const Wrapper = styled.div`
    height: 25px;
    width: 100%;
    border-radius: 2px;
    background-color: ${props => props.bgColor ? props.bgColor : "rgba(0, 0, 0, 0.25)"};
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: ${props => props.noMargin ? "0px" : "5px"};
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        ${props => props.disableHover ? "" : 
            "background-color: var(--bgLight);" +
            "color: var(--textPrimary);" +
            "cursor: pointer;"
        }
    }
`;

export const Label = styled.div`
    opacity: 0.75;
    flex: 1;
    margin-right: 5px;
    ${lineText}
`;

export const Data = styled.div`
    flex: 3;
    ${lineText}
    display: flex;
    flex-direction: row;
    align-items: center;
    ${props => props.bold && "font-weight: bold;"}
`;

export const Copy = styled.div`
    width: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &:hover {
        cursor: pointer;
        color: var(--contrast);
    }
`;

export const Blink = styled.div`
    animation: ${blink} 0.5s linear infinite;
`;

export const TitleRow = styled.div`
    background-color: #324ea84b;
`;

export const Nested = styled.div`
    ${nested}
`;

export const DenseRow = styled(Wrapper)`
    ${nested}
    margin-bottom: ${props => props.last ? "5px" : "0px"};
`;

export const ImportantText = styled.span`
    color: var(--textPrimary);
    font-weight: bold;
`

