import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { WithdrawAccount } from 'entities/Account/transactions';
import Cleave from 'cleave.js/react';
import { DefaultSelect } from 'components/core/selectors';
import { useValidation, useAddressBalance } from 'hooks/core';

const AccountWithdrawRow = (props) => {
    const { accountSc } = props;
    const { dispatch, web3Instance, cleave, setCleave, amount, setAmount } = useDefaultInputValues();
    const currencies = useSelector(state => state.currencyList);
    const [selectedCcy, setSelectedCcy] = useState({});
    const thisBalance = useAddressBalance(accountSc);
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount === "",
            errorText: "Can't withdraw 0 amount",
        },
        {
            validation: () => (thisBalance?.balances.find(e => e.currencySc === selectedCcy.item)?.value ?? -1) < cleave.getRawValue() * Math.pow(10, selectedCcy.decimals),
            errorText: "Can't withdraw more than in Balance",
        }
    ]);

    const withdraw = () => {
        if(disabled) return;
        dispatch(WithdrawAccount(
            selectedCcy.item,
            accountSc,
            cleave.getRawValue() * Math.pow(10, selectedCcy.decimals),
            web3Instance,
        ));
        props.close()
    }

    return (
        <Wrapper>
            <Inputs>
                <InputItem width={"50%"}>
                    <Cleave onInit={cleave => setCleave(cleave)}
                        className={"cleaveStyle"} style={{height: "25px", width: "100%"}} placeholder={"Amount..."} onChange={e => setAmount(e.target.value)} 
                        options={{numeral: true, numeralDecimalScale: selectedCcy.decimals}}
                    />
                </InputItem>
                <InputItem width={"50%"}>
                    <DefaultSelect
                        label={"Currency"}
                        onChange={selected => setSelectedCcy(selected)}
                        formatValue={(e) => `${e.name}/${e.symbol}`}
                        list={currencies}
                    />
                </InputItem>
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Withdrawal"} disabled={disabled} onClick={withdraw}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export default AccountWithdrawRow;