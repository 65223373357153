import React from 'react';
import { ListViewRoot, ListContent, ListHeaderRow } from 'components/list/styled';
import { ListColumn, ListWrapper, RowRenderer, useListViewValues, DeployRow } from 'components/list';
import { PanelHeader } from 'components/core/basic';
import { FaPlus } from 'react-icons/fa';

const ListView = () => {
    const { selectedEntity, list, privileges, showDeploy, setShowDeploy } = useListViewValues("currencyList");

    const rowRenderer = ({ key, index, style }) => (
        <RowRenderer key={key} index={index} style={style} item={list[index].item}>
            <ListColumn>{list[index].name}</ListColumn>
            <ListColumn timestamp>{list[index].timestamp}</ListColumn>
            <ListColumn flex={2}>{list[index].item}</ListColumn>
        </RowRenderer>
    )

    const headerButtons = () => {
        if(privileges.selected.name === "CCP")
            return [
                {icon: FaPlus, onClick: () => setShowDeploy(true), disabled: showDeploy, active: showDeploy, tooltip: "Deploy Currency"},
            ]
    }

    if(selectedEntity !== "Currency")
        return null;
    return (
        <ListViewRoot>
            <PanelHeader title={"Currencies"} buttons={headerButtons()}/>
            {showDeploy &&
                <DeployRow close={() => setShowDeploy(false)} type={"Currency"}/>
            }
            {list.length > 0 && (
                <ListHeaderRow>
                    <ListColumn head>Name</ListColumn>
                    <ListColumn head>Timestamp</ListColumn>
                    <ListColumn head flex={2}>SC Address</ListColumn>
                </ListHeaderRow>
            )}
            <ListContent headerVisible={list.length > 0}>
                <ListWrapper length={list.length} rowRenderer={rowRenderer}/>
            </ListContent>
        </ListViewRoot>
    )
}

export { ListView };