import { useEffect } from 'react';

const BodyColor = (props) => {
    useEffect(() => {
        document.body.style = `background-color: var(${props.color});`;
    }, [props.color]);

    return props.children;
}

export default BodyColor;