import React from 'react';
import { Wrapper, Data, ImportantText } from 'components/detail/basic/styled';
import { useSingleEntity } from 'hooks/core';
import { Spinner } from 'components/core/basic';
import { F } from 'services';

const MarginRow = (props) => {
    const { data } = props;
    const thisExchange = useSingleEntity("exchangeList", data.exchange);

    return (
        <Wrapper>
            <Data>
                {thisExchange ? thisExchange.name : "ClearChain"}&nbsp;
                set&nbsp;<ImportantText>{data.margin}%</ImportantText>&nbsp;margin&nbsp;
                at&nbsp;
                <React.Fragment>
                    {data.timestamp ? F.timestamp(data.timestamp) : <Spinner height={10} width={10} borderSize={5}/>}
                </React.Fragment>
            </Data>
        </Wrapper>
    )
}

export { MarginRow };