import React from 'react';
import { DataRow, Label, Data, Button } from '../styled';
import { Spinner } from 'components/core/basic';
import { isNil } from 'lodash';

const HomeDataRow = (props) => {
    const formatValue = () => {
        if(!isNil(props.children) && props.formatValue)
            return props.formatValue(props.children)
        if(!isNil(props.children))
            return props.children;
        return null;
    }
    
    return (
        <DataRow marginTop={props.marginTop}>
            <Label>{isNil(props.label) ? <Spinner height={10} width={10} borderSize={5}/> : props.label}</Label>
            <Data>{formatValue()}</Data>
            {Array.isArray(props.buttons) &&
                <React.Fragment>
                    {props.buttons.map((e, idx) => {
                        return <Button key={idx} onClick={e.onClick}>{e.icon}</Button>
                    })}
                </React.Fragment>
            }
        </DataRow>
    )
}

export default HomeDataRow;