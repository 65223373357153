import styled from 'styled-components';

export const Wrapper = styled.div`
    border: 2px dashed ${props => props.draggedOver ? "var(--contrast2)" : "var(--textSecondary)"};
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:hover {
        color: var(--contrast);
        border-color: var(--contrast);
    }
    position: relative;
`;

export const Icon = styled.div`
    font-size: 24px;
`;

export const InfoText = styled.div`
    margin-top: 5px;
`;

export const Input = styled.input`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
`;