import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ADD_EXCHANGE":
                draft.push({...action.payload, clearers: [], accounts: [], contractIDList: []});
                return draft;
            case "LOAD_EXCHANGE_ASYNC_VALUES":
                const updateIndex = draft.findIndex(e => e.item === action.payload.item);
                draft[updateIndex] = {...draft[updateIndex], ...action.payload};
                return draft;
            case "REMOVE_EXCHANGE":
                const removeIndex = draft.findIndex(e => e.item === action.payload);
                draft.splice(removeIndex, 1);
                return draft;
            case "ADD_CLEARER_TO_EXCHANGE_LIST":
            {
                const { clearerSc, exchangeSc } = action.payload;
                const addIndex = draft.findIndex(e => e.item === exchangeSc);
                draft[addIndex].clearers.push(clearerSc);
            }
                return draft;
            case "REMOVE_CLEARER_FROM_EXCHANGE_LIST":
            {
                const { clearerSc, exchangeSc } = action.payload;
                const removeIndex = draft.findIndex(e => e.item === exchangeSc);
                draft[removeIndex].clearers.splice(draft[removeIndex].clearers.findIndex(e => e === clearerSc), 1);
            }
                return draft;
            case "ADD_ACCOUNT_TO_EXCHANGE_LIST":
            {
                const { accountSc, exchangeSc } = action.payload;
                const addIndex = draft.findIndex(e => e.item === exchangeSc);
                draft[addIndex].accounts.push(accountSc);
            }
                return draft;
            case "REMOVE_ACCOUNT_FROM_EXCHANGE_LIST":
            {
                const { accountSc, exchangeSc } = action.payload;
                const removeIndex = draft.findIndex(e => e.item === exchangeSc);
                draft[removeIndex].accounts.splice(draft[removeIndex].accounts.findIndex(e => e === accountSc), 1);
            }
                return draft;
            case "EXCHANGE_SET_DFC":
                const { currencySc, exchangeSc, amount, contractID } = action.payload;
                const exchangeIndex = draft.findIndex(e => e.item === exchangeSc);
                const contractIDIndex = draft[exchangeIndex].contractIDList.findIndex(e => e.id === contractID && e.currencySc === currencySc);
                if(contractIDIndex === -1) {
                    draft[exchangeIndex].contractIDList.push({id: contractID, amount: amount, currencySc: currencySc});
                }
                else {
                    draft[exchangeIndex].contractIDList[contractIDIndex].amount  = amount;
                }
                return draft;
            default:
                return draft;
        }
    })
