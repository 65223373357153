import React from 'react';
import { PanelHeader, HeaderToolbox, ButtonDefaultWrapper } from './styled';
import { ButtonDefault, Tooltip } from 'components/core/basic';

const Header = (props) => {
    return (
        <PanelHeader>
            <div>{props.title}</div>
            <HeaderToolbox>
                {props.buttons && props.buttons.map((e, idx) => (
                    <Tooltip key={idx} content={e.tooltip}>
                        <ButtonDefault as={ButtonDefaultWrapper} active={e.active} disabled={e.disabled} onClick={e.onClick}>
                            <e.icon/>
                        </ButtonDefault>
                    </Tooltip>
                ))}
            </HeaderToolbox>
        </PanelHeader>
    )
}

export default Header;