import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { Confirm, useDefaultInputValues } from 'components/inputs';
import { Deposit } from 'entities/Clearer/transactions';
import Cleave from 'cleave.js/react';
import { DefaultSelect } from 'components/core/selectors';
import { useValidation } from 'hooks/core';

const DepositClearerRow = (props) => {
    const { dispatch, web3Instance, cleave, setCleave, amount, setAmount } = useDefaultInputValues();
    const [selectedCcy, setSelectedCcy] = useState({});
    const currencies = useSelector(state => state.currencyList);
    const privileges = useSelector(state => state.privileges);
    const { clearer } = props;
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount === "",
            errorText: "Can't deposit 0 amount",
        },
        {
            validation: () => clearer.exchanges.length === 0,
            errorText: "Not registered with any Exchange",
        },
        {
            validation: () => clearer.custodians.findIndex(e => e === privileges.selected.scAddress) === -1,
            errorText: "Not registered with this Custodian"
        }
    ])

    const deposit = () => {
        if(disabled) return;
        dispatch(Deposit(
            props.clearer.wallet,
            selectedCcy.item,
            cleave.getRawValue() * Math.pow(10, selectedCcy.decimals),
            web3Instance,
        ));
        props.close();
    }

    return (
        <Wrapper>
            <Inputs>
                <InputItem width={"50%"}>
                    <Cleave onInit={(cleave) => setCleave(cleave)} 
                        className={"cleaveStyle"} style={{height: "25px", width: "100%"}} placeholder={"Amount..."} onChange={(e) => setAmount(e.target.value)} 
                        options={{numeral: true, numeralDecimalScale: selectedCcy.decimals}}
                    />
                </InputItem>
                <InputItem width={"50%"}>
                    <DefaultSelect
                        label={"Currency"}
                        onChange={(selected) => setSelectedCcy(selected)}
                        formatValue={(e) => `${e.name}/${e.symbol}`}
                        list={currencies}
                    />
                </InputItem>
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Deposit"} disabled={disabled} onClick={deposit}/>
        </Wrapper>
    )
}

export { DepositClearerRow };