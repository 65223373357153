import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "UPDATE_ADDRESS_BALANCE":
                const { from, to, value, currencySc } = action.payload;
                const fromIndex = draft.findIndex(e => e.address === from);
                if(fromIndex === -1) {
                    draft.push({
                        address: from,
                        balances: [{currencySc: currencySc, value: -parseInt(value)}],
                    });
                }
                else {
                    const balanceIndex = draft[fromIndex].balances.findIndex(e => e.currencySc === currencySc);
                    if(balanceIndex === -1) {
                        draft[fromIndex].balances.push({
                            currencySc: currencySc,
                            value: -parseInt(value),
                        });
                    }
                    else
                        draft[fromIndex].balances[balanceIndex].value -= parseInt(value);
                }
                const toIndex = draft.findIndex(e => e.address === to);
                if(toIndex === -1) {
                    draft.push({
                        address: to,
                        balances: [{currencySc: currencySc, value: parseInt(value)}],
                    });
                }
                else {
                    const balanceIndex = draft[toIndex].balances.findIndex(e => e.currencySc === currencySc);
                    if(balanceIndex === -1) {
                        draft[toIndex].balances.push({
                            currencySc: currencySc,
                            value: parseInt(value),
                        });
                    }
                    else
                        draft[toIndex].balances[balanceIndex].value += parseInt(value);
                }
                return draft;
            default:
                return draft;
        }
    })
