import { TransactionService } from 'services';
import { abiParser } from 'services/EventHandlingHelpers';

export const Deposit = (clearerWallet, ccyAddress, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Currency"), ccyAddress);
        const method = () => clearerWallet ? contract.methods.deposit(clearerWallet, amount) : contract.methods.deposit(amount);
        TransactionService.executeTransaction(method, ccyAddress, web3, state, "deposit");
    }
}

export const CustodianAcceptSettlement = (settlemendID, exchangeSc, currencySc, sender, to, amount, custodianSc, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Custodian"), custodianSc);
        const method = () => contract.methods.confirmSettlement(settlemendID, exchangeSc, currencySc, sender, to, amount);
        TransactionService.executeTransaction(method, custodianSc, web3, state);
    }
}

export const CustodianRejectSettlement = (settlemendID, exchangeSc, currencySc, sender, to , amount, custodianSc, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Custodian"), custodianSc);
        const method = () => contract.methods.rejectSettlement(settlemendID, exchangeSc, currencySc, sender, to, amount, "");
        TransactionService.executeTransaction(method, custodianSc, web3, state);
    }
}