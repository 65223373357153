import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
    border: ${props => props.borderSize || 10}px solid var(--bgStrong);
    border-radius: 50%;
    border-top: ${props => props.borderSize || 10}px solid var(--textSecondary);
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    animation: ${spin} 1s linear infinite;
`;