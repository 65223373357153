import { toast } from 'react-toastify';
const EthereumTx = require('ethereumjs-tx').Transaction;

const executeTransaction = async (method, to, web3, state, msg) => {
    try {
        if(to)
            await method().call({ from: state.accounts.selected.address });
    }
    catch(err) {
        toast.error(`Transaction ${msg} failed`);
        console.log(err);
        return;
    }
    const txHash = method().encodeABI();
    web3.eth.getTransactionCount(state.accounts.selected.address).then(r => {
        const accountNonceHex = "0x" + r.toString(16);
        const gasPriceHex = web3.utils.toHex(parseInt(state.gasPrice / 100000000) + "");
        let rawTx = {
            nonce: accountNonceHex,
            gasPrice: gasPriceHex,
            gasLimit: "0x7a1200",
            data: txHash,
        }
        if(to) {
            rawTx.to = to;
            rawTx.value = "0x00";
        }
        const privateKey = Buffer.from(state.privateKey, "hex");
        const tx = new EthereumTx(rawTx);
        
        tx.sign(privateKey);
        const serializedTx = tx.serialize();
        web3.eth.sendSignedTransaction("0x" + serializedTx.toString("hex"))
            .then(r => msg && toast.success(`Transaction ${msg} succedeed`)).catch(err => console.log(err))
    });
}

export const WithdrawFromDefaultFund = (amount, ccyAddress, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        
        const compatibleAbi = state.abi.data.CCP.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, state.ccpAddress);
        const method = () => contract.methods.withdrawFromDefaultFund(amount, ccyAddress);
        executeTransaction(method, state.ccpAddress, web3, state, "withdrawFromDefaultFund");
    }
}

export const DepositFromDefaultFund = (ccyAddress, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        
        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, ccyAddress);
        const method = () => contract.methods.depositDefaultFund(amount);
        executeTransaction(method, ccyAddress, web3, state, "depositDefaultFund");
    }
}

export const RegisterClearer = (clearerSc, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const abiName = state.privileges.selected.name;
        const compatibleAbi = state.abi.data[abiName].abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, state.privileges.selected.scAddress);
        const method = () => contract.methods.registerClearer(clearerSc);
        executeTransaction(method, state.privileges.selected.scAddress, web3, state);
    }
}

export const DeregisterClearer = (clearerSc, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const abiName = state.privileges.selected.name;
        const compatibleAbi = state.abi.data[abiName].abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, state.privileges.selected.scAddress);
        const method = () => contract.methods.deregisterClearer(clearerSc);
        executeTransaction(method, state.privileges.selected.scAddress, web3, state);
    }
}

export const Deposit = (clearerWallet, ccyAddress, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, ccyAddress);
        const method = () => clearerWallet ? contract.methods.deposit(clearerWallet, amount) : contract.methods.deposit(amount);
        executeTransaction(method, ccyAddress, web3, state, "deposit");
    }
}

export const Deploy = (type, args, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        
        const compatibleAbi = state.abi.data[type].abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi);
        const method = () => contract.deploy({
            data: "0x" + (state.abi.data[type].data.bytecode.object),
            arguments: type === "CCP" ? [...args] : [...args, state.ccpAddress],
        });
        executeTransaction(method, null, web3, state, "deploy");
    }
}

export const SetDefaultFundContribution = (currencySc, exchangeSc, contractID, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Exchange.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, exchangeSc);
        const method = () => contract.methods.setDefaultFundContribution(currencySc, contractID, amount);
        executeTransaction(method, exchangeSc, web3, state, "setDefaultFundContribution");
    }
}

export const AdjustInitialMargin = (marketSc, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Market.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, marketSc);
        const method = () => contract.methods.adjustInitialMargin(amount);
        executeTransaction(method, marketSc, web3, state, "adjustInitialMargin");
    }
}

export const ClearerDepositDefaultFund = (exchangeSc, symbol, amount, ccyAddress, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, ccyAddress);
        const method = () => contract.methods.depositDefaultFund(exchangeSc, symbol, amount);
        executeTransaction(method, ccyAddress, web3, state, "depositDefaultFund");
    }
}

export const ClearerWithdrawFromDefaultFund = (exchangeSc, clearerSc, symbol, amount, ccyAddress, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Clearer.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, clearerSc);
        const method = () => contract.methods.withdrawFromDefaultFund(exchangeSc, symbol, ccyAddress, amount);
        executeTransaction(method, clearerSc, web3, state, "withdrawFromDefaultFund");
    }
}

export const RegisterCompany = (companySc, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Clearer.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, state.privileges.selected.scAddress);
        const method = () => contract.methods.registerCompany(companySc);
        executeTransaction(method, state.privileges.selected.scAddress, web3, state);
    }
}

export const DeregisterCompany = (companySc, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Clearer.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, state.privileges.selected.scAddress);
        const method = () => contract.methods.deregisterCompany(companySc);
        executeTransaction(method, state.privileges.selected.scAddress, web3, state);
    }
}

export const DepositAccount = (currencySc, accountSc, amount ,web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, currencySc);
        const method = () => contract.methods.depositToAccount(accountSc, amount);
        executeTransaction(method, currencySc, web3, state, "depositToAccount");
    }
}

export const WithdrawAccount = (currencySc, accountSc, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Account.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, accountSc);
        const method = () => contract.methods.withdrawMoney(amount, currencySc);
        executeTransaction(method, accountSc, web3, state, "withdrawMoney");
    }
}

export const AccountCancelWithdrawal = (accountSc, id, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Account.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, accountSc);
        const method = () => contract.methods.cancelWithdrawal(id);
        executeTransaction(method, accountSc, web3, state, "cancelWithdrawal");
    }
}

export const AccountCreateBalance = (accountSc, currencySc, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Account.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, accountSc);
        const method = () => contract.methods.createBalance(currencySc);
        executeTransaction(method, accountSc, web3, state, "createBalance");
    }
}

export const RegisterAccount = (accountSc, web3, exchangeSc) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Exchange.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, exchangeSc);
        const method = () => contract.methods.registerAccount(accountSc);
        executeTransaction(method, exchangeSc, web3, state);
    }
}

export const DeregisterAccount = (accountSc, web3, exchangeSc) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Exchange.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, exchangeSc);
        const method = () => contract.methods.deregisterAccount(accountSc);
        executeTransaction(method, exchangeSc, web3, state);
    }
}

export const CustodianAcceptSettlement = (settlemendID, exchangeSc, currencySc, sender, to, amount, custodianSc, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Custodian.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, custodianSc);
        const method = () => contract.methods.confirmSettlement(settlemendID, exchangeSc, currencySc, sender, to, amount);
        executeTransaction(method, custodianSc, web3, state);
    }
}

export const CustodianRejectSettlement = (settlemendID, exchangeSc, currencySc, sender, to , amount, custodianSc, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Custodian.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, custodianSc);
        const method = () => contract.methods.rejectSettlement(settlemendID, exchangeSc, currencySc, sender, to, amount, "");
        executeTransaction(method, custodianSc, web3, state);
    }
}

export const ClearerRegisterCompanyAnon = (name, scAddress, web3) => {
    return (dispatch, getState) => {
        const state = getState();

        const compatibleAbi = state.abi.data.Clearer.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, state.privileges.selected.scAddress);
        const method = () => contract.methods.registerCompany(scAddress, name);
        executeTransaction(method, state.privileges.selected.scAddress, web3, state, "registerCompany");
    }
}