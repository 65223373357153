import { useState, useEffect } from 'react';

const useElementOverflown = (ref) => {
    const [overflown, setOverflown] = useState(false);
    
    useEffect(() => {
        if(ref.current === undefined) return;
        setOverflown(ref.current.clientHeight < ref.current.scrollHeight);
    }, [ref]);
    
    return overflown;
}

export { useElementOverflown };