import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ListViewRoot, ListContent, ListHeaderRow } from 'components/list/styled';
import { ListColumn, ListWrapper, RowRenderer, useListViewValues, DeployRow } from 'components/list';
import { PanelHeader } from 'components/core/basic';
import { RegisterCompanyRow } from 'entities/Company/inputs';
import { FaPlus, FaUserPlus } from 'react-icons/fa';

const ListView = () => {
    const { selectedEntity, list, privileges, showDeploy, setShowDeploy } = useListViewValues("companyList");
    const accounts = useSelector(state => state.accounts);
    const clearers = useSelector(state => state.clearerList);
    const [showRegister, setShowRegister] = useState(false);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        setFilteredList(list.filter(e => e.wallet === accounts.selected.address || e.clearerSc === privileges.selected.scAddress));
    }, [list, privileges.selected.scAddress, accounts])
    
    useEffect(() => {
        if(showDeploy) setShowRegister(false)
    }, [showDeploy])

    useEffect(() => {
        if(showRegister) setShowDeploy(false)
    }, [showRegister, setShowDeploy])

    const rowRenderer = ({ key, index, style }) => (
        <RowRenderer key={key} index={index} style={style} item={filteredList[index].item}>
            <ListColumn>{filteredList[index].name}</ListColumn>
            <ListColumn timestamp>{filteredList[index].timestamp}</ListColumn>
            <ListColumn>{findClearer(filteredList[index].clearerSc)}</ListColumn>
            <ListColumn flex={2}>{filteredList[index].owner}</ListColumn>
            <ListColumn>{filteredList[index].accounts.length}</ListColumn>
        </RowRenderer>
    )

    const findClearer = (clearerSc) => {
        const clearer = clearers.find(e => e.item === clearerSc);
        return clearer ? clearer.name : "N/A";
    }

    const headerButtons = () => {
        if(privileges.selected.name === "None")
            return [
                {icon: FaPlus, onClick: () => setShowDeploy(true), disabled: showDeploy, active: showDeploy, tooltip: "Deploy Company"}
            ]
        if(privileges.selected.name === "Clearer")
            return [
                {icon: FaUserPlus, onClick: () => setShowRegister(true), disabled: showRegister, active: showRegister, tooltip: "Register Company"},
                {icon: FaPlus, onClick: () => setShowDeploy(true), disabled: showDeploy, active: showDeploy, tooltip: "Deploy Company"},
            ]
        return [];
    }

    if(selectedEntity !== "Company")
        return null;
    return (
        <ListViewRoot>
            <PanelHeader title={"Companies"} buttons={headerButtons()}/>
            {showDeploy &&
                <DeployRow close={() => setShowDeploy(false)} type={"Company"}/>
            }
            {showRegister &&
                <RegisterCompanyRow close={() => setShowRegister(false)}/>
            }
            {filteredList.length > 0 && (
                <ListHeaderRow>
                    <ListColumn head>Name</ListColumn>
                    <ListColumn head>Timestamp</ListColumn>
                    <ListColumn head>Clearer</ListColumn>
                    <ListColumn flex={2} head>Wallet</ListColumn>
                    <ListColumn head>Reg. Accounts</ListColumn>
                </ListHeaderRow>
            )}
            <ListContent headerVisible={filteredList.length > 0}>
                <ListWrapper length={filteredList.length} rowRenderer={rowRenderer}/>
            </ListContent>
        </ListViewRoot>
    )
}

export { ListView };