import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterRoot, FilterCell } from 'components/list/filter/styled';
import { DefaultSelect } from 'components/core/selectors';

const SettlementFilter = (props) => {
    const { setFilterFunction } = props;
    const accounts = useSelector(state => state.accountList);
    const [sender, setSender] = useState(null);
    const [counterparty, setCounterparty] = useState(null);

    useEffect(() => {
        const filterFunction = (e) => {
            if(sender !== "All")
                if(e.sender !== sender) return false;
            if(counterparty !== "All")
                if(e.counterparty !== counterparty) return false;
            return true;
        }
        setFilterFunction(filterFunction)
    }, [sender, counterparty, setFilterFunction])

    return (
        <FilterRoot>
            <FilterCell width={"16.6%"}>
                <DefaultSelect
                    label={"Sender"}
                    onChange={(e) => setSender(e)}
                    formatValue={e => e === "All" ? e : e.name}
                    list={["All"].concat(accounts)}
                />
            </FilterCell>
            <FilterCell width={"16.6%"}>
                <DefaultSelect
                    label={"Counterparty"}
                    onChange={(e) => setCounterparty(e)}
                    formatValue={e => e === "All" ? e : e.name}
                    list={["All"].concat(accounts)}
                />
            </FilterCell>
            <FilterCell width={"16.6%"}>Timestamp</FilterCell>
            <FilterCell width={"16.6%"}>Currency</FilterCell>
            <FilterCell width={"16.6%"}>Amount</FilterCell>
            <FilterCell width={"16.6%"}>Status</FilterCell>
        </FilterRoot>
    );
}

export { SettlementFilter };