import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SetSelectedRowID } from 'redux/reducers/Entities/actions';
import { ListRowWrapper, ListRow } from './styled';

const RowRenderer = (props) => {
    const selectedRowID = useSelector(state => state.selectedRowID);
    const dispatch = useDispatch();

    const selectRow = () => {
        dispatch(SetSelectedRowID(props.item));
    }

    return(
        <ListRowWrapper style={props.style} onClick={selectRow}>
            <ListRow index={props.index} selected={selectedRowID === props.item}>
                {props.children}
            </ListRow>
        </ListRowWrapper>
    )
}

export { RowRenderer };