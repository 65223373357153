import { populateNodeAndDispatch, populateNodeProps } from 'services/EventHandlingHelpers';

export const AddAccount = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setExtraMethods(["company"]).setDisabledMethods(["ccp", "wallet"])
            .setAbiName("Account").setActionType("ADD_ACCOUNT").setAsyncActionType("LOAD_ACCOUNT_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);

        dispatch({ type: "ADD_ACCOUNT_TO_CLEARER_LIST", payload: {clearerSc: evt.returnValues._clearer, accountSc: evt.returnValues._item} });
        dispatch({ type: "ADD_CLEARER_TO_ACCOUNT_LIST", payload: {clearerSc: evt.returnValues._clearer, accountSc: evt.returnValues._item} });
        dispatch({ type: "COMPANY_ADD_ACCOUNT", payload: {companySc: evt.returnValues._node, accountSc: evt.returnValues._item} });
    }
}

export const AccountDeposit = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _moneyAmount, _currency } = evt.returnValues;
        dispatch({ type: "ADD_ACCOUNT_BALANCE", payload: {accountSc: _node, amount: _moneyAmount, currencySc: _currency} });
    }
}

export const AccountWithdrawalProcessed = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _moneyAmount, _currency, _id } = evt.returnValues;
        dispatch({ type: "REMOVE_ACCOUNT_BALANCE", payload: {accountSc: _node, amount: _moneyAmount, currencySc: _currency} });
        dispatch({ type: "ACCOUNT_WITHDRAWAL_PROCESSED", payload: { accountSc: _node, id: _id } });
    }
}

export const AccountWithdrawalRequested = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _id, _requestedAmount, _currency } = evt.returnValues;
        dispatch({ type: "ACCOUNT_WITHDRAWAL_REQUESTED", payload: { accountSc: _node, id: _id, amount: _requestedAmount, currencySc: _currency } });
    }
}

export const AccountWithdrawalCancelled = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _id, _currency, _amount } = evt.returnValues;
        dispatch({ type: "ACCOUNT_WITHDRAWAL_CANCELLED", payload: { accountSc: _node, id: _id, amount: _amount, currencySc: _currency} });
    }
}

export const AccountSettlementUpdate = (evt, web3) => {
    return (dispatch, getState) => {
       const { _node, _currency, ...other } = evt.returnValues;
       dispatch({ type: "ACCOUNT_SETTLEMENT_UPDATE", payload: {accountSc: _node, currencySc: _currency, settlementValues: other} });
    }
}

export const AccountPhysicalSettlementRequested = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _settlementID, _exchange, _currency, _counterparty, _amount, _canBeSatisfied } = evt.returnValues;
        const payload = {
            sender: _node,
            settlementID: _settlementID,
            exchange: _exchange,
            currency: _currency,
            counterparty: _counterparty,
            amount: _amount,
            canBeSatisfied: _canBeSatisfied,
        }
        dispatch({ type: "ACCOUNT_ADD_PHYSICAL_SETTLEMENT", payload: payload });
        web3.eth.getBlock(evt.blockNumber).then(r => {
            dispatch({ type: "SET_SETTLEMENT_TIMESTAMP", payload: {timestamp: r.timestamp, settlementID: _settlementID} });
        });
    }
}

export const AccountPhysicalSettlementProcessed = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _settlementID, _exchange, _currency, _counterparty, _amount } = evt.returnValues;
        const payload = {
            sender: _node,
            settlementID: _settlementID,
            exchange: _exchange,
            currency: _currency,
            counterparty: _counterparty,
            amount: _amount,
        }
        dispatch({ type: "ACCOUNT_PHYSICAL_SETTLEMENT_PROCESSED", payload: payload });
        dispatch({ type: "ADD_ACCOUNT_BALANCE", payload: {accountSc: _counterparty, amount: _amount, currencySc: _currency} });
        dispatch({ type: "REMOVE_ACCOUNT_BALANCE", payload: {accountSc: _node, amount: _amount, currencySc: _currency} });
    }
}

export const AccountPhysicalSettlementRejected = (evt, web3) => {
    return (dispatch, getState) => {
        const { _node, _settlementID, _exchange, _currency, _counterparty, _amount } = evt.returnValues;
        const payload = {
            sender: _node,
            settlementID: _settlementID,
            exchange: _exchange,
            currency: _currency,
            counterparty: _counterparty,
            amount: _amount,
        }
        dispatch({ type: "ACCOUNT_REJECT_PHYSICAL_SETTLEMENT", payload: payload });
    }
}