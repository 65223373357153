import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { Container, ListAndHomeWrapper, DetailViewWrapper, HomeWrapper, ListWrapper, Resize, ResizeSolo } from './styled';
import { AccountListView, AccountDetailView } from 'entities/Account';
import { CustodianListView, CustodianDetailView, CustodianHome } from 'entities/Custodian';
import { ClearerListView, ClearerDetailView, ClearerHome } from 'entities/Clearer';
import { ExchangeListView, ExchangeDetailView, ExchangeHome } from 'entities/Exchange';
import { CurrencyListView, CurrencyDetailView } from 'entities/Currency';
import { CompanyListView, CompanyDetailView } from 'entities/Company';
import { SettlementListView, SettlementDetailView } from 'entities/Settlement';
import { CcpHome } from 'entities/CCP';
import { ContractListView, ContractDetailView } from 'entities/Contract';
import { MarketDetailView, MarketListView } from 'entities/Market';
import { useDimensions } from 'hooks/core';

const View = () => {
    const isHomeVisible = useSelector(state => state.isHomeVisible);
    const privilege = useSelector(state => state.privileges.selected.name);
    const listAndHomeRef = useRef();
    const dimensions = useDimensions(listAndHomeRef);
    const [delta, setDelta] = useState(0);

    useEffect(() => {
        const quarter = dimensions.height/4;
        if(delta > quarter) setDelta(quarter);
        if(delta < -quarter) setDelta(-quarter);
    }, [delta, dimensions.height])

    const resizeHeight = (deltaY) => {
        setDelta(prev => prev + deltaY);
        console.log(deltaY + delta);
    }

    return (
        <Container>
            <ListAndHomeWrapper ref={listAndHomeRef}>
                <HomeWrapper height={(dimensions.height / 2) + delta}>
                    {isHomeVisible && (
                        <React.Fragment>
                            <CcpHome/>
                            <CustodianHome/>
                            {privilege === "Clearer" && <ClearerHome/>}
                            <ExchangeHome/>
                            <Draggable
                                axis="y"
                                onDrag={(_event, { deltaY }) => {
                                    resizeHeight(deltaY);
                                }}
                                position={{ x: 0 }}
                            >
                                <Resize/>
                            </Draggable>
                        </React.Fragment> 
                    )}
                </HomeWrapper>
                <Draggable
                    axis="y"
                    onDrag={(_event, { deltaY }) => {
                        resizeHeight(deltaY);
                    }}
                    position={{ x: 0 }}
                >
                    <ResizeSolo>&nbsp;</ResizeSolo>
                </Draggable>
                <ListWrapper height={(dimensions.height / 2) - delta}>
                    <ExchangeListView/> <ClearerListView/> <CompanyListView/> <CustodianListView/> <CurrencyListView/> <AccountListView/> <SettlementListView/>
                    <ContractListView/> <MarketListView/>
                </ListWrapper>
            </ListAndHomeWrapper>
            <DetailViewWrapper>
                <ExchangeDetailView/> <ClearerDetailView/> <CompanyDetailView/> <CustodianDetailView/> <CurrencyDetailView/> <AccountDetailView/> <SettlementDetailView/>
                <ContractDetailView/> <MarketDetailView/>
            </DetailViewWrapper>
        </Container>
    )
}

export default View;