import React, { useRef, useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { FaCaretDown, FaCaretUp, FaWindowClose, FaExclamationTriangle, FaEye } from 'react-icons/fa';
import { BodyColor, InputAde, ButtonDefault, FileDrop, Spinner } from 'components/core/basic';
import { SetPrimaryKey, SetCcpAddress } from 'redux/reducers/Login/actions';
import { LoadAbi, LoadPrivileges } from 'redux/reducers/Blockchain/actions';
import { useSelectedProvider } from 'hooks/Login/useSelectedProvider';
import { Web3Context } from 'context/Web3Context';
import ProviderMenu from './ProviderMenu';
import { Container, Logo,
    InputContainer, InputTitle, InputAdeWrapper, Provider, ProviderInput, ToggleProvidersButton, PkContainer, EyeIcon,
    SpinnerContainer, LoadingText, 
    ErrorContainer, ErrorIcon,
    ButtonContainer, EnterContainer, UnlockContainer, PrivateKeyButton, KeystoreButton,
    FileDropWrapper, FileInfo, RemoveFileIcon, FileText } from './styled';

const Login = () => {
    const { ccpAddress, privateKey, abi } = useSelector(state => ({
        ccpAddress: state.ccpAddress,
        privateKey: state.privateKey,
        abi: state.abi,
        privileges: state.privileges,
    }), shallowEqual);
    const sp = useSelectedProvider();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({status: false, text: null});
    const [error, setError] = useState({status: false, text: null});
    const [showProviderMenu, setShowProviderMenu] = useState(false);
    const [keyFile, setKeyFile] = useState(null);
    const [fileText, setFileText] = useState(null);
    const [showPk, setShowPk] = useState(false);
    const pkRef = useRef();
    const passwordRef = useRef();
    const ccpRef = useRef();
    const { web3Instance, setWeb3Instance, clearWeb3Instance } = useContext(Web3Context);

    useEffect(() => {
        if(error.status === true) setLoading({status: false, text: null})
    }, [error.status])

    useEffect(() => {
        if(web3Instance === null) return;
        if(fileText) {
            try {
                const decrypt = web3Instance.eth.accounts.decrypt(fileText, passwordRef.current.value);
                dispatch(SetPrimaryKey(decrypt.privateKey.substr(2)));
            }
            catch(err) {
                setError({status: true, text: "Wrong Password"})
                setFileText(null);
                clearWeb3Instance();
                return;
            }
        }
        dispatch(LoadAbi());
        setLoading({status: true, text: "Loading Abi"});
    }, [web3Instance, dispatch, fileText, clearWeb3Instance])

    useEffect(() => {
        if(abi.error === true) setError(({status: true, text: "Couldn't load Abi"}))
    }, [abi.error])

    useEffect(() => {
        if(abi.data === null) return;
        dispatch(LoadPrivileges(web3Instance));
        setLoading(s => ({...s, text: "Loading Privileges"}));
    }, [abi.data, web3Instance, dispatch]);
    
    const handlePkPress = () => {
        if(keyFile === null) {
            if(privateKey.length !== 64) {
                setError({status: true, text: "Private Key must be 64 characters long"});
                return;
            }
            setError({status: false, text: null});
            const web3Set = setWeb3Instance();
            if(web3Set === false) setError({status: true, text: "Wrong Provider Address"})
        }
    }

    const handleKeystorePress = async () => {
        if(keyFile !== null) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                setFileText(e.target.result);
                const web3Set = setWeb3Instance();
                if(web3Set === false) {
                    setError({status: true, text: "Wrong Provider Address"})
                    setFileText(null);
                }
            }
            reader.readAsText(keyFile);
            setError({status: false, text: null});
            setLoading({status: true, text: "Loading Keystore"});
        }
    }

    const handlePkChange = (evt) => {
        dispatch(SetPrimaryKey(pkRef.current.value));
    }

    const handleCcpChange = (evt) => {
        dispatch(SetCcpAddress(ccpRef.current.value));
    }
    
    const handleFileUpload = (file) => {
        setKeyFile(file);
    }
    
    return(
        <BodyColor color={"--bgStrong"}>
            <Container>
                <Logo as={"img"} src={require("assets/logo/logo1.png")} alt={"ADE"}/>
                <InputContainer>
                    <InputTitle>CCP Address</InputTitle>
                    <InputAdeWrapper defaultValue={ccpAddress} ref={ccpRef} as={InputAde} onChange={handleCcpChange}/>
                </InputContainer>
                <InputContainer>
                    {keyFile === null
                        ? <React.Fragment>
                            <InputTitle>Private Key</InputTitle>
                            <PkContainer>
                                <InputAdeWrapper key={"pk"} type={!showPk && "password"} defaultValue={privateKey} ref={pkRef} as={InputAde} onChange={handlePkChange}/>
                                <EyeIcon onClick={() => setShowPk(c => !c)}><FaEye/></EyeIcon>
                            </PkContainer>
                          </React.Fragment>
                        : <React.Fragment>
                            <InputTitle>Password</InputTitle>
                            <InputAdeWrapper key={"pwd"} type={"password"} ref={passwordRef} as={InputAde}/>
                          </React.Fragment>
                    }
                </InputContainer>
                <InputContainer>
                    <InputTitle>Selected Provider</InputTitle>
                    <Provider>
                        <ProviderInput><InputAdeWrapper disabled as={InputAde} value={sp["name"]}/></ProviderInput>
                        <ToggleProvidersButton as={ButtonDefault} onClick={() => setShowProviderMenu(e => !e)}>
                            {showProviderMenu ? <FaCaretUp/> : <FaCaretDown/>}
                        </ToggleProvidersButton>
                    </Provider>
                </InputContainer>
                {showProviderMenu && <ProviderMenu/>}
                {loading.status === false 
                    ?   <ButtonContainer>
                            <EnterContainer>
                                <PrivateKeyButton as={ButtonDefault} onClick={handlePkPress} disabled={keyFile !== null}>Unlock with PK</PrivateKeyButton>
                            </EnterContainer>
                            <UnlockContainer>
                                <KeystoreButton as={ButtonDefault} disabled={keyFile === null} onClick={handleKeystorePress}>Unlock with Keystore File</KeystoreButton>
                                <FileDropWrapper onChange={handleFileUpload} as={FileDrop}/>
                                {keyFile ? <FileInfo><FileText>{keyFile.name}</FileText><RemoveFileIcon onClick={() => setKeyFile(null)} as={FaWindowClose}/></FileInfo> : null}
                            </UnlockContainer>
                        </ButtonContainer>
                    :   <SpinnerContainer>
                            <Spinner height={70} width={70}/>
                            <LoadingText>{loading.text}</LoadingText>
                        </SpinnerContainer>
                }
                {error.status && <ErrorContainer><ErrorIcon as={FaExclamationTriangle}/>{error.text}</ErrorContainer>}
            </Container>
        </BodyColor>
    )
}

export default Login;