import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { AdjustInitialMargin } from 'entities/Exchange/transactions';
import Cleave from 'cleave.js/react';
import { useValidation } from 'hooks/core';
import { DefaultSelect } from 'components/core/selectors';

const AdjustInitialMarginRow = (props) => {
    const { marketSc } = props;
    const { dispatch, web3Instance, cleave, setCleave, amount, setAmount } = useDefaultInputValues();
    const [selectedMarket, setSelectedMarket] = useState({});
    const markets = useSelector(state => state.marketList);
    const [filteredMarkets, setFilteredMarkets] = useState([]);
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount.length < 2,
            errorText: "Percent can't be empty",
        },
        {
            validation: () => marketSc ? false : filteredMarkets.length === 0,
            errorText: "No available expiries",
        },
    ]);

    useEffect(() => {
        if(amount.length > 3)
            cleave.setRawValue(100);
    }, [amount, cleave])

    useEffect(() => {
        setFilteredMarkets(
            markets.filter(e => e.name)
        )
    }, [markets])

    const adjust = () => {
        if(disabled) return;
        dispatch(AdjustInitialMargin(
            marketSc ? marketSc : selectedMarket.item,
            parseInt(cleave.getRawValue().slice(1)),
            web3Instance,
        ))
        props.close();
    }

    return (
        <Wrapper>
            <Inputs>
                {marketSc === undefined &&
                    <InputItem width={"50%"}>
                        <DefaultSelect
                            label={"Expiry"}
                            onChange={e => setSelectedMarket(e)}
                            formatValue={e => e.name}
                            list={filteredMarkets}
                        />
                    </InputItem>
                }
                <InputItem width={marketSc ? "100%" : "50%"}>
                    <Cleave onInit={cleave => setCleave(cleave)}
                        className={"cleaveStyle"} style={{height: "25px", width: "100%"}} onChange={e => setAmount(e.target.value)} 
                        options={{numeral: true, delimeter: "", numeralDecimalScale: 0, prefix: "%"}}
                    />
                </InputItem>
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Adjust Initial Margin"} disabled={disabled} onClick={adjust}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { AdjustInitialMarginRow };