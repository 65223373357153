import { populateNodeAndDispatch, populateNodeProps } from 'services/EventHandlingHelpers';

export const AddCustodian = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setDisabledMethods(["ccp"])
            .setAbiName("Custodian").setActionType("ADD_CUSTODIAN").setAsyncActionType("LOAD_CUSTODIAN_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const RemoveCustodian = (evt, web3) => {
    return async (dispatch, getState) => {
        dispatch({ type: "REMOVE_CUSTODIAN", payload: evt.returnValues._item });
    }
}

export const CustodianRegisterClearer = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node }  = evt.returnValues;
        dispatch({ type: "CUSTODIAN_REGISTER_CLEARER", payload: {clearerSc: _item, custodianSc: _node} });
        dispatch({ type: "ADD_CLEARER_TO_CUSTODIAN_LIST", payload: {clearerSc: _item, custodianSc: _node} });
    }
}

export const CustodianDeregisterClearer = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "CUSTODIAN_DEREGISTER_CLEARER", payload: {clearerSc: _item, custodianSc: _node} });
        dispatch({ type: "REMOVE_CLEARER_FROM_CUSTODIAN_LIST", payload: {clearerSc: _item, custodianSc: _node} });
    }
}

export const custodianEvents = {
    "CCP_addCustodian": AddCustodian,
    "CCP_removeCustodian": RemoveCustodian,
    "Custodian_registerClearer": CustodianRegisterClearer,
    "Custodian_deregisterClearer": CustodianDeregisterClearer,
}