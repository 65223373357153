import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import reducers from 'redux/reducers';
import { saveStateToStorageRoot } from './saveStateToLS';

const middleWare = applyMiddleware(thunk);
const store = createStore(reducers, middleWare);

store.subscribe(throttle(() => {
    saveStateToStorageRoot(store);
}, 1000));

export { store };