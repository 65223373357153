import produce from 'immer';
import { isNil } from 'lodash';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ADD_MARKET":
                draft.push({...action.payload});
                return draft;
            case "LOAD_MARKET_ASYNC_VALUES":
                const updateIndex = draft.findIndex(e => e.item === action.payload.item);
                const {initialMargin, openInterest, ...values} = action.payload;
                draft[updateIndex] = {...draft[updateIndex], ...values};
                if(isNil(draft[updateIndex].initialMargin)) draft[updateIndex].initialMargin = initialMargin; 
                if(isNil(draft[updateIndex].openInterest)) draft[updateIndex].openInterest = openInterest; 
                return draft;
            case "MARKET_UPDATE_INITIAL_MARGIN":
                {
                    const { marketSc, margin } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === marketSc);
                    draft[updateIndex].initialMargin = margin;
                }
                return draft;
            case "MARKET_UPDATE_OPEN_INTEREST":
                {
                    const { marketSc, amount } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === marketSc);
                    draft[updateIndex].openInterest = amount;
                }
                return draft;
            default:
                return draft;
        }
    })
