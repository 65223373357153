import React from 'react';
import { StyledTooltip } from './styled';

const Tooltip = (props) => {
    const {children, ...other} = props;
    return (
        <StyledTooltip {...other}>
            {props.children}
        </StyledTooltip>
    )
}

export default Tooltip;