import produce from 'immer';

export default (state = null, action) => 
    produce(state, draft => {
        switch(action.type) {
            case "SET_GAS_PRICE":
                return action.payload;
            default:
                return draft;
        }
    })
