import React, { useState } from 'react';
import { Wrapper, Label, Data, Button } from '../styled';
import { FaCopy } from 'react-icons/fa';
import { Spinner } from 'components/core/basic';

const DetailViewDataRow = (props) => {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        if(hovered) return;
        setHovered(true);
    }

    const handleMouseLeave = () => {
        if(hovered === false) return;
        setHovered(false);
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.children);
    }

    const formatChildren = () => {
        if(typeof props.children === "boolean")
            return props.children.toString();
        return props.formatValue ? props.formatValue(props.children) : props.children;
    }

    return (
        <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Label>
                {props.label}:
            </Label>
            <Data>
                {formatChildren() ?? <Spinner height={10} width={10} borderSize={5}/>}
            </Data>
            <Button>{hovered && <FaCopy onClick={copyToClipboard}/>}</Button>
        </Wrapper>
    )
}

export { DetailViewDataRow };