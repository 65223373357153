import styled from 'styled-components';

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
`;

export const Bar = styled(RowWrapper)`
    background-color: var(--bgStrong);
    width: 100%;
    height: 55px;
    justify-content: space-between;
    padding: 0px 10px 0px 20px;
    box-sizing: border-box;
    border-radius: 2px;
`;

export const Left  = styled(RowWrapper)`

`;

export const Right = styled(RowWrapper)`
    color: var(--textSecondary);
`;

export const Selector = styled.div`
    font-size: 18px;
    cursor: pointer;
    color: ${props => props.selected ? "var(--textPrimary)" : "var(--textSecondary)"};
    &:hover {
        opacity: 0.5;
    }
    background-color: ${props => props.selected ? "var(--contrast2)" : "var(--bgLight)"};
    padding: 10px;
    border-radius: 2px;
    box-shadow: 2px 2px 5px var(--bgMedium);
    user-select: none;
`;