import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Wrapper, Data, Label, Button } from 'components/detail/basic/styled';
import { FaTimes } from 'react-icons/fa';
import { AccountCancelWithdrawal } from 'entities/Account/transactions';
import { Web3Context } from 'context/Web3Context';
import { useSingleEntity } from 'hooks/core';
import { F } from 'services';

const WithdrawalRow = (props) => {
    const { id, status, currencySc, amount } = props.data;
    const dispatch = useDispatch();
    const { web3Instance } = useContext(Web3Context);
    const thisCurrency = useSingleEntity("currencyList", currencySc);

    const cancel = () => {
        dispatch(AccountCancelWithdrawal(props.accountSc, id, web3Instance));
    }

    return (
        <Wrapper>
            <Label>{`(${id})Amount:`}</Label>
            <Data>{F.currency(amount, thisCurrency?.decimals)}</Data>
            <Label>Status:</Label>
            <Data>{status}</Data>
            <Button>{status === "PENDING" && <FaTimes onClick={cancel}/>}</Button>
        </Wrapper>
    )
}

export { WithdrawalRow };