import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'simplebar/dist/simplebar.min.css';
import 'react-virtualized/styles.css';
import 'tippy.js/dist/tippy.css';
import 'react-toastify/dist/ReactToastify.css';

import Modal from 'react-modal';
Modal.setAppElement(document.getElementById('root'));

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
