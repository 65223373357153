import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDetailViewValues } from 'hooks/detailView';
import { DetailViewRoot, DetailViewContent } from 'components/detail/styled';
import { PanelHeader } from 'components/core/basic';
import { DetailViewDataRow, DetailViewExpander } from 'components/detail';
import { MarginRow } from './MarginRow';
import { FaTape } from 'react-icons/fa';
import { AdjustInitialMarginRow } from 'entities/Exchange/inputs';

const DetailView = () => {
    const { selectedEntity, selectedRowID, data } = useDetailViewValues("marketList");
    const privileges = useSelector(state => state.privileges);
    const initialMarginLog = useSelector(state => state.initialMarginLog);
    const [showMargin, setShowMargin] = useState(false);

    const headerButtons = () => {
        if(privileges.selected.name === "CCP" && selectedRowID)
            return [
                {icon: FaTape, onClick: () => setShowMargin(true), active: showMargin, disabled: showMargin, tooltip: "Adjust Initial Margin"}, 
            ]
    }

    if(selectedEntity !== "Market")
        return null;
    return (
        <DetailViewRoot>
            <PanelHeader title={selectedRowID === null ? "No Row Selected" : ""} buttons={headerButtons()}/>
            {showMargin &&
                <AdjustInitialMarginRow marketSc={data?.item} close={() => setShowMargin(false)}/>
            }
            {data &&
                <DetailViewContent>
                    <DetailViewDataRow label={"Name"}>{data.name}</DetailViewDataRow>
                    <DetailViewDataRow label={"Type"}>{data.type_}</DetailViewDataRow>
                    <DetailViewDataRow label={"Sc Address"}>{data.item}</DetailViewDataRow>
                    <DetailViewDataRow formatValue={e => e && `${e.substring(0, 4)}-${e.substring(4, 6)}-${e.substring(6)}`} label={"Last Trd. Date"}>
                        {data.lastTradingDate}
                    </DetailViewDataRow>
                    <DetailViewDataRow label={"Initial Margin"}>{data.initialMargin}%</DetailViewDataRow>
                    <DetailViewDataRow label={"Open Interest"}>{data.openInterest}</DetailViewDataRow>
                    {initialMarginLog.filter(e => e.marketSc === data?.item).length > 0 && 
                        <DetailViewExpander label={"Adjust Initial Margin Log"} depth={1}>
                            {initialMarginLog.filter(e => e.marketSc === data?.item).map(e => {
                                return <MarginRow data={e} key={e.blockNumber}/>
                            }).reverse()}
                        </DetailViewExpander>}
                </DetailViewContent>
            }
        </DetailViewRoot>
    )
}

export { DetailView };