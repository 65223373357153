import { toast } from 'react-toastify';
import { TransactionService } from 'services';
import { abiParser } from 'services/EventHandlingHelpers';

export const RegisterCompany = (companySc, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Clearer"), state.privileges.selected.scAddress);
        const method = () => contract.methods.registerCompany(companySc);
        TransactionService.executeTransaction(method, state.privileges.selected.scAddress, web3, state);
    }
}

export const DeregisterCompany = (companySc, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Clearer"), state.privileges.selected.scAddress);
        const method = () => contract.methods.deregisterCompany(companySc);
        TransactionService.executeTransaction(method, state.privileges.selected.scAddress, web3, state);
    }
}

export const RegisterCompanyAnonymous = (scAddress, name, web3) => {
    return async (dispatch, getState) => {
        const state = getState();
        try {
            const companyContract = new web3.eth.Contract(abiParser(state, "Company"), scAddress);
            const companyName = await companyContract.methods.name().call();
            if(companyName !== name) throw null
        }
        catch(err) {
            toast.error("Register Company Failed");
            return;
        }
        const contract = new web3.eth.Contract(abiParser(state, "Clearer"), state.privileges.selected.scAddress);
        const method = () => contract.methods.registerCompany(scAddress);
        TransactionService.executeTransaction(method, state.privileges.selected.scAddress, web3, state, "registerCompany");
    }
}