import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ADD_CONTRACT":
                draft.push({...action.payload});
                return draft;
            case "LOAD_CONTRACT_ASYNC_VALUES":
                const updateIndex = draft.findIndex(e => e.item === action.payload.item);
                draft[updateIndex] = {...draft[updateIndex], ...action.payload};
                return draft;
            default:
                return draft;
        }
    })
