import styled, { keyframes } from 'styled-components';

const blink = keyframes`
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
`;

export const Blink = styled.div`
    animation: ${blink} 0.5s linear infinite;
`;