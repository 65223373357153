import styled, { keyframes } from 'styled-components';

export const RowItem = styled.div`
    margin-top: 20px;
`;

export const Container = styled.div`
    margin: auto;
    color: var(--textSecondary);
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
`;

export const Logo = styled(RowItem)`
    max-height: 70px;
`;

export const InputContainer = styled(RowItem)`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const InputTitle = styled.div`
    margin-bottom: 5px;
    font-weight: bold;
`;

export const InputAdeWrapper = styled.div`
    flex: 1;
    height: 25px;
    font-size: 14px;
`;

export const PkContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

export const EyeIcon = styled.div`
    background-color: var(--bgDark);
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0px 5px 0px 5px;
    &: hover {
        color: var(--contrast);
        cursor: pointer;
    }
`;

export const ButtonContainer = styled(RowItem)`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const EnterContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;

export const UnlockContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const PrivateKeyButton = styled.div`
    width: 100%;
    height: 35px;
    font-size: 16px;
    background-color: #2d7f9d;
`;

export const KeystoreButton = styled.div`
    width: 100%;
    height: 35px;
    font-size 16px;
`;

export const ToggleProvidersButton = styled.div`
    height: 25px;
    font-size: 16px;
    margin-left: 10px;
    flex: 0.1;
`;

export const Provider = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ProviderInput = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

export const FileDropWrapper = styled.div`
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
`;

export const FileInfo = styled.div`
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
`;

export const RemoveFileIcon = styled.div`
    &:hover {
        cursor: pointer;
        color: var(--contrast);
    }
`;

export const FileText = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const SpinnerContainer = styled(RowItem)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LoadingText = styled.div`
    margin-top: 10px;
`;

const errorAppear = keyframes`
    from {
        background-color: transparent;
    }

    to {
        background-color: #e11060;
    }
`;

export const ErrorContainer = styled(RowItem)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    background-color: #e11060;
    color: var(--textPrimary);
    font-weight: bold;
    font-size: 16px;
    border-radius: 2px;
    border: 2px solid var(--textSecondary);
    animation: ${errorAppear} 0.5s ease-in 1;
`;

export const ErrorIcon = styled.div`
    font-size: 24px;
    margin-bottom: 5px;
`;