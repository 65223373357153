import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Wrapper, Label, Data, Button } from 'components/detail/basic/styled';
import { FaAngleDown, FaAngleUp, FaCheck, FaTimes, FaUndoAlt, FaPlus, FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { useSingleEntity, useArray, useAddressBalance } from 'hooks/core';
import { Nested, Options, OptionRow, OptionColumn, Red, OptionEdit, Buttons, InputWrapper } from './styled';
import { F } from 'services';
import { Tooltip } from 'components/core/basic';
import { isNil } from 'lodash';
import { RunDFCCalc } from 'entities/Clearer/transactions';
import { EditOptionParams, EditVarRow, DepositBufferFundRow, WithdrawFromBufferFundRow } from 'entities/Clearer/inputs';
import { Web3Context } from 'context/Web3Context';

const Expander = (props) => {
    const [expanded, setExpanded] = useState(false);
    
    return (
        <React.Fragment>
            <Wrapper>
                <Label>
                    <Button onClick={() => setExpanded(c => !c)}>{expanded ? <FaAngleUp/> : <FaAngleDown/>}</Button>
                </Label>
                <Data bold>{props.label}</Data>
                <Button/>
            </Wrapper>
            {expanded && <Nested>{props.children}</Nested>}
        </React.Fragment>
    )
}

const Table = (props) => {
    const { options, decimals, exchangeSc, clearer, currencySc, symbol } = props;
    const [expanded, addExpanded, removeExpanded] = useArray([]);
    const headers = ["Option", "Percent", "Notional", "IsActive"];
    const privilege = useSelector(state => state.privileges.selected.name);

    return (
        <Options>
            <OptionRow head>
                {headers.map(e => <OptionColumn key={e} head>{e}</OptionColumn>)}
                {privilege === "CCP" && <OptionEdit/>}
            </OptionRow>
            {options?.map(e => {
                return (
                    <React.Fragment key={e.key}>
                        <OptionRow>
                            <Tooltip content={F.clearerOptionParamsFull(e.key)}>
                                <OptionColumn>
                                    {F.clearerOptionParams(e.key)}
                                </OptionColumn>
                            </Tooltip>
                            <OptionColumn>
                                {isNil(e.percent) ? "---" : `${e.percent}%`}
                            </OptionColumn>
                            <OptionColumn>
                                {isNil(e.notional) ? "---" : F.currency(e.notional, decimals)}
                            </OptionColumn>
                            <OptionColumn>
                                {e.isActive ? <FaCheck/> : <FaTimes/>}
                            </OptionColumn>
                            {privilege === "CCP" &&
                                <OptionEdit onClick={() => addExpanded(e.key)}><FaPlus/></OptionEdit>
                            }
                        </OptionRow>
                        {expanded.find(x => x === e.key) && <EditOptionParams type={e.key} decimals={decimals} 
                            exchangeSc={exchangeSc} symbol={symbol} clearer={clearer} currencySc={currencySc} close={() => removeExpanded(e.key)}
                                isActive={e.isActive} initialPercent={e.percent} notional={e.notional}
                            />
                        }
                    </React.Fragment>
                )
            })}
        </Options>
    )
}

const CurrencyRow = (props) => {
    const dispatch = useDispatch();
    const privilege = useSelector(state => state.privileges.selected.name);
    const wallet = useSelector(state => state.accounts.selected.address);
    const { currencySc, clearer, exchangeSc, symbol, data } = props;
    const thisCurrency = useSingleEntity("currencyList", currencySc);
    const thisExchange = useSingleEntity("exchangeList", exchangeSc);
    const [ctrIDData, setCtrIDData] = useState(null);
    const [expected, setExpected] = useState(0);
    const [minRequired, setMinRequired] = useState(0);
    const balance = useAddressBalance(wallet);
    const [expanded, setExpanded] = useState(false);
    const [showVar, setShowVar] = useState(false);
    const [showDeposit, setShowDeposit] = useState(false);
    const [showWithdraw, setShowWithdraw] = useState(false);
    const { web3Instance } = useContext(Web3Context);
    
    useEffect(() => {
        setCtrIDData(data?.find(e => e.currencySc === currencySc && e.contractID === symbol && e.exchangeSc === exchangeSc));
    }, [data, currencySc, symbol, exchangeSc])

    useEffect(() => {
        setMinRequired(thisExchange?.contractIDList.find(e => e.id === symbol && e.currencySc === currencySc)?.amount ?? 0)
    }, [thisExchange, symbol, currencySc])

    useEffect(() => {
        let max = minRequired;
        (ctrIDData?.options ?? []).forEach(e => {
            if(e.isActive)
                max = Math.max(e.notional, max);
        });
        setExpected(max);
    }, [minRequired, ctrIDData])

    const runCalc = () => {
        dispatch(RunDFCCalc(
            clearer?.item,
            exchangeSc,
            symbol,
            currencySc,
            web3Instance,
        ));
    }

    return (
        <React.Fragment>
            <Wrapper as={ctrIDData?.amount < (ctrIDData?.contribution ?? minRequired) ? Red : null}>
                <Tooltip content={"BFC didn't satisfy Mininimum Requirement"} disabled={ctrIDData?.amount >= (ctrIDData?.contribution ?? minRequired)}>
                    <Data>
                        {thisCurrency?.symbol}
                        &nbsp;
                        BFC deposit({F.currency(ctrIDData?.amount, thisCurrency?.decimals)})
                        &nbsp;
                        VAR({ctrIDData?.varValue ?? 0}%)
                        &nbsp;
                        <React.Fragment>
                            Contract Min Req({F.currency(
                                minRequired,
                                thisCurrency?.decimals)})
                        </React.Fragment>
                        &nbsp;
                        Balance({F.currency(balance?.balances.find(e => e.currencySc === currencySc)?.value, thisCurrency?.decimals)})
                    </Data>
                </Tooltip>
                {privilege === "Clearer" && 
                    (<Tooltip content={"Deposit"}>
                        <Button onClick={() => setShowDeposit(true)}>{<FaLongArrowAltDown/>}</Button>
                    </Tooltip>)
                }
                {privilege === "Clearer" && 
                    (<Tooltip content={"Withdraw"}>
                        <Button onClick={() => setShowWithdraw(true)}>{<FaLongArrowAltUp/>}</Button>
                    </Tooltip>)
                }
                {privilege === "Clearer" && 
                    (<Tooltip content={"Set VAR"}>
                        <Button onClick={() => setShowVar(true)}>{<FaPlus/>}</Button>
                    </Tooltip>)
                }
                <Button onClick={() => setExpanded(v => !v)}>{expanded ? <FaAngleUp/> : <FaAngleDown/>}</Button>
            </Wrapper>
            <Buttons>
                <InputWrapper>
                    {showDeposit && <DepositBufferFundRow exchangeSc={exchangeSc} symbol={symbol} currencySc={currencySc} close={() => setShowDeposit(false)}/>}
                </InputWrapper>
                <InputWrapper>
                    {showWithdraw && <WithdrawFromBufferFundRow exchangeSc={exchangeSc} symbol={symbol} currencySc={currencySc} close={() => setShowWithdraw(false)}/>}
                </InputWrapper>
                <InputWrapper>
                    {showVar && <EditVarRow exchangeSc={exchangeSc} symbol={symbol} currencySc={currencySc} clearerSc={clearer?.item} close={() => setShowVar(false)}/>}
                </InputWrapper>
            </Buttons>
            {expanded &&
                <React.Fragment>
                    <Wrapper>
                        <Data>
                            Expected Req({F.currency(expected, thisCurrency?.decimals)})
                            &nbsp;
                            Calculated Req({F.currency(ctrIDData?.contribution, thisCurrency?.decimals)})
                        </Data>
                        {privilege === "CCP" && (
                            <Tooltip content={"Run BFC Calc"}>
                                <Button onClick={runCalc}><FaUndoAlt/></Button>
                            </Tooltip>
                        )}
                    </Wrapper>
                    <Table decimals={thisCurrency?.decimals} exchangeSc={exchangeSc} symbol={symbol} currencySc={currencySc}
                        options={[...ctrIDData?.options].sort((a, b) => a.key - b.key)} clearer={clearer}
                    />
                </React.Fragment> 
            }
        </React.Fragment>
    )
}

const ContractRow = (props) => {
    const { symbol, exchangeSc, data, clearer } = props;
    const [currencies, setCurrencies] = useState([]);
    const contractIDList = useSelector(state => state.contractIDList);

    useEffect(() => {
        const currencies = data.filter(e => e.exchangeSc === exchangeSc && e.contractID === symbol).reduce((acc, curr) => {
            if(acc.includes(curr.currencySc)) return acc;
            return [...acc, curr.currencySc];
        }, []) ?? [];
        setCurrencies(currencies);
    }, [contractIDList, data, exchangeSc, symbol])

    return (
        <Expander label={symbol}>
            {currencies.map(e => <CurrencyRow key={e} currencySc={e} symbol={symbol} exchangeSc={exchangeSc} clearer={clearer} data={data}/>)}
        </Expander>
    )
}

const ExchangeRow = (props) => {
    const { exchangeSc, data, clearer } = props;
    const thisExchange = useSingleEntity("exchangeList", exchangeSc);
    const contractIDList = useSelector(state => state.contractIDList);
    const [contracts, setContracts] = useState([]);

    useEffect(() => {
        const contracts = data.filter(e => e.exchangeSc === exchangeSc).reduce((acc, curr) => {
            if(acc.includes(curr.contractID)) return acc;
            return [...acc, curr.contractID];
        }, []) ?? [];
        setContracts(contracts);
    }, [contractIDList, data, exchangeSc])

    return (
        <Expander label={thisExchange?.name}>
            {contracts.map(e => <ContractRow key={e} data={data} symbol={e} exchangeSc={exchangeSc} clearer={clearer}/>)}
        </Expander>
    )
}

const ContractBfc = (props) => {
    const { clearer } = props;
    const contractIDList = useSelector(state => state.contractIDList);
    const [exchanges, setExchanges] = useState([]);
    
    useEffect(() => {
        const exchanges = contractIDList.find(e => e.clearerSc === clearer?.item)?.data.reduce((acc, curr) => {
            if(acc.includes(curr.exchangeSc)) return acc;
            return [...acc, curr.exchangeSc];
        }, []) ?? [];
        setExchanges(exchanges.filter(e => clearer.exchanges.includes(e)));
    }, [contractIDList, clearer])

    return (
        <Expander label={"Contract Bfc Info"}>
            {exchanges.map(e => <ExchangeRow key={e} data={contractIDList.find(e => e.clearerSc === clearer?.item)?.data} exchangeSc={e} clearer={clearer}/>)}
        </Expander>
    )
}

export { ContractBfc };