import React from 'react';
import { DataRow, Label, Data } from '../styled';
import { Spinner } from 'components/core/basic';
import { isNil } from 'lodash';

const Loading = () => <Spinner height={10} width={10} borderSize={5}/>

const HomeCcyLabel = (props) => {
    const { name, symbol } = props;

    return (
        <DataRow>
            <Label>Ccy:</Label>
            <Data>
                <React.Fragment>
                    {isNil(name) ? <Loading/> : name}
                </React.Fragment>
                /
                <React.Fragment>
                    {isNil(symbol) ? <Loading/> : symbol}
                </React.Fragment>
            </Data>
        </DataRow>
    )
}

export default HomeCcyLabel;