import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HomeRoot, HomeRow, HomeDataRowWrapper } from 'components/home/styled';
import { PanelHeader, Tooltip } from 'components/core/basic';
import { HomeContent, HomeDataRow, SettlementRow } from 'components/home';
import { DepositBufferFundRow, WithdrawFromBufferFundRow } from 'entities/Clearer/inputs';
import { useArray, useAddressBalance, useSingleEntity } from 'hooks/core';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { DetailViewExpander } from 'components/detail';
import { ContractBfc } from 'entities/Clearer/detail/ContractBfc';
import { F } from 'services';

const Home = () => {
    const walletAddress = useSelector(state => state.accounts.selected.address);
    const privileges = useSelector(state => state.privileges);
    const currencies = useSelector(state => state.currencyList);
    const clearers = useSelector(state => state.clearerList);
    const exchanges = useSelector(state => state.exchangeList);
    const accounts = useSelector(state => state.accountList);
    const [unsatisfiedSettlements, setUnsatisfiedSettlements] = useState([]);
    const thisClearer = useSingleEntity("clearerList", privileges.selected.scAddress);
    const thisBalances = useAddressBalance(walletAddress);
    const scBalances = useAddressBalance(privileges.selected.scAddress);
    const [depositRows, addDepositRow, removeDepositRow] = useArray([]);
    const [withdrawRows, addWithdrawRow, removeWithdrawRow] = useArray([]);

    useEffect(() => {
        let settlements = [];
        accounts.forEach(e => {
            if(!thisClearer?.accounts.includes(e.item))
                return;
            e.settlements.forEach(s => {
                if(s.canBeSatisfied === false && s.to) settlements.push({...s, sender: e.item})
            });
        });
        setUnsatisfiedSettlements(settlements);
    }, [accounts, thisClearer])

    const depositButtons = (ccy) => {
        return [{
            icon: <Tooltip content={"Deposit Buffer Fund"}><span><FaLongArrowAltDown/></span></Tooltip>,
            onClick: () => addDepositRow(ccy.item),
        }]
    }

    const withdrawButtons = (ccy) => {
        return [{
            icon: <Tooltip content={"Withdraw from Buffer Fund"}><span><FaLongArrowAltUp/></span></Tooltip>,
            onClick: () => addWithdrawRow(ccy.item),
        }]
    }
    
    if(privileges.selected.name !== "Clearer")
        return null;
    return(
        <HomeRoot>
            <PanelHeader title={"Home"}/>
            <HomeContent innerStyle={{display: "flex", flexDirection: "column"}}>
                {clearers.find(e => e.item === privileges.selected.scAddress)?.exchanges.length > 0 &&
                    <DetailViewExpander label={"Exchanges"}>
                        {clearers.find(e => e.item === privileges.selected.scAddress)?.exchanges.map(e => {
                            return (
                                <HomeDataRowWrapper key={e} noMarginRight>
                                    <HomeDataRow label={"Exchange"}>{exchanges.find(x => x.item === e).name}</HomeDataRow>
                                </HomeDataRowWrapper>
                            )
                        })}
                    </DetailViewExpander>
                }
                {unsatisfiedSettlements.length > 0 &&
                    <DetailViewExpander label={"Unsatisfied Settlements"}>
                        {unsatisfiedSettlements.map(e => {
                            return <HomeDataRowWrapper noMarginRight><SettlementRow data={e}/></HomeDataRowWrapper>
                        })}
                    </DetailViewExpander>
                }
                {thisBalances && currencies.map(e => {
                    const walletBalance = thisBalances.balances.find(bl => bl.currencySc === e.item)?.value ?? 0;
                    const scBalance = scBalances?.balances.find(bl => bl.currencySc === e.item)?.value ?? 0;
                    return(
                        <HomeRow key={e.item}>
                            <HomeDataRowWrapper width={"20%"}>
                                <HomeDataRow label={"Ccy:"}>
                                    {`${e.name}/${e.symbol}`}
                                </HomeDataRow>
                            </HomeDataRowWrapper>
                            <HomeDataRowWrapper width={"40%"}>
                                <HomeDataRow label={"Balance:"}>
                                    {F.currency(walletBalance, e.decimals)}
                                </HomeDataRow>
                                {/* {depositRows.find(row => row === e.item) && 
                                    <DepositBufferFundRow balance={walletBalance} ccy={e} close={() => removeDepositRow(e.item)}/>
                                } */}
                            </HomeDataRowWrapper>
                            <HomeDataRowWrapper width={"40%"}>
                                <HomeDataRow label={"BFC:"}>
                                    {F.currency(scBalance, e.decimals)}
                                </HomeDataRow>
                                {/* {withdrawRows.find(row => row === e.item) && 
                                    <WithdrawFromBufferFundRow clearerSc={privileges.selected.scAddress} dfc={scBalance} 
                                        ccy={e} close={() => removeWithdrawRow(e.item)}/>
                                } */}
                            </HomeDataRowWrapper>
                        </HomeRow>
                    )
                })}
                {<ContractBfc clearer={thisClearer}/>}
            </HomeContent>
        </HomeRoot>
    )
}

export { Home };