import React from 'react';
import { useDetailViewValues } from 'hooks/detailView';
import { DetailViewRoot, DetailViewContent } from 'components/detail/styled';
import { PanelHeader } from 'components/core/basic';
import { DetailViewDataRow } from 'components/detail';
import { F } from 'services'; 

const DetailView = () => {
    const { selectedEntity, selectedRowID, data } = useDetailViewValues("currencyList");

    if(selectedEntity !== "Currency")
        return null;
    return (
        <DetailViewRoot>
            <PanelHeader title={selectedRowID === null ? "No Row Selected" : ""}/>
            {data &&
                <DetailViewContent>
                    <DetailViewDataRow label={"Name"}>{data.name}</DetailViewDataRow>
                    <DetailViewDataRow label={"Sc Address"}>{data.item}</DetailViewDataRow>
                    <DetailViewDataRow formatValue={(v) => F.currency(v, data.decimals)} label={"Total Supply"}>{data.totalSupply}</DetailViewDataRow>
                    <DetailViewDataRow label={"Decimals"}>{data.decimals}</DetailViewDataRow>
                    <DetailViewDataRow label={"Symbol"}>{data.symbol}</DetailViewDataRow>
                    <DetailViewDataRow formatValue={(v) => F.currency(v, data.decimals)} label={"Balance"}>{data.balanceOf}</DetailViewDataRow>
                </DetailViewContent>
            }
        </DetailViewRoot>
    )
}

export { DetailView };