import React, { useContext, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDetailViewValues } from 'hooks/detailView';
import { DetailViewRoot, DetailViewContent, SpinIcon } from 'components/detail/styled';
import { DepositClearerRow } from 'entities/Clearer/inputs';
import { PanelHeader } from 'components/core/basic';
import { DetailViewDataRow, DetailViewExpander } from 'components/detail';
import { FaUserCheck, FaUserSlash, FaSpinner, FaDonate, FaPaste } from 'react-icons/fa';
import { RegisterClearer, DeregisterClearer } from 'entities/Clearer/transactions';
import { ContractBfc } from './ContractBfc';
import { Web3Context } from 'context/Web3Context';
import { ExchangeDetailViewData } from 'entities/Exchange';
import { CustodianDetailViewData } from 'entities/Custodian';
import { AccountDetailViewData } from 'entities/Account';
import { useAddressBalance, useClearerTotalAccountSupply } from 'hooks/core';
import { F } from 'services';

const RegisterIcon = (props) => {
    if(props.loading)
        return <SpinIcon as={FaSpinner}/>
    return props.registered ? <FaUserSlash/> : <FaUserCheck/>;
}

const Spinner = () => <SpinIcon as={FaSpinner}/>

const DetailViewData = (props) => {
    const { data } = props;
    const currencies = useSelector(state => state.currencyList);
    const thisBalance = useAddressBalance(data.wallet);
    const totalAccBalance = useClearerTotalAccountSupply(data.item);
    
    return (
        <React.Fragment>
            <DetailViewDataRow label={"Name"}>{data.name}</DetailViewDataRow>
            <DetailViewDataRow label={"Sc Address"}>{data.item}</DetailViewDataRow>
            <DetailViewDataRow label={"Wallet"}>{data.wallet}</DetailViewDataRow>
            {thisBalance?.balances?.length > 0 &&
                <DetailViewExpander label={"Currency Balances"}>
                    {thisBalance.balances.map(e => {
                        const currency = currencies.find(c => c.item === e.currencySc);
                        return (
                            <DetailViewDataRow key={e.currencySc} label={`${currency.name}/${currency.symbol}`}>
                                {F.currency(e.value, currency.decimals)}
                            </DetailViewDataRow>
                        )
                    })}
                </DetailViewExpander>
            }
            {totalAccBalance.length > 0 &&
                <DetailViewExpander label={"Balances Under Control(Accounts)"}>
                    {totalAccBalance.map(e => {
                        const currency = currencies.find(c => c.item === e.currencySc);
                        return (
                            <DetailViewDataRow key={e.currencySc} label={`${currency.name}/${currency.symbol}`}>
                                {F.currency(e.amount, currency.decimals)}
                            </DetailViewDataRow>
                        )
                    })}
                </DetailViewExpander>
            }
            {thisBalance?.balances?.length > 0 &&
                <DetailViewExpander label={"Currency Stats"}>
                    {thisBalance.balances.map(e => {
                        const currency = currencies.find(c => c.item === e.currencySc);
                        const currencyPercent = Number(e.value * 100 / currency.totalSupply).toFixed(2);
                        return (
                            <DetailViewDataRow key={e.currencySc} label={`${currency.name} % of Total`}>
                                %{currencyPercent}
                            </DetailViewDataRow>
                        )
                    })}
                </DetailViewExpander>
            }
        </React.Fragment>
    );
}

export { DetailViewData };

const DetailView = () => {
    const { selectedEntity, selectedRowID, data } = useDetailViewValues("clearerList");
    const custodians = useSelector(state => state.custodianList);
    const exchanges = useSelector(state => state.exchangeList);
    const accounts = useSelector(state => state.accountList);
    const privileges = useSelector(state => state.privileges);
    const dispatch = useDispatch();
    const [isRegisteredByUser, setIsRegisteredByUser] = useState(false);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const [showDeposit, setShowDeposit] = useState(false);
    const prevRegistered = useRef();
    const { web3Instance } = useContext(Web3Context);

    useEffect(() => {
        if(data && ["Exchange", "Custodian"].includes(privileges.selected.name)) {
            const registererListName = privileges.selected.name === "Exchange" ? "exchanges" : "custodians";
            const registered = data[registererListName].find(e => e === privileges.selected.scAddress) ? true : false;
            setIsRegisteredByUser(registered);
        }
    }, [data, privileges])

    useEffect(() => {
        if(data) {
            if(isRegisteredByUser !== prevRegistered.current)
                setLoadingRegister(false);
            prevRegistered.current = isRegisteredByUser;
        }
    }, [isRegisteredByUser, data])

    const handleRegisterClick = () => {
        if(loadingRegister) return;
        const func = isRegisteredByUser ? DeregisterClearer : RegisterClearer;
        dispatch(func(data.item, web3Instance));
        setLoadingRegister(true);
    }

    const headerButtons = () => {
        if(selectedRowID && data) {
            if(["Exchange"].includes(privileges.selected.name))
                return [
                    {icon: loadingRegister ? Spinner : (isRegisteredByUser ? FaUserSlash : FaUserCheck),
                        onClick: handleRegisterClick, tooltip: (isRegisteredByUser ? "Deregister" : "Register") + " Clearer"},
                ]
            if(["Custodian"].includes(privileges.selected.name))
                return [
                    {icon: loadingRegister ? Spinner : (isRegisteredByUser ? FaUserSlash : FaUserCheck),
                        onClick: handleRegisterClick, tooltip: (isRegisteredByUser ? "Deregister" : "Register") + " Clearer"},
                    {icon: FaDonate, onClick: () => setShowDeposit(v => !v), active: showDeposit, tooltip: "Deposit Clearer Wallet"},
                ]
        }
    }

    if(selectedEntity !== "Clearer")
        return null;
    return (
        <DetailViewRoot>
            <PanelHeader title={selectedRowID === null ? "No Row Selected" : ""} buttons={headerButtons()}/>
            {data &&
                <DetailViewContent>
                    {showDeposit &&
                        <DepositClearerRow clearer={data} close={() => setShowDeposit(false)}/>
                    }
                    <DetailViewData data={data}/>
                    {privileges.selected.name !== "Exchange" && data.custodians.length > 0 &&
                        <DetailViewExpander label={"Custodians registered with"}>
                            {data.custodians.map(e => (
                                <DetailViewExpander key={e} reverse label={custodians.find(c => c.item === e).name}>
                                    <CustodianDetailViewData data={custodians.find(c => c.item === e)}/>
                                </DetailViewExpander>
                            ))}
                        </DetailViewExpander>
                    }
                    {privileges.selected.name !== "Custodian" && data.exchanges.length > 0 &&
                        <DetailViewExpander label={"Exchanges registered with"}>
                            {data.exchanges.map(e => (
                                <DetailViewExpander key={e} reverse label={exchanges.find(c => c.item === e).name}>
                                    <ExchangeDetailViewData data={exchanges.find(c => c.item === e)}/>
                                </DetailViewExpander>
                            ))}
                        </DetailViewExpander>
                    }
                    {privileges.selected.name === "CCP" && data.accounts.length > 0 &&
                        <DetailViewExpander label={"Created Accounts"}>
                            {data.accounts.map(e => (
                                <DetailViewExpander key={e} reverse label={accounts.find(c => c.item === e).name}>
                                    <AccountDetailViewData data={accounts.find(c => c.item === e)}/>
                                </DetailViewExpander>
                            ))}
                        </DetailViewExpander>
                    }
                    {privileges.selected.name === "CCP" &&
                        <ContractBfc clearer={data}/>
                    }
                </DetailViewContent>
            }
        </DetailViewRoot>
    )
}

export { DetailView };