import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ADD_CURRENCY":
                draft.push({...action.payload, totalDFC: 0});
                return draft;
            case "LOAD_CURRENCY_ASYNC_VALUES":
                const updateIndex = draft.findIndex(e => e.item === action.payload.item);
                draft[updateIndex] = {...draft[updateIndex], ...action.payload};
                return draft;
            case "REMOVE_CURRENCY":
                const removeIndex = draft.findIndex(e => e.item === action.payload);
                draft.splice(removeIndex, 1);
                return draft;
            case "CCP_ADD_DFC":
                draft.forEach((e, index) => {
                    if(action.payload.node === e.item) {
                        draft[index].totalDFC += parseInt(action.payload.delta);
                        draft[index].balanceOf = action.payload.balanceOf;
                    } 
                });
                return draft;
            case "CCP_REMOVE_DFC":
                const {node, delta} = action.payload;
                draft.forEach((e, index) => {
                    if(node === e.item) {
                        draft[index].totalDFC -= parseInt(delta);
                        draft[index].balanceOf = action.payload.balanceOf; 
                    }
                });
                return draft;
            case "SET_TOTAL_SUPPLY":
                const updateSupplyIndex = draft.findIndex(e => e.item === action.payload.currencyAddress);
                draft[updateSupplyIndex].totalSupply = action.payload.newValue;
                return draft; 
            default:
                return draft;
        }
    })
