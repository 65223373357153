import React from 'react';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { WithdrawFromDefaultFund } from 'entities/CCP/transactions';
import Cleave from 'cleave.js/react';
import { useValidation } from 'hooks/core';
import { isNil } from 'lodash';

const WithdrawFromBufferFundRow = (props) => {
    const { dispatch, web3Instance, cleave, setCleave, amount, setAmount } = useDefaultInputValues();
    const { ccy, dfc } = props;
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount === "",
            errorText: "You can't withdraw 0 amount",
        },
        {
            validation: () => isNil(dfc),
            errorText: "You have no funds in BFC",
        },
        {
            validation: () => dfc < cleave.getRawValue() * Math.pow(10, ccy.decimals),
            errorText: "You can't withdraw more than you have in BFC"
        }
    ]);

    const withdraw = () => {
        if(disabled) return;
        dispatch(WithdrawFromDefaultFund(
            cleave.getRawValue() * Math.pow(10, ccy.decimals),
            ccy.item,
            web3Instance,
        ))
        props.close();
    }

    return (
        <Wrapper>
            <Inputs>
                <InputItem width={"100%"}>
                    <Cleave onInit={(cleave) => setCleave(cleave)} 
                        className={"cleaveStyle"} style={{height: "25px", width: "100%"}} placeholder={"Amount..."} onChange={(e) => setAmount(e.target.value)} 
                        options={{numeral: true, numeralDecimalScale: ccy.decimals}}
                    />
                </InputItem>
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Withdraw"} disabled={disabled} onClick={withdraw}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { WithdrawFromBufferFundRow };