import styled, { keyframes } from 'styled-components';

const appear = keyframes`
    from {
        opacity: 0.75;
    }

    to {
        opacity: 1;
    }
`;

export const ListViewRoot = styled.div`
    height: 100%;
    border-radius: 2px;
    animation: ${appear} 1s ease 1;
    background-color: var(--bgStrong);
`;

export const ListContent = styled.div`
    height: calc(100% - ${props => props.headerVisible ? 70 : 45}px);
    width: 100%;
    background-color: var(--bgStrong);
    padding-top: 5px;
    box-sizing: border-box;
`;

export const ListHeaderRow = styled.div`
    height: 25px;
    background-color: var(--bgLight);
    padding: 0px 5px 0px 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
`;