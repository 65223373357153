import { TransactionService } from 'services';
import { abiParser } from 'services/EventHandlingHelpers';

export const DepositAccount = (currencySc, accountSc, amount ,web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Currency"), currencySc);
        const method = () => contract.methods.depositToAccount(accountSc, amount);
        TransactionService.executeTransaction(method, currencySc, web3, state, "depositToAccount");
    }
}

export const WithdrawAccount = (currencySc, accountSc, amount, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Account"), accountSc);
        const method = () => contract.methods.withdrawMoney(amount, currencySc);
        TransactionService.executeTransaction(method, accountSc, web3, state, "withdrawMoney");
    }
}

export const AccountCancelWithdrawal = (accountSc, id, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Account"), accountSc);
        const method = () => contract.methods.cancelWithdrawal(id);
        TransactionService.executeTransaction(method, accountSc, web3, state, "cancelWithdrawal");
    }
}

export const AccountCreateBalance = (accountSc, currencySc, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Account"), accountSc);
        const method = () => contract.methods.createBalance(currencySc);
        TransactionService.executeTransaction(method, accountSc, web3, state, "createBalance");
    }
}

export const RegisterAccount = (accountSc, web3, exchangeSc) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Exchange"), exchangeSc);
        const method = () => contract.methods.registerAccount(accountSc);
        TransactionService.executeTransaction(method, exchangeSc, web3, state);
    }
}

export const DeregisterAccount = (accountSc, web3, exchangeSc) => {
    return (dispatch, getState) => {
        const state = getState();
        const contract = new web3.eth.Contract(abiParser(state, "Exchange"), exchangeSc);
        const method = () => contract.methods.deregisterAccount(accountSc);
        TransactionService.executeTransaction(method, exchangeSc, web3, state);
    }
}