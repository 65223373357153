import styled from 'styled-components';

export const Input = styled.input`
    appearance: none;
    border: 0px;
    background-color: var(--bgDark);
    color: var(--textPrimary);
    border-radius: 2px;
    &:focus {
        outline: none;
        border-bottom: 2px solid var(--contrast);
    }
    padding: 0px 0.5em 0px 0.5em;
    box-sizing: border-box;
    height: ${props => props.height ? props.height + "px" : "auto"};
`;