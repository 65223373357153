import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner, Tooltip } from 'components/core/basic';
import { DataRow, Label, Data, DataPart, Button, Red } from 'components/home/basic/styled';
import { useSingleEntity } from 'hooks/core';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { F } from 'services';

const Loading = () => <Spinner height={10} width={10} borderSize={5}/>

const ExchangeContractRow = (props) => {
    const { id, amount, currencySc } = props.data;
    const exchange = props.exchange;
    const thisCcy = useSingleEntity("currencyList", currencySc);
    const clearers = useSelector(state => state.clearerList);
    const contractIDList = useSelector(state => state.contractIDList);
    const [clearerContributions, setClearerContributions] = useState([]);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        let filtered = [];
        contractIDList.forEach(e => {
            if(exchange?.clearers.includes(e.clearerSc)) {
                const clearerDfc = e.data.find(d => d.exchangeSc === exchange.item && d.contractID === id && d.currencySc === currencySc);
                filtered.push({
                    clearerSc: e.clearerSc,
                    amount: clearerDfc?.amount ?? 0,
                });
            }
        });
        setClearerContributions(filtered);
    }, [contractIDList, currencySc, exchange, id])

    useEffect(() => {
        if(clearerContributions.length === 0)
            setExpanded(false);
    }, [clearerContributions, expanded])
    
    return (
        <React.Fragment>
            <DataRow marginTop={"5px"}>
                <Label>{id}</Label>
                <Data>
                    <React.Fragment>
                        {thisCcy?.name ? thisCcy.name : <Loading/>}
                    </React.Fragment>
                    /
                    <React.Fragment>
                        {thisCcy?.symbol ? thisCcy?.symbol : <Loading/>}
                    </React.Fragment>
                    <DataPart>
                        {F.currency(amount, thisCcy?.decimals)}
                    </DataPart>

                </Data>
                <Button inactive={clearerContributions.length === 0}>
                    {expanded ? <FaAngleUp onClick={() => setExpanded(false)}/> : <FaAngleDown onClick={() => setExpanded(true)}/>}
                </Button>
            </DataRow>
            {expanded && clearerContributions.map(e => {
                const clearer = clearers.find(c => c.item === e.clearerSc);
                return (
                    <Tooltip content={"Clearer didn't satisfy minimum BFC"} disabled={amount < e.amount}>
                        <DataRow key={e.clearerSc} as={amount > e.amount ? Red : null}>
                            <Label>{clearer?.name}</Label>
                            <Data>{F.currency(e.amount, thisCcy?.decimals)}</Data>
                            <Button>&nbsp;</Button>
                        </DataRow>
                    </Tooltip>
                )
            })}
        </React.Fragment>
    )
}

export { ExchangeContractRow };