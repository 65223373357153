import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm, Close } from 'components/inputs';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { InputAde } from 'components/core/basic';
import { DefaultSelect } from 'components/core/selectors';
import Cleave from 'cleave.js/react';
import { Deploy } from 'redux/reducers/UserActions/actions';
import { Web3Context } from 'context/Web3Context';
import { useValidation } from 'hooks/core';

const inputStyle = {
    height: "25px",
    width: "100%",
}

const DFDP = {
    1: "Pro-rata",
    2: "Linear(Highest to Min)",
    3: "Linear(Highest)",
    4: "Linear(Lowest)",
}

const DFDPOE = {
    1: "Linear",
    2: "Notional",
}

const DeployRow = (props) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [wallet, setWallet] = useState("");
    const [dfdp, setDfdp] = useState(1);
    const [dfdpoe, setDfdpoe] = useState(1);
    const [decimals, setDecimals] = useState(2);
    const [symbol, setSymbol] = useState("");
    const [datetime, setDatetime] = useState("");
    const [stringLing, setStringLing] = useState("");
    const companies = useSelector(state => state.companyList);
    const [companySc, setCompanySc] = useState(null);
    const { web3Instance } = useContext(Web3Context);
    const { type } = props;
    const [disabled, errorText] = useValidation([
        {
            validation: () => name === "",
            errorText: "Name can't be empty",
        },
        {
            validation: () => !web3Instance.utils.isAddress(wallet) && ["Custodian", "Clearer", "Exchange"].includes(type),
            errorText: "Invalid wallet address",
        },
        {
            validation: () => type === "Currency" && symbol === "",
            errorText: "Symbol can't be empty",
        },
        {
            validation: () => type === "Currency" && decimals === "",
            errorText: "Decimals can't be emtpy",
        },
        {
            validation: () => type === "Account" && companies.length === 0,
            errorText: "No deployed companies",
        },
        {
            validation: () => type === "CCP" && datetime === "",
            errorText: "Datetime string can't be empty",
        },
        {
            validation: () => type === "CCP" && stringLing === "",
            errorText: "StringLing can't be empty",
        }
    ]);

    const deploy = () => {
        if(disabled) return;
        dispatch(Deploy(
            props.type,
            deployFields(),
            web3Instance,
        ));
        props.close();
    }

    const deployFields = () => {
        switch(props.type) {
            case "Exchange":
                return [name, wallet];
            case "Clearer":
                return [name, wallet, dfdp, dfdpoe];
            case "Company":
                return [name];
            case "Currency":
                return [name, symbol, decimals];
            case "Custodian":
                return [name, wallet];
            case "Account":
                return [name, companySc];
            case "CCP":
                return [datetime, stringLing, name];
            default:
                return [];
        }
    }

    return (
        <Wrapper>
            <Inputs>
                <InputItem>
                    <InputAde 
                        style={inputStyle} 
                        placeholder={"Name..."} 
                        value={name} 
                        onChange={(evt) => setName(evt.target.value)}
                    />
                </InputItem>
                {type === "CCP" &&
                    <InputItem>
                        <InputAde 
                            style={inputStyle} 
                            placeholder={"Datetime SC..."} 
                            value={datetime} 
                            onChange={(evt) => setDatetime(evt.target.value)}
                        />
                    </InputItem>
                }
                {type === "CCP" &&
                    <InputItem>
                        <InputAde 
                            style={inputStyle} 
                            placeholder={"StringLing SC..."} 
                            value={stringLing} 
                            onChange={(evt) => setStringLing(evt.target.value)}
                        />
                    </InputItem>
                }
                {["Custodian", "Clearer", "Exchange"].includes(type) &&
                    <InputItem>
                        <InputAde 
                            style={inputStyle} 
                            placeholder={"Wallet..."} 
                            value={wallet} 
                            onChange={(evt) => setWallet(evt.target.value)}
                        />
                    </InputItem>
                }
                {type === "Currency" &&
                    <React.Fragment>
                        <InputItem>
                            <InputAde
                                style={inputStyle}
                                placeholder={"Symbol..."}
                                value={symbol}
                                onChange={(evt) => setSymbol(evt.target.value)}
                            />
                        </InputItem>
                        <InputItem>
                            <Cleave
                                className={"cleaveStyle"}
                                style={inputStyle}
                                placeholder={"Decimals..."}
                                value={decimals}
                                onChange={(evt) => setDecimals(evt.target.value)}
                                options={{numeral: true, numeralDecimalMark: '', delimeter: ''}}
                            />
                        </InputItem>
                    </React.Fragment>
                }
                {["Clearer"].includes(props.type) &&
                    <InputItem>
                        <DefaultSelect
                            label={"DFDP"}
                            onChange={(selected) => setDfdp(selected)}
                            formatValue={(e) => DFDP[e]}
                            list={Object.keys(DFDP)}
                        />
                    </InputItem>
                }
                {["Clearer"].includes(props.type) &&
                    <InputItem>
                        <DefaultSelect
                            label={"DFDPOE"}
                            onChange={(selected) => setDfdpoe(selected)}
                            formatValue={(e) => DFDPOE[e]}
                            list={Object.keys(DFDPOE)}
                        />
                    </InputItem>
                }
                {["Account"].includes(props.type) &&
                    <InputItem>
                        <DefaultSelect
                            label={"Company"}
                            onChange={(selected) => setCompanySc(selected.item)}
                            formatValue={e => e.name ? e.name : e.item}
                            list={companies}
                        />
                    </InputItem>
                }
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Deploy"} onClick={deploy} disabled={disabled}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { DeployRow };