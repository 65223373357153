import produce from 'immer';

const initialState = {
    "error": false,
    "data": null,
};

export default (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case "LOAD_ABI":
                draft.data = action.payload;
                return draft;
            case "SET_ABI_ERROR":
                draft.error = action.payload;
                return draft;
            default:
                return draft;
        }
    })
