import styled from 'styled-components';

export const ListRowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px 0px 5px;
    box-sizing: border-box;
`;

export const ListRow = styled.div`
    flex: 1;
    height: 25px;
    ${props => props.selected 
        ? "background-color: var(--bgLight);"
        : "background-color: rgba(0, 0, 0, " + (props.index % 2 === 0 ? 0.25 : 0.15) + ");"
    }
    color: ${props => props.selected ? "var(--textPrimary)" : "var(--textSecondary)"};
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        cursor: pointer;
        color: var(--textPrimary);
    }
`;