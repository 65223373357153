// import Exchange from './Exchange';
// import ClearerArrays from './Clearer';
// import CustodianArrays from './Custodian';
// import CompanyArrays from './Company';
// import AccountArrays from './Account';
// import CurrencyArrays from './Currency';
import SelectedEntity from './SelectedEntity';
import SelectedRowID from './SelectedRowID';
// import AddressBalance from './AddressBalance';
// import ContractID from './ContractID';
// import SettlementArrays from './Settlement';
// import ContractArrays from './Contract';
// import MarketArrays from './Market';
// import InitialMarginLog from './InitialMarginLog';

import AccountReducers from 'entities/Account/reducers';
import CustodianReducers from 'entities/Custodian/reducers';
import ClearerReducers from 'entities/Clearer/reducers';
import ExchangeReducers from 'entities/Exchange/reducers';
import CurrencyReducers from 'entities/Currency/reducers';
import CompanyReducers from 'entities/Company/reducers';
import SettlementReducers from 'entities/Settlement/reducers';
import ContractReducers from 'entities/Contract/reducers';
import MarketReducers from 'entities/Market/reducers';

export default {
    // exchangeList: Exchange,
    selectedEntity: SelectedEntity,
    selectedRowID: SelectedRowID,
    // addressCcyBalances: AddressBalance, 
    // contractIDList: ContractID,
    ...AccountReducers,
    ...CustodianReducers,
    ...ClearerReducers,
    ...ExchangeReducers,
    ...CurrencyReducers,
    ...CompanyReducers,
    ...SettlementReducers,
    ...ContractReducers,
    ...MarketReducers,
}