import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    height: ${props => props.fitContainerHeight ? "100%" : "auto"};
    width: 100%;
`;

export const Content = styled.div`
    width: 100%;
    position: absolute;
    z-index: 200;
    right: 0;
`;

export const FixedContent = styled.div`
    position: fixed;
    width: ${props => props.width ? props.width + "px" : "auto"};
`;