import styled, { keyframes } from 'styled-components';

const appear = keyframes`
    from {
        opacity: 0.75;
    }

    to {
        opacity: 1;
    }
`;

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

export const DetailViewRoot = styled.div`
    height: 100%;
    background-color: var(--bgStrong);
    animation: ${appear} 1s ease 1;
`;

export const DetailViewContent = styled.div`
    height: calc(100% - 45px);
    display: flex;
    flex-direction: column;
    padding: 5px;
    box-sizing: border-box;
`;

export const SpinIcon = styled.svg`
    animation: ${spin} 1s linear infinite;
`;