import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDetailViewValues } from 'hooks/detailView';
import { DetailViewRoot, DetailViewContent } from 'components/detail/styled';
import { PanelHeader } from 'components/core/basic';
import { DetailViewDataRow, DetailViewExpander } from 'components/detail';
import { ExchangeBalances } from './ExchangeBalances';
import { ClearerDetailViewData } from 'entities/Clearer';
import { AccountDetailViewData } from 'entities/Account';
import { FaFileInvoiceDollar, FaTape } from 'react-icons/fa';
import { F } from 'services';
import { AdjustInitialMarginRow, SetBufferFundRow } from 'entities/Exchange/inputs';

const DetailViewData = (props) => {
    const { data } = props;
    return (
        <React.Fragment>
            <DetailViewDataRow label={"Name"}>{data.name}</DetailViewDataRow>
            <DetailViewDataRow label={"Sc Address"}>{data.item}</DetailViewDataRow>
            <DetailViewDataRow label={"Wallet"}>{data.wallet}</DetailViewDataRow>
        </React.Fragment>
    )
}

export { DetailViewData };

const DetailView = () => {
    const { selectedEntity, selectedRowID, data } = useDetailViewValues("exchangeList");
    const privileges = useSelector(state => state.privileges);
    const clearers = useSelector(state => state.clearerList);
    const accounts = useSelector(state => state.accountList);
    const currencies = useSelector(state => state.currencyList);
    const [showSetDFC, setShowSetDFC] = useState(false);
    const [showAdjustIM, setShowAdjustIM] = useState(false);

    const headerButtons = () => {
        if(selectedRowID && privileges.selected.name === "CCP")
            return [
                {icon: FaFileInvoiceDollar, onClick: () => openShowSetDFC(), active: showSetDFC, disabled: showSetDFC, tooltip: "Set Buffer Fund Contribution"},
                {icon: FaTape, onClick: () => openShowAdjustIM(), active: showAdjustIM, disabled: showAdjustIM, tooltip: "Adjust Initial Margin"}
            ]
        return [];
    }

    const openShowSetDFC = () => {
        setShowSetDFC(true);
        setShowAdjustIM(false);
    }

    const openShowAdjustIM = () => {
        setShowAdjustIM(true);
        setShowSetDFC(false);
    }
    
    if(selectedEntity !== "Exchange")
        return null;
    return (
        <DetailViewRoot>
            <PanelHeader title={selectedRowID === null ? "No Row Selected" : ""} buttons={headerButtons()}/>
            {data &&
                <DetailViewContent>
                    {showSetDFC && <SetBufferFundRow exchange={data} close={() => setShowSetDFC(false)}/>}
                    {showAdjustIM && <AdjustInitialMarginRow exchange={data} close={() => setShowAdjustIM(false)}/>}
                    <DetailViewData data={data}/>
                    {data.contractIDList.map(e => {
                        const ccy = currencies.find(ccy => ccy.item === e.currencySc)
                        return (
                            <DetailViewDataRow key={e.id + e.currencySc} label={e.id}>
                                {`${F.currency(e.amount, ccy.decimals)} ${ccy.name}`}
                            </DetailViewDataRow>
                        )
                    })}
                    {data.clearers.length > 0 &&
                        <DetailViewExpander label={"Registered Clearers"}>
                            {data.clearers.map(e => (
                                    <DetailViewExpander key={e} reverse label={clearers.find(c => c.item === e).name}>
                                        <ClearerDetailViewData data={clearers.find(c => c.item === e)}/>
                                    </DetailViewExpander>
                            ))}
                        </DetailViewExpander>
                    }
                    {data.accounts.length > 0 &&
                        <DetailViewExpander label={"Registered Accounts"}>
                            {data.accounts.map(e => (
                                    <DetailViewExpander key={e} reverse label={accounts.find(c => c.item === e).name}>
                                        <AccountDetailViewData data={accounts.find(c => c.item === e)}/>
                                    </DetailViewExpander>
                            ))}
                        </DetailViewExpander>
                    }
                    <ExchangeBalances exchange={data}/>
                </DetailViewContent>
            }
        </DetailViewRoot>
    )
}

export { DetailView };