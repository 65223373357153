import React from 'react';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { DepositFromDefaultFund } from 'entities/CCP/transactions';
import Cleave from 'cleave.js/react';
import { useValidation } from 'hooks/core';
import { isNil } from 'lodash';

const DepositBufferFundRow = (props) => {
    const { dispatch, web3Instance, cleave, setCleave, amount, setAmount } = useDefaultInputValues();
    const { ccy, supply } = props;
    const [disabled, errorText] = useValidation([
        {
            validation: () => amount === "",
            errorText: "You can't deposit 0 amount",
        },
        {
            validation: () => isNil(supply),
            errorText: "You have no funds in Balance",
        },
        {
            validation: () => supply < cleave.getRawValue() * Math.pow(10, ccy.decimals),
            errorText: "You can't deposit more than you have in Balance"
        }
    ])

    const deposit = () => {
        if(disabled) return;
        dispatch(DepositFromDefaultFund(
            ccy.item,
            cleave.getRawValue() * Math.pow(10, ccy.decimals),
            web3Instance,
        ))
        props.close();
    }

    return (
        <Wrapper>
            <Inputs>
                <InputItem width={"100%"}>
                    <Cleave onInit={(cleave) => setCleave(cleave)} 
                        className={"cleaveStyle"} style={{height: "25px", width: "100%"}} placeholder={"Amount..."} onChange={(e) => setAmount(e.target.value)} 
                        options={{numeral: true, numeralDecimalScale: ccy.decimals}}
                    />
                </InputItem>
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Deposit"} disabled={disabled} onClick={deposit}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { DepositBufferFundRow };