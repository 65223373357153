import { populateNodeAndDispatch, populateNodeProps } from 'services/EventHandlingHelpers';

export const AddExchange = (evt, web3) => {
    return async (dispatch, getState) => {
        let node = {};
        let props = populateNodeProps().setEvent(evt).setWeb3(web3).setDispatch(dispatch).setGetState(getState).setNode(node)
            .setAbiName("Exchange").setActionType("ADD_EXCHANGE").setAsyncActionType("LOAD_EXCHANGE_ASYNC_VALUES")
            .build()
        populateNodeAndDispatch(props);
    }
}

export const RemoveExchange = (evt, web3) => {
    return async (dispatch, getState) => {
        dispatch({ type: "REMOVE_EXCHANGE", payload: evt.returnValues._item });
    }
}

export const ExchangeRegisterClearer = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "EXCHANGE_REGISTER_CLEARER", payload: {clearerSc: _item, exchangeSc: _node} });
        dispatch({ type: "ADD_CLEARER_TO_EXCHANGE_LIST", payload: {clearerSc: _item, exchangeSc: _node} });
    }
}

export const ExchangeDeregisterClearer = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "EXCHANGE_DEREGISTER_CLEARER", payload: {clearerSc: _item, exchangeSc: _node} });
        dispatch({ type: "REMOVE_CLEARER_FROM_EXCHANGE_LIST", payload: {clearerSc: _item, exchangeSc: _node} });
    }
}

export const ExchangeRegisterAccount = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "ADD_EXCHANGE_TO_ACCOUNT_LIST", payload: {accountSc: _item, exchangeSc: _node} });
        dispatch({ type: "ADD_ACCOUNT_TO_EXCHANGE_LIST", payload: {accountSc: _item, exchangeSc: _node} });
    }
}

export const ExchangeDeregisterAccount = (evt, web3) => {
    return (dispatch, getState) => {
        const { _item, _node } = evt.returnValues;
        dispatch({ type: "REMOVE_EXCHANGE_FROM_ACCOUNT_LIST", payload: {accountSc: _item, exchangeSc: _node} });
        dispatch({ type: "REMOVE_ACCOUNT_FROM_EXCHANGE_LIST", payload: {accountSc: _item, exchangeSc: _node} });
    }
}

export const ExchangeSetDefaultFundContribution = (evt, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const { _currency, _node, _amount, _contractID } = evt.returnValues;
        const clearerScList = state.clearerList.map(e => e.item);
        dispatch({ type: "EXCHANGE_SET_DFC", payload: {
            currencySc: _currency, exchangeSc: _node, amount: _amount, contractID: _contractID, clearerScList: clearerScList} });
    }
}

export const exchangeEvents = {
    "CCP_addExchange": AddExchange,
    "CCP_removeExchange": RemoveExchange,
    "Exchange_registerClearer": ExchangeRegisterClearer,
    "Exchange_deregisterClearer": ExchangeDeregisterClearer,
    "Exchange_registerAccount": ExchangeRegisterAccount,
    "Exchange_deregisterAccount": ExchangeDeregisterAccount,
    "Exchange_set_DefaultFundContribution": ExchangeSetDefaultFundContribution,
}