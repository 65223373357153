import React, { useRef, useEffect } from 'react';
import { HomeContentRoot } from '../styled';
import { AutoSizer } from 'react-virtualized';
import SimpleBar from 'simplebar-react';
import { useDimensions } from 'hooks/core';

function simplebarWrapperGetter(ref) {
    if(ref.current) {
        // console.log(ref.current);
        return { current: ref.current.contentWrapperEl };
    }
    return ref;
}

function simplebarContentGetter(ref) {
    if(ref.current) {
        return { current: ref.current.contentEl };
    }
    return ref;
}

const HomeContent = (props) => {
    const simpleBarRef = useRef();
    // const wrapper = useDimensions(simplebarWrapperGetter(simpleBarRef));
    const dimensions = useDimensions(simplebarContentGetter(simpleBarRef));
    
    return (
        <HomeContentRoot>
            <AutoSizer>
            {({ width, height }) => (
                <SimpleBar ref={simpleBarRef} autoHide={true} 
                    style={{ width: width, height: height, paddingRight: dimensions.height > height ? "12px" : "0px", boxSizing: "border-box" }}>
                    <div style={props.innerStyle}>{props.children}</div>
                </SimpleBar>
            )}
             </AutoSizer>
        </HomeContentRoot>
    )
}

export default HomeContent;