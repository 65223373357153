import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    border-radius: 2px;
    background-color: ${props => props.bg ?? "rgba(0, 0, 0, 0.25)"};
    margin-bottom: ${props => props.noMargin ? "0px" : "5px"};
    padding: 0px 5px 0px 5px;
    box-sizing: border-box;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;  
    font-size: 14px;
`;

export const Inputs = styled.div`
    width: calc(100% - ${props => props.offsetRight ?? "0"}px);
    height: 25px;
    display: flex;
    flex-direction: row;
    padding-right: 5px;
`;

export const InputItem = styled.div`
    width: ${props => props.width ?? "auto"};
    ${props => props.width ? "" : "flex: 1;"}
    &:not(:last-child) {
        margin-right: 5px;
    }
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const InputItem2x0 = styled.div`
    flex: ${props => props.flex ?? "1"};
    &:not(:last-child) {
        margin-right: 5px;
    }
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
`;