import React, { useRef } from 'react';
import { Spinner, Tooltip } from 'components/core/basic';
import { Column, ColumnText } from './styled';
import { useWidthOverflown } from 'hooks/core';
import moment from 'moment';

const ListColumn = (props) => {
    const {children, ...other} = props;
    const contentRef = useRef();
    const isOverflown = useWidthOverflown(contentRef);

    const formatChildren = () => {
        if(props.timestamp)
            return moment(children * 1000).format("YYYY-MM-DD hh:mm:ss");
        return children;
    }
    
    return (
        <Column {...other}>
            <Tooltip content={formatChildren()} disabled={isOverflown === false}>
                <ColumnText ref={contentRef}>
                    {children !== undefined ? formatChildren() : <Spinner height={10} width={10} borderSize={5}/>}
                </ColumnText>
            </Tooltip>
        </Column>
    )
}

export { ListColumn };