import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ADD_ACCOUNT":
                draft.push({...action.payload, ccyBalances: [], withdrawals: [], settlements: [], clearers: [], exchanges: []});
                return draft;
            case "LOAD_ACCOUNT_ASYNC_VALUES":
                const updateIndex = draft.findIndex(e => e.item === action.payload.item);
                draft[updateIndex] = {...draft[updateIndex], ...action.payload};
                return draft;
            case "ADD_ACCOUNT_BALANCE":
                {
                    const { accountSc, amount, currencySc } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === accountSc);
                    const balanceIndex = draft[updateIndex].ccyBalances.findIndex(e => e.currencySc === currencySc);
                    if(balanceIndex === -1)
                        draft[updateIndex].ccyBalances.push({
                            currencySc: currencySc,
                            amount: parseInt(amount),
                            excessEquity: 0,
                            openTradeEquity: 0,
                            realizedPnL: 0,
                            marginBalance: 0,
                        });
                    else
                        draft[updateIndex].ccyBalances[balanceIndex].amount += parseInt(amount)
                }
                return draft;
            case "REMOVE_ACCOUNT_BALANCE":
                {
                    const { accountSc, amount, currencySc } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === accountSc);
                    const balanceIndex = draft[updateIndex].ccyBalances.findIndex(e => e.currencySc === currencySc);
                    draft[updateIndex].ccyBalances[balanceIndex].amount -= parseInt(amount);
                }
                return draft;
            case "ACCOUNT_SETTLEMENT_UPDATE":
                {
                    const { accountSc, currencySc } = action.payload;
                    const { _excessEquity, _initialMarginRequirement, _maintenanceMarginRequirement,
                         _openTradeEquity, _realized_pnl, _varianceMarginRequirement } = action.payload.settlementValues;
                    const updateIndex = draft.findIndex(e => e.item === accountSc);
                    const balanceIndex = draft[updateIndex].ccyBalances.findIndex(e => e.currencySc === currencySc);
                    if(balanceIndex !== -1) {
                        draft[updateIndex].ccyBalances[balanceIndex].excessEquity = _excessEquity;
                        draft[updateIndex].ccyBalances[balanceIndex].openTradeEquity = _openTradeEquity;
                        draft[updateIndex].ccyBalances[balanceIndex].realizedPnL = _realized_pnl;
                        draft[updateIndex].ccyBalances[balanceIndex].marginBalance = 
                            parseInt(_initialMarginRequirement) + parseInt(_maintenanceMarginRequirement) + parseInt(_varianceMarginRequirement);
                    }
                }
                return draft;
            case "ACCOUNT_WITHDRAWAL_REQUESTED":
                {
                    const { accountSc, id, amount, currencySc } = action.payload;
                    const accountIndex = draft.findIndex(e => e.item === accountSc);
                    draft[accountIndex].withdrawals.push({
                        id: id,
                        status: "PENDING",
                        amount: amount,
                        currencySc: currencySc,
                    });
                }
                return draft;
            case "ACCOUNT_WITHDRAWAL_CANCELLED":
                {
                    const { accountSc, id } = action.payload;
                    const accountIndex = draft.findIndex(e => e.item === accountSc);
                    const withdrawalIndex = draft[accountIndex].withdrawals.findIndex(e => e.id === id);
                    draft[accountIndex].withdrawals[withdrawalIndex].status = "CANCELLED";
                }
                return draft;
            case "ACCOUNT_WITHDRAWAL_PROCESSED":
                {
                    const { accountSc, id } = action.payload;
                    const accountIndex = draft.findIndex(e => e.item === accountSc);
                    const withdrawalIndex = draft[accountIndex].withdrawals.findIndex(e => e.id === id);
                    draft[accountIndex].withdrawals[withdrawalIndex].status = "PROCESSED";
                }
                return draft;
            case "ACCOUNT_ADD_PHYSICAL_SETTLEMENT":
                {
                    const { sender, settlementID, exchange, currency, counterparty, amount, canBeSatisfied } = action.payload;
                    const [senderIndex, counterpartyIndex] = [draft.findIndex(e => e.item === sender), draft.findIndex(e => e.item === counterparty)];
                    draft[senderIndex].settlements.push({
                        amount: amount,
                        to: counterparty,
                        currencySc: currency,
                        processed: false,
                        settlementID: settlementID,
                        canBeSatisfied: canBeSatisfied,
                        exchangeSc: exchange,
                        rejected: false,
                    });
                    draft[counterpartyIndex].settlements.push({
                        amount: amount,
                        from: sender,
                        currencySc: currency,
                        processed: false,
                        settlementID: settlementID,
                        canBeSatisfied: canBeSatisfied,
                        exchangeSc: exchange,
                        rejected: false,
                    });
                }
                return draft;
            case "ACCOUNT_PHYSICAL_SETTLEMENT_PROCESSED":
                {
                    const { sender, settlementID, exchange, currency, counterparty, amount } = action.payload;
                    const [senderIndex, counterpartyIndex] = [draft.findIndex(e => e.item === sender), draft.findIndex(e => e.item === counterparty)];
                    const [senderIDIndex, counterpartyIDIndex] = [
                        draft[senderIndex].settlements.findIndex(e => e.settlementID === settlementID),
                        draft[counterpartyIndex].settlements.findIndex(e => e.settlementID === settlementID)
                    ]
                    if(senderIDIndex + counterpartyIDIndex === -2)
                        return draft;
                    draft[senderIndex].settlements[senderIDIndex].processed = true;
                    draft[counterpartyIndex].settlements[counterpartyIDIndex].processed = true;
                }
                return draft;
            case "ACCOUNT_REJECT_PHYSICAL_SETTLEMENT":
                {
                    const { sender, settlementID, exchange, currency, counterparty, amount } = action.payload;
                    const [senderIndex, counterpartyIndex] = [draft.findIndex(e => e.item === sender), draft.findIndex(e => e.item === counterparty)];
                    const [senderIDIndex, counterpartyIDIndex] = [
                        draft[senderIndex].settlements.findIndex(e => e.settlementID === settlementID),
                        draft[counterpartyIndex].settlements.findIndex(e => e.settlementID === settlementID)
                    ]
                    if(senderIDIndex + counterpartyIDIndex === -2)
                        return draft;
                    draft[senderIndex].settlements[senderIDIndex].rejected = true;
                    draft[counterpartyIndex].settlements[counterpartyIDIndex].rejected = true;
                }  
                return draft;
            case "ADD_CLEARER_TO_ACCOUNT_LIST":
                {
                    const { clearerSc, accountSc } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === accountSc);
                    draft[updateIndex].clearers.push(clearerSc);
                }
                return draft;
            case "ADD_EXCHANGE_TO_ACCOUNT_LIST":
                {
                    const { accountSc, exchangeSc } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === accountSc);
                    draft[updateIndex].exchanges.push(exchangeSc);
                }
                return draft;
            case "REMOVE_EXCHANGE_FROM_ACCOUNT_LIST":
                {
                    const { accountSc, exchangeSc } = action.payload;
                    const removeIndex = draft.findIndex(e => e.item === accountSc);
                    draft[removeIndex].exchanges.splice(draft[removeIndex].exchanges.findIndex(e => e === exchangeSc), 1);
                }
                return draft;
            default:
                return draft;
        }
    })
