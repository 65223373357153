import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListViewRoot, ListContent, ListHeaderRow } from 'components/list/styled';
import { ListColumn, ListWrapper, RowRenderer, useListViewValues } from 'components/list';
import { DeployRow } from 'entities/Contract/inputs';
import { PanelHeader } from 'components/core/basic';
import { FaPlus } from 'react-icons/fa';

const ListView = () => {
    const { selectedEntity, list } = useListViewValues("contractList");
    const [filteredList, setFilteredList] = useState([]);
    const [showDeploy, setShowDeploy] = useState(false);
    const privileges = useSelector(state => state.privileges);
    const exchanges = useSelector(state => state.exchangeList);
    const markets = useSelector(state => state.marketList);

    useEffect(() => {
        setFilteredList(
            privileges.selected.name === "CCP" ? list : list.filter(e => e.exchange === privileges.selected.scAddress)
        )
    }, [list, privileges])

    const exchangeName = (exchangeSc) => exchanges.find(e => e.item === exchangeSc)?.name

    const rowRenderer = ({ key, index, style }) => (
        <RowRenderer key={key} index={index} style={style} item={filteredList[index].item}>
            <ListColumn>{filteredList[index].symbol}</ListColumn>
            <ListColumn timestamp>{filteredList[index].timestamp}</ListColumn>
            <ListColumn>{exchangeName(filteredList[index].exchange)}</ListColumn>
            <ListColumn>{markets.filter(e => e.contract === filteredList[index].item).length}</ListColumn>
        </RowRenderer>
    )

    const headerButtons = () => {
        if(privileges.selected.name === "Exchange")
            return[
                {icon: FaPlus, onClick: () => setShowDeploy(true), active: showDeploy, disabled: showDeploy, tooltip: "Deploy Contract"},
            ]
    }

    if(selectedEntity !== "Contract")
        return null;
    return (
        <ListViewRoot>
            <PanelHeader title={"Contracts"} buttons={headerButtons()}/>
            {showDeploy && <DeployRow close={() => setShowDeploy(false)}/>}
            {filteredList.length > 0 && (
                <ListHeaderRow>
                    <ListColumn head>Symbol</ListColumn>
                    <ListColumn head>Timestamp</ListColumn>
                    <ListColumn head>Exchange</ListColumn>
                    <ListColumn head>Num. of Markets</ListColumn>
                </ListHeaderRow>
            )}
            <ListContent headerVisible={filteredList.length > 0}>
                <ListWrapper length={filteredList.length} rowRenderer={rowRenderer}/>
            </ListContent>
        </ListViewRoot>
    )
}

export { ListView };