import produce from 'immer';

export default (state = [], action) => 
    produce(state, draft => {
        switch(action.type) {
            case "ADD_CUSTODIAN":
                draft.push({...action.payload, clearers: [], totalMint: [], totalBurn: []});
                return draft;
             case "LOAD_CUSTODIAN_ASYNC_VALUES":
                const updateIndex = draft.findIndex(e => e.item === action.payload.item);
                draft[updateIndex] = {...draft[updateIndex], ...action.payload};
                return draft;
            case "REMOVE_CUSTODIAN":
                const removeIndex = draft.findIndex(e => e.item === action.payload);
                draft.splice(removeIndex, 1);
                return draft;
            case "ADD_CLEARER_TO_CUSTODIAN_LIST":
                {
                    const { clearerSc, custodianSc } = action.payload;
                    const addIndex = draft.findIndex(e => e.item === custodianSc);
                    draft[addIndex].clearers.push(clearerSc);
                }
                return draft;
            case "REMOVE_CLEARER_FROM_CUSTODIAN_LIST":
                {
                    const { clearerSc, custodianSc } = action.payload;
                    const removeIndex = draft.findIndex(e => e.item === custodianSc);
                    draft[removeIndex].clearers.splice(draft[removeIndex].clearers.findIndex(e => e === clearerSc), 1);
                }
                return draft;
            case "CUSTODIAN_UPDATE_TOTAL_MINT":
                {
                    const { custodianSc, delta, currencySc } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === custodianSc);
                    const mintIndex = draft[updateIndex].totalMint.findIndex(e => e.currencySc === currencySc);
                    if(mintIndex === -1) {
                        draft[updateIndex].totalMint.push({
                            currencySc: currencySc,
                            amount: parseInt(delta),
                        });
                    }
                    else
                        draft[updateIndex].totalMint[mintIndex].amount += parseInt(delta);
                }
                return draft;
            case "CUSTODIAN_UPDATE_TOTAL_BURN":
                {
                    const { custodianSc, delta, currencySc } = action.payload;
                    const updateIndex = draft.findIndex(e => e.item === custodianSc);
                    const burnIndex = draft[updateIndex].totalBurn.findIndex(e => e.currencySc === currencySc);
                    if(burnIndex === -1) {
                        draft[updateIndex].totalBurn.push({
                            currencySc: currencySc,
                            amount: parseInt(delta),
                        });
                    }
                    else
                        draft[updateIndex].totalBurn[burnIndex].amount += parseInt(delta);
                }
                return draft;
            default:
                return draft;
        }
    })
