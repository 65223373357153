import { isNil } from 'lodash';
import moment from 'moment';

export default class F {
    static currency(value, decimals) {
        if(isNil(value)) value = 0;
        let negative = false;
        if(value < 0) { 
            value = Math.abs(value);
            negative = true;
        }
        const splitIndex = value.toString().length - decimals;
        if(splitIndex < 1) {
            let result = "0.";
            for(let i = 0; i < Math.abs(splitIndex); i++) {
                result += "0";
            }
            return result + value.toString();
        }
        const [int, dec] = [value.toString().slice(0, splitIndex), value.toString().slice(splitIndex)];
        let intWithComma = "";
        [...int].forEach((e, idx) => {
            intWithComma += e;
            if(int.length === idx + 1) return;
            if((idx + 1) % 3 - (int.length % 3) === 0 ) intWithComma += ",";
        });
        const minus = negative ? "-" : "";
        return `${minus}${intWithComma}.${dec}`;
    }

    static timestamp(value) {
        if(isNil(value)) return value;
        return moment(value * 1000).format("YYYY-MM-DD hh:mm:ss");
    }

    static clearerOptionParams(key) {
        const optionList = {
            2: "OOIN",
            3: "OMP",
            4: "LMP",
        }
        return optionList[key];
    }

    static clearerOptionParamsFull(key) {
        const optionList = {
            2: "Overnight Open Interest Notional",
            3: "Overnight Margin Position",
            4: "Largest Margin Position",
        }
        return optionList[key];
    }
}