import React from 'react';
import { Wrapper, Data } from 'components/detail/basic/styled';
import { Blink } from './styled';
import { Spinner, Tooltip } from 'components/core/basic';
import { useSingleEntity } from 'hooks/core';
import { F } from 'services';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';

const Loader = () => <Spinner width={10} height={10} borderSize={5}/>

const SettlementRow = (props) => {
    const { amount, from, to, currencySc, processed, canBeSatisfied } = props.data;
    const thisAccount = useSingleEntity("accountList", from ?? to);
    const thisCompany = useSingleEntity("companyList", thisAccount?.company);
    const thisCcy = useSingleEntity("currencyList", currencySc);

    return (
        <Wrapper bgColor={processed ? "#52e02b4b" : "#0c3dc44b"} as={canBeSatisfied ? null : Blink}>
            <Data>
                {from ? <FaLongArrowAltLeft/> : <FaLongArrowAltRight/>}&nbsp;
                <React.Fragment>{F.currency(amount, thisCcy?.decimals)}</React.Fragment>&nbsp;
                <React.Fragment>{thisCcy?.symbol ?? <Loader/>}</React.Fragment>&nbsp;
                {from ? "from" : "to"}&nbsp;
                <Tooltip content={thisCompany?.name}>
                    <span><React.Fragment>{thisAccount?.name ?? <Loader/>}</React.Fragment></span>
                </Tooltip>&nbsp;
                {"(" + (processed ? "Processed" : "Pending") + ")"}
            </Data>
        </Wrapper>
    )
}

export { SettlementRow };