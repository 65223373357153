import { useState } from 'react';
import { useSelector } from 'react-redux';

const useListViewValues = (listName) => {
    const selectedEntity = useSelector(state => state.selectedEntity);
    const list = useSelector(state => state[listName]);
    const privileges = useSelector(state => state.privileges);
    const [showDeploy, setShowDeploy] = useState(false);

    return {
        selectedEntity,
        list,
        privileges,
        showDeploy,
        setShowDeploy,
    }
}

export { useListViewValues };