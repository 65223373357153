import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.25);
    margin-bottom: ${props => props.noMargin ? "0px" : "5px"};
    padding: 0px 5px 0px 5px;
    box-sizing: border-box;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;  
    font-size: 14px;
`;

export const Inputs = styled.div`
    width: 100%;
    height: 25px;
    display: flex;
    flex-direction: row;
    padding-right: 5px;
`;

export const InputItem = styled.div`
    width: ${props => props.width ?? "auto"};
    ${props => props.width ? "" : "flex: 1;"}
    &:not(:last-child) {
        margin-right: 5px;
    }
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const CheckButton = styled.div`
    padding: 0px 5px 0px 5px;
    background-color: var(--bgLight);
    color: var(--textPrimary);
    &:hover {
        background-color: #188500;
    }
    margin-auto;
    margin-right: 5px;
    height: 25px;
`;

export const CloseButton = styled.div`
    padding: 0px 5px 0px 5px;
    background-color: var(--bgLight);
    color: var(--textPrimary);
    &:hover {
        background-color: #e11060;
    }
    margin-auto;
    height: 25px;
`;