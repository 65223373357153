import styled from 'styled-components';
import Tippy from '@tippyjs/react';

export const StyledTooltip = styled(Tippy)`
    background: ${props => props.bg ?? "black"};
    box-shadow: 0px 2px 10px var(--bgLight);

    &[data-placement^='top'] > .tippy-arrow::before {
        border-top-color: ${props => props.bg ?? "black"};
      }
    
    &[data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color: ${props => props.bg ?? "black"};
      }
`;