import styled from 'styled-components';

export const HomeRoot = styled.div`
    height: 100%;
`;

export const HomeRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

export const ContentCol = styled.div`
    width: ${props => props.width ?? "50%"};
    max-width: ${props => props.width ?? "50%"};
    &:not(:last-child) {
        margin-right: 5px;
    }
`;

export const HomeColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 50%;
    &:not(:last-child) {
        margin-right: 5px;
    }
`;

export const HomeDataRowWrapper = styled.div`
    flex: ${props => props.flex ? props.flex : 1};
    width: ${props => props.width};
    max-width: ${props => props.width};
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    &:not(:last-child) {
        margin-right: ${props => props.noMarginRight ? "0px" : "5px"};
    }
`;