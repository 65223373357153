export const SetCcpAddress = (data) => {
    return {
        type: "SET_CCP_ADDRESS",
        payload: data,
    }
}

export const SetPrimaryKey = (data) => {
    return {
        type: "SET_PK",
        payload: data,
    }
}

export const AddNewProvider = (data) => {
    return {
        type: "ADD_NEW_PROVIDER",
        payload: data,
    }
}

export const SelectProvider = (data) => {
    return {
        type: "SELECT_PROVIDER",
        payload: data,
    }
}

export const DeleteProvider = (data) => {
    return {
        type: "DELETE_PROVIDER",
        payload: data,
    }
}

export const EditProvider = (data) => {
    return {
        type: "EDIT_PROVIDER",
        payload: data,
    }
}