import React from 'react';
import { useSelector } from 'react-redux';
import { useDetailViewValues } from 'hooks/detailView';
import { DetailViewRoot, DetailViewContent } from 'components/detail/styled';
import { PanelHeader } from 'components/core/basic';
import { DetailViewDataRow } from 'components/detail';
import { useSingleEntity } from 'hooks/core';
import { F } from 'services';

const DetailView = () => {
    const { selectedEntity, selectedRowID, data } = useDetailViewValues("settlementList", "settlementID");
    const accounts = useSelector(state => state.accountList);
    const thisCurrency = useSingleEntity("currencyList", data?.currency);
    const exchanges = useSelector(state => state.exchangeList);

    if(selectedEntity !== "Settlement")
        return null;
    return (
        <DetailViewRoot>
            <PanelHeader title={selectedRowID === null ? "No Row Selected" : ""}/>
            {data &&
                <DetailViewContent>
                    <DetailViewDataRow label={"Sender"}>{accounts.find(x => x.item === data.sender)?.name}</DetailViewDataRow>
                    <DetailViewDataRow label={"Counterparty"}>{accounts.find(x => x.item === data.counterparty)?.name}</DetailViewDataRow>
                    <DetailViewDataRow label={"Exchange"}>{exchanges.find(x => x.item === data.exchange)?.name}</DetailViewDataRow>
                    <DetailViewDataRow label={"SettlementID"}>{data.settlementID}</DetailViewDataRow>
                    <DetailViewDataRow label={"Currency"}>{thisCurrency?.symbol}</DetailViewDataRow>
                    <DetailViewDataRow label={"Amount"}>{F.currency(data.amount, thisCurrency?.decimals)}</DetailViewDataRow>
                    <DetailViewDataRow label={"Can be Satisfied"}>{data.canBeSatisfied}</DetailViewDataRow>
                    <DetailViewDataRow label={"Status"}>{data.status}</DetailViewDataRow>
                </DetailViewContent>
            }
        </DetailViewRoot>
    )
}

export { DetailView };