import React, { useState } from 'react';
import { Wrapper, Label, Data, Button, Nested } from '../styled';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

const DetailViewExpander = (props) => {
    const [expanded, setExpanded] = useState(false);
    const { depth } = props;

    const Expander = () => expanded ? <Button as={FaAngleUp} onClick={() => setExpanded(false)}/> : <Button as={FaAngleDown} onClick={() => setExpanded(true)}/>

    return (
        <React.Fragment>
            <Wrapper bgColor={"#4A5553"}>
                <Label>
                    {!props.reverse && <Expander/>}
                </Label>
                <Data bold>{props.label}</Data>
                {props.reverse ? <Expander/> : <Button></Button>}
            </Wrapper>
            {expanded && <Nested depth={depth ? depth : 0}>{props.children}</Nested>}
        </React.Fragment>
    )
}

export { DetailViewExpander };