import React, { useContext, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { Container, Slider, Data, Info, Label, Value, Title, SliderArrow } from './styled';
import { Web3Context } from 'context/Web3Context';

const InfoPanel = () => {
    const { web3Instance } = useContext(Web3Context);
    const { ccpAddress, accounts, callbacks, network, gasPrice, privileges } = useSelector(state => ({
        ccpAddress: state.ccpAddress,
        accounts: state.accounts,
        callbacks: state.callbacks,
        network: state.network,
        gasPrice: state.gasPrice,
        privileges: state.privileges,
    }), shallowEqual);
    const [expanded, setExpanded] = useState(false);

    return(
        <Container>
            <Slider onClick={() => setExpanded(e => !e)}>
                <SliderArrow as={expanded ? FaAngleDoubleRight : FaAngleDoubleLeft}/>
            </Slider>
            {expanded && 
            (<Data>
                <Title>Info Panel</Title>
                <Info>
                    <Label>CCP:</Label>
                    <Value>{ccpAddress}</Value>
                </Info>
                {privileges.selected.name !== "CCP" &&
                    <Info>
                        <Label>Sc Address:</Label>
                        <Value>{privileges.selected.scAddress}</Value>
                    </Info>
                }
                <Info>
                    <Label>Address(Account):</Label>
                    <Value>{accounts.selected.address}</Value>
                </Info>
                <Info>
                    <Label>Balance(ETH):</Label>
                    <Value>{web3Instance.utils.fromWei(accounts.balance)}</Value>
                </Info>
                <Info>
                    <Label>Callbacks:</Label>
                    <Value>{callbacks}</Value>
                </Info>
                <Info>
                    <Label>Network:</Label>
                    <Value>{network}</Value>
                </Info>
                <Info>
                    <Label>Gas Price:</Label>
                    <Value>{gasPrice}</Value>
                </Info>
            </Data>)
            }
        </Container>
    )
}

export default InfoPanel;