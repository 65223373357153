import styled from 'styled-components';

export const PanelHeader = styled.div`
    position: relative;
    z-index: 2;
    background-color: var(--bgLight);
    box-shadow: 0px 2px 10px var(--bgMedium);
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: bold;
    color: var(--textPrimary);
`;

export const HeaderToolbox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ToolboxItem = styled.div`
    &:not(:first-child) {
        margin-left: 10px;
    }
`;

export const ButtonDefaultWrapper = styled(ToolboxItem)`
    padding: 7px;
    color: var(--textPrimary);
    background-color: ${props => props.active ? "var(--contrast2)" : "transparent"};
    $:hover {
        color: var(--textPrimary);
    }
    box-shadow: 2px 2px 5px var(--bgMedium);
`;