import styled, { keyframes } from 'styled-components';

const expandSlider = keyframes`
  from {
    max-width: 0px;
  }

  to {
    max-width: 500px;
  }
`;

export const Container = styled.div`
    height: 100%;
    background-color: var(--bgStrong);
    color: var(--textSecondary);
    display: flex;
    flex-direction: row;
    font-size: 12px;
`;

export const Info = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;
`;

export const Label = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

export const Value = styled.div`
    color: var(--textPrimary);
`;

export const Data = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    animation: ${expandSlider} 0.7s ease 1;
    overflow: hidden;
`;

export const Slider = styled.div`
    width: 25px;
    background-color: var(--bgStrong);
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--bgDark);
    &:hover {
        cursor: pointer;
        background-color: var(--bgLight);
    }
`;

export const SliderArrow = styled.div`
    
`;

export const Title = styled.div`
    color: var(--textPrimary);
    background-color: var(--bgLight);
    font-size: 18px;
    font-weight: bold;
    align-self: center;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid var(--bgDark);
    width: 100%;
    margin-bottom: 10px;
`;