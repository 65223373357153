import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm, Close } from 'components/inputs';
import { Inputs, InputItem, Wrapper } from 'components/inputs/styled';
import { InputAde } from 'components/core/basic';
import { useValidation } from 'hooks/core';
import { Deploy } from 'redux/reducers/UserActions/actions';
import { Web3Context } from 'context/Web3Context';
import { DefaultSelect } from 'components/core/selectors';
import Cleave from 'cleave.js/react';

const inputStyle = {
    height: "25px",
    width: "100%",
}

const formatType = (type) => {
    const values = {
        0: "Spot",
        1: "Spot perpetual",
        2: "Futures",
        3: "Options",
        4: "IRS(Interest Rate Swap)",
    }
    return values[type];
}

const types = [{v: 0} ,{v: 1} ,{v: 2} ,{v: 3} ,{v: 4}];

const DeployRow = (props) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [nameCustom, setNameCustom] = useState("");
    const [type, setType] = useState(null);
    const [lastTradingDate, setLastTradingDate] = useState("");
    const [initialMargin, setInitialMargin] = useState("");
    const [openInterest, setOpenInterest] = useState("");
    const [selectedContract, setSelectedContract] = useState({});
    const contracts = useSelector(state => state.contractList);
    const scAddress = useSelector(state => state.privileges.selected.scAddress);
    const { web3Instance } = useContext(Web3Context);
    const [disabled, errorText] = useValidation([
        {
            validation: () => name === "",
            errorText: "Name can't be empty",
        },
        {
            validation: () => nameCustom === "",
            errorText: "Name Custom can't be empty",
        },
        {
            validation: () => lastTradingDate === "",
            errorText: "Last Trading Date can't be empty",
        },
        {
            validation: () => initialMargin === "",
            errorText: "Initial Margin can't be empty",
        },
        {
            validation: () => openInterest === "",
            errorText: "Open Interest can't be empty",
        },
        {
            validation: () => contracts.length === 0,
            errorText: "No available contracts",
        }
    ]);

    useEffect(() => {
        if(initialMargin > 100) setInitialMargin(100);
    }, [initialMargin])

    const deploy = () => {
        if(disabled) return;
        dispatch(Deploy(
            "Market",
            [name, nameCustom, type, lastTradingDate, initialMargin, openInterest, selectedContract.symbol],
            web3Instance,
        ));
        props.close();
    }

    return (
        <Wrapper>
            <Inputs>
                <InputItem width={"14.28%"}>
                    <InputAde 
                        style={inputStyle} 
                        placeholder={"Name..."} 
                        value={name} 
                        onChange={evt => setName(evt.target.value)}
                    />
                </InputItem>
                <InputItem width={"14.28%"}>
                    <InputAde 
                        style={inputStyle} 
                        placeholder={"Name Custom..."} 
                        value={nameCustom} 
                        onChange={evt => setNameCustom(evt.target.value)}
                    />
                </InputItem>
                <InputItem width={"14.28%"}>
                    <DefaultSelect
                        label={"Type"}
                        onChange={selected => setType(selected.v)}
                        formatValue={e => formatType(e.v)}
                        list={types}
                    />
                </InputItem>
                <InputItem width={"14.28%"}>
                    <Cleave
                        className={"cleaveStyle"}
                        style={inputStyle}
                        placeholder={"Last Trd. Date..."}
                        value={lastTradingDate}
                        onChange={evt => setLastTradingDate(evt.target.value)}
                        options={{numericOnly: true}}
                    />
                </InputItem>
                <InputItem width={"14.28%"}>
                    <Cleave
                        className={"cleaveStyle"}
                        style={inputStyle}
                        placeholder={"Init. Margin..."}
                        value={initialMargin}
                        onChange={evt => setInitialMargin(evt.target.value)}
                        options={{numericOnly: true, numeralPositiveOnly: true}}
                    />
                </InputItem>
                <InputItem width={"14.28%"}>
                    <Cleave
                        className={"cleaveStyle"}
                        style={inputStyle}
                        placeholder={"Open Interest..."}
                        value={openInterest}
                        onChange={evt => setOpenInterest(evt.target.value)}
                        options={{numericOnly: true, numeralPositiveOnly: true}}
                    />
                </InputItem>
                <InputItem width={"14.28%"}>
                    <DefaultSelect
                        label={"Contract"}
                        onChange={selected => setSelectedContract(selected)}
                        formatValue={e => e.symbol ? e.symbol : e.item}
                        list={contracts.filter(e => e.exchange === scAddress)}
                    />
                </InputItem>
            </Inputs>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Deploy"} onClick={deploy} disabled={disabled}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { DeployRow };