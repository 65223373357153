import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    color: var(--textSecondary);
    margin: 20px 0px 20px 20px;
    display: flex;
    flex-direction: row;
`;

export const ListAndHomeWrapper = styled.div`
    flex: 2;
    margin-right: 20px;
    display: flex;
    flex-direction: column
`;

export const HomeWrapper = styled.div`
    margin-bottom: 20px;
    position: relative;
    height: calc(${props => props.height}px - 10px);
    box-sizing: border-box;
    overflow: hidden;
`;

export const ListWrapper = styled.div`
    position: relative;
    height: calc(${props => props.height}px - 10px);
    box-sizing: border-box;
    overflow: hidden;
`;

export const DetailViewWrapper = styled.div`
    flex: 1;
    overflow: hidden;
`;

export const Resize = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    cursor: ns-resize;
    height: 4px;
    &:hover {
        background-color: var(--contrast);
    }
    z-index: 100;
`;

export const ResizeSolo = styled.div`
    transition: translate(0, -50%);
    width: 100%;
    cursor: ns-resize;
    height: 4px;
    &:hover {
        background-color: var(--contrast);
    }
    z-index: 10;
`;