import React, { useState } from 'react';
import { InputItem2x0, Wrapper } from 'components/inputs/styled';
import { Confirm, Close, useDefaultInputValues } from 'components/inputs';
import { RegisterCompanyAnonymous } from 'entities/Company/transactions';
import { useValidation } from 'hooks/core';
import { InputAde } from 'components/core/basic';

const inputStyle = {
    height: "25px", width: "100%"
}

const RegisterCompanyRow = (props) => {
    const { dispatch, web3Instance } = useDefaultInputValues();
    const [scAddress, setScAddress] = useState("");
    const [name, setName] = useState("");
    const [disabled, errorText] = useValidation([
        {
            validation: () => scAddress === "",
            errorText: "Sc Address can't be empty",
        },
        {
            validation: () => name === "",
            errorText: "Name can't be empty",
        }
    ]);

    const register = () => {
        if(disabled) return;
        dispatch(RegisterCompanyAnonymous(
            scAddress,
            name,
            web3Instance,
        ))
        props.close();
    }

    return (
        <Wrapper>
            <InputItem2x0>
                <InputAde style={inputStyle} placeholder={"Name..."} onChange={e => setName(e.target.value)}/>
            </InputItem2x0>
            <InputItem2x0>
                <InputAde style={inputStyle} placeholder={"Sc Address..."} onChange={e => setScAddress(e.target.value)}/>
            </InputItem2x0>
            <Confirm error={errorText} tooltip={errorText ?? "Confirm Register Company"} disabled={disabled} onClick={register}/>
            <Close tooltip={"Discard Input"} onClick={props.close}/>
        </Wrapper>
    )
}

export { RegisterCompanyRow };