import produce from 'immer';

export default (state = null, action) => 
    produce(state, draft => {
        switch(action.type) {
            case "SET_SELECTED_ENTITY":
                draft = action.payload;
                return draft;
            default:
                return draft;
        }
    })
