import { useCallback, useState } from 'react';
import Web3 from 'web3';
import { useSelectedProvider } from 'hooks/Login';

export const useWeb3Instance = () => {
    const [instance, setRaw] = useState(null);
    const provider = useSelectedProvider();

    const setInstance = () => {
        if(instance !== null)
            instance.currentProvider.connection.close();  
        try {
            const web3 = new Web3(provider.address);
            web3.currentProvider.on('error', () => window.location.reload())
            setRaw(web3);
        }
        catch(err) {
            console.log(err);
            return false;
        }
        
    }

    const clearInstance = useCallback(() => {
        setRaw(null);
    }, [])

    return [instance, setInstance, clearInstance];
}