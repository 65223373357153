import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'components/core/basic';
import { Toggle, Label, Selected, Expand, Content, RowItem } from './styled';
import { FaCaretDown } from 'react-icons/fa';
import { isEmpty } from 'lodash';

const DefaultSelect = (props) => {
    const [selected, setSelected] = useState({});
    const toggle = useRef();
    const { onChange, list, disabled, initial } = props;
    const [isDisabled, setIsDisabled] = useState(false);

    const toggleCallback = (toggleFunc) => {
        toggle.current = toggleFunc;
    }

    const selectItem = (item) => {
        setSelected(item);
        const toggleFunc = toggle.current;
        toggleFunc();
    }

    useEffect(() => {
        if(list.length === 0) {
            setIsDisabled(true);
            return;
        } 
        if(disabled) {
            setIsDisabled(true);
            return;
        }
        setIsDisabled(false);
    }, [list, disabled])

    useEffect(() => {
        if(list.length > 0 && isEmpty(selected)) {
            setSelected(initial ?? list[0])
        }
    }, [list, selected, initial])

    useEffect(() => {
        if(isEmpty(selected)) return;
        onChange(selected);
    }, [selected, onChange])

    return (
        <Dropdown fitContainerHeight={true} toggleCallback={toggleCallback}>
            <Toggle disabled={isDisabled}>
                <Label>{props.label}</Label>
                <Selected>
                    {list.length === 0
                        ? "Empty"
                        : props.formatValue(selected)}
                </Selected>
                <Expand><FaCaretDown/></Expand>
            </Toggle>
            {!isDisabled &&
                <Content>
                    {list.map((e, idx) => {
                        return <RowItem key={idx} onClick={() => selectItem(e)}>{props.formatValue(e)}</RowItem>
                    })}
                </Content>
            }
        </Dropdown>
    );
}

export default DefaultSelect;