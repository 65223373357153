export const CcpAddDefaultFundContribution = (evt, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const { _node, _delta } = evt.returnValues;

        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, _node);
        if(state.privileges.selected.name === "CCP")
            contract.methods.balanceOf(state.accounts.selected.address).call().then(r => {
                dispatch({ type: "CCP_ADD_DFC", payload: {node: _node, delta: _delta, balanceOf: parseInt(r)} });
            });
    }
}

export const CcpRemoveDefaultFundContribution = (evt, web3) => {
    return (dispatch, getState) => {
        const state = getState();
        const { _node, _delta } = evt.returnValues;

        const compatibleAbi = state.abi.data.Currency.abi.map(e => {
            const {constant, ...other} = e;
            return {...other}
        });
        const contract = new web3.eth.Contract(compatibleAbi, _node);
        if(state.privileges.selected.name === "CCP")
            contract.methods.balanceOf(state.accounts.selected.address).call().then(r => {
                dispatch({ type: "CCP_REMOVE_DFC", payload: {node: _node, delta: _delta, balanceOf: parseInt(r)} });
            });
    }
}

export const ccpEvents = {
    "CCP_addDefaultFundContribution": CcpAddDefaultFundContribution,
    "CCP_removeDefaultFundContribution": CcpRemoveDefaultFundContribution,
}