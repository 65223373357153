import React from 'react';
import { useSelector } from 'react-redux';
import { ListViewRoot, ListContent, ListHeaderRow } from 'components/list/styled';
import { ListColumn, ListWrapper, RowRenderer, useListViewValues, DeployRow } from 'components/list';
import { PanelHeader } from 'components/core/basic';
import { FaPlus } from 'react-icons/fa';

const ListView = () => {
    const { selectedEntity, list, privileges, showDeploy, setShowDeploy } = useListViewValues("custodianList");
    const clearers = useSelector(state => state.clearerList);

    const registeredClearers = (custodianSc) => {
        const count = clearers.reduce((acc, curr) => {
            if(curr.custodians.find(e => e === custodianSc))
                return acc + 1;
            return acc;
        }, 0);
        return count;
    }

    const rowRenderer = ({ key, index, style }) => (
        <RowRenderer key={key} index={index} style={style} item={list[index].item}>
            <ListColumn>{list[index].name}</ListColumn>
            <ListColumn timestamp>{list[index].timestamp}</ListColumn>
            <ListColumn>{registeredClearers(list[index].item)}</ListColumn>
        </RowRenderer>
    )

    const headerButtons = () => {
        if(privileges.selected.name === "CCP")
            return [
                {icon: FaPlus, onClick: () => setShowDeploy(true), active: showDeploy, disabled: showDeploy, tooltip: "Deploy Custodian"},
            ]
    }

    if(selectedEntity !== "Custodian")
        return null;
    return (
        <ListViewRoot>
            <PanelHeader title={"Custodians"} buttons={headerButtons()}/>
            {showDeploy &&
                <DeployRow close={() => setShowDeploy(false)} type={"Custodian"}/>
            }
            {list.length > 0 && (
                <ListHeaderRow>
                    <ListColumn head>Name</ListColumn>
                    <ListColumn head>Timestamp</ListColumn>
                    <ListColumn head>Reg. Clearers</ListColumn>
                </ListHeaderRow>
            )}
            <ListContent headerVisible={list.length > 0}>
                <ListWrapper length={list.length} rowRenderer={rowRenderer}/>
            </ListContent>
        </ListViewRoot>
    )
}

export { ListView };